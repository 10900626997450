import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { useState } from "react";
import styles from "features/rewards/css/CategoryList.module.scss";
import { useTranslation } from "react-i18next";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomButton from "components/custom-button/CustomButton";
import { PERMS } from "helpers/constants";
import { PlusCircleOutlined } from "@ant-design/icons";
import AddRewardCategory from "features/rewards/category/AddRewardCategory";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CustomLanguageSelector from "components/form/custom-language-selector/CustomLanguageSelector";
import { CategoryList } from "features/rewards/category/CategoryList";
interface CategoriesProps {}

const Categories: React.FunctionComponent<CategoriesProps> = (props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [isAddCategory, setIsAddCategory] = useState(false);
  const [langCode, setLangCode] = useState<string>();

  const handleSubmit = (values: any, actions: any) => {
    actions.resetForm();
    setLangCode(values.language);
    setVisible(false);
    setIsAddCategory(true);
  };

  return (
    <DashboardLayout openKey={["Rewards"]}>
      <Breadcrumbs
        data={[
          {
            label: t("rewards"),
          },
          {
            label: t("reward-categories"),
          },
        ]}
      />
      <CategoryList setVisible={setVisible} />
      {visible && (
        <CustomLanguageSelector
          visible={visible}
          setVisible={setVisible}
          onSubmit={handleSubmit}
          title={`${t("category")} ${t("main-language")}`}
          label={t("category")}
          saveText={t("add-category")}
        />
      )}
      {isAddCategory && (
        <AddRewardCategory
          visible={isAddCategory}
          setVisible={setIsAddCategory}
          editId={null}
          setEditId={() => null}
          langCode={langCode}
        />
      )}
    </DashboardLayout>
  );
};

export default Categories;
