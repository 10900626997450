import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setFilters as setPersistFilters } from "store/navigationSlice";
import { usePersistedState } from "./usePersistedState";

const useFilters = (filters: any) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const persistFilters = usePersistedState("filters", location, {});

  const handleRangeFilter = (
    dateString: any,
    dateStrings: any,
    filterItem?: any,
  ) => {
    let filtersArray: any = {};
    filters?.date.forEach((element: any) => {
      const { applyAs, comparison, key }: any = element;
      if (filterItem && key !== filterItem.key) return;

      const params = {
        value: { start: dateStrings[0], end: dateStrings.pop() },
        key,
        comparison,
      };

      let prevArr = persistFilters[applyAs!] ? persistFilters[applyAs!] : [];

      const filterPrevArr = prevArr.filter(
        (item: { key: string }) => item.key !== params.key,
      );

      if (dateString) {
        filtersArray = {
          ...filtersArray,
          [applyAs!]: [...filterPrevArr, params],
        };
      } else {
        filtersArray = {
          ...filtersArray,
          [applyAs!]: [...filterPrevArr],
        };
      }
    });

    // this would add a hash to determine if some state is persisted
    filters.persist !== false && navigate("#persist");
    dispatch(
      setPersistFilters({
        location,
        filters: { ...filtersArray },
      }),
    );
  };

  const handleFilter = (currValue: any) => {
    const setStatusFilter = setFilters(filters?.status);
    setStatusFilter(currValue);
  };

  const handleMandatoryFilter = (currValue: any) => {
    const setMandatoryFilter = setFilters(filters?.mandatory);
    setMandatoryFilter(currValue);
  };

  const handleManualFilter = (filter: any) => {
    const setManualFilter = setFilters(
      filters?.manualFilters.find((x: any) => x.key === filter.key),
    );

    return (currValue: any) => {
      setManualFilter(currValue);
    };
  };

  const setFilters = (filterVal: any) => {
    const { applyAs, comparison, key }: any = filterVal;

    return (currValue: any) => {
      const params = {
        value: currValue,
        key,
        comparison,
      };

      let prevArr = persistFilters[applyAs!] ? persistFilters[applyAs!] : [];

      const filterPrevArr = prevArr.filter(
        (item: { key: string }) => item.key !== params.key,
      );

      if (currValue) {
        dispatch(
          setPersistFilters({
            location,
            filters: {
              ...persistFilters,
              [applyAs!]: [...filterPrevArr, params],
            },
          }),
        );
      } else {
        dispatch(
          setPersistFilters({
            location,
            filters: {
              ...persistFilters,
              [applyAs!]: [...filterPrevArr],
            },
          }),
        );
      }

      filters?.persist !== false && navigate("#persist");
    };
  };

  return {
    advanceFilters: persistFilters,
    handleFilter,
    handleRangeFilter,
    handleMandatoryFilter,
    handleManualFilter,
    
  };
};

export default useFilters;
