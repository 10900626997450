import * as Yup from "yup";
import * as Validations from "validations/schema/common/validations";
import { Language } from "graphql/_generated/graphql";


export const addChallengeSchema = ({
  isSuperAdmin,
  isViwellAdmin,
  isAddLanguage,
  isViwellChallenge,
  translations,
  language
}: any) => {

  const optional = Yup.string().nullable();
  const optionalArray = Yup.array().nullable();

  const titleArabic = Yup.string().max(100, translations("max-100-char")).required("Title is required").nullable();
  const titleEnglish = Yup.string().matches(/^[A-Za-z0-9_-][A-Za-z0-9_ -]*$/, translations("only-special-char-for-challenge-title"),).max(100, translations("max-100-char")).required("Title is required").nullable();
  
  return Yup.object().shape({
    image: Validations.image("image"),
    title: language === Language.English ? titleEnglish  : titleArabic,
    description: Validations.required("description"),
    rules: Yup.string().required(translations("rules-required")).nullable(),
    rewardDetails: Validations.required("reward details"),
    companies: (isSuperAdmin || isViwellAdmin) && !isViwellChallenge ? Yup.array().min(1, translations("atleast-one-company-required")).required(translations("company-required")) : optionalArray,
    pillarId: Validations.required("pillar"),
    categoryId: Validations.required("category"),
    tagIds: Validations.requiredArrayOfStringValidation("tag"),
    type: Validations.required("type"),
    goalType: Validations.required("goal type"),
    goal: Validations.required("goal"),
    startDate: isAddLanguage
    ? optional
    : Yup.date()
    .min(new Date(), translations("start-date-not-in-past"))
    .required(translations("startdate-required")).nullable(),
    expiryDate: isAddLanguage
    ? optional
    : Yup.date()
    .min(Yup.ref("startDate"), translations("expirydate-cannot-before-startdate"))
    .required(translations("expirydate-required")).nullable(),
    duration: Validations.required("duration"),
    completePoints:
    isSuperAdmin || isViwellAdmin
      ? Validations.required("complete points")
      : optional,
  });
};

export const defaultRewardSchema = ({translations}: any) => {

 return Yup.object().shape({
  points: Yup.string().required(translations("challenge-default-point-required")).nullable()
});
}  

export const exceptionCompanySchema = ({translations}: any) => {

  return Yup.object().shape({
    setCompanyRewardPointsId: Validations.required("company"),
    points: Yup.string().required(translations("points-required")).nullable()
  });
}