import { Tabs } from "antd";
import alertSvg from "assets/images/alert-circle.svg";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import customNotification from "components/custom-notification/CustomNotification";
import CustomTabs from "components/custom-tabs/CustomTabs";
import styles from "features/users/css/InviteMultipleUsers.module.scss";
import { mapUsersContents } from "features/users/hooks/useInviteMultipleUsers";
import { useUsers } from "features/users/hooks/useUsers";
import { EmployeeBulkCreate } from "graphql/_generated/graphql";
import { useErrorHandler } from "hooks/useErrorHandler";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DetectedErrorsModal from "./DetectedErrorsModal";
import PreviewDetails from "./PreviewDetails";

const { TabPane } = Tabs;

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  users: {
    title: string;
    employees: EmployeeBulkCreate[];
    positions: EmployeeBulkCreate[];
    departments: EmployeeBulkCreate[];
    buildings: EmployeeBulkCreate[];
    cities: EmployeeBulkCreate[];
    countries: EmployeeBulkCreate[];
    addresses: EmployeeBulkCreate[];
  };
  errors: any;
  values: any;
  refetch: () => void;
};

export default function BulkUsersPreview({
  visible,
  setVisible,
  users,
  errors,
  values,
  refetch,
}: Props) {
  const { t } = useTranslation();
  const [errorsVisible, setErrorsVisible] = useState<boolean>(false);
  const { isLoading, inviteMultipleEmployees } = useUsers();
  const { handleError } = useErrorHandler();

  const handleCancel = () => {
    setVisible(false);
  };

  const handleBulkInvite = async () => {
    try {
      const mappedUsers = mapUsersContents(values.users);
      const res = await inviteMultipleEmployees({
        ...values,
        users: mappedUsers,
      });
      if (res.inviteEmployeeInBulk.errorCount > 0) {
        handleError(JSON.stringify(res.inviteEmployeeInBulk.errorsOn));
      } else {
        customNotification("success", t("success-invite-employees"));
        refetch();
      }
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const tabs = [
    {
      label: t("employees"),
      tab: (
        <PreviewDetails
          items={users.employees}
          previewKeys={{
            all: true,
          }}
        />
      ),
    },
    {
      label: t("positions"),
      tab: (
        <PreviewDetails
          items={users.positions}
          previewKeys={{
            position: true,
            department: true,
          }}
        />
      ),
    },
    {
      label: t("departments"),
      tab: (
        <PreviewDetails
          items={users.departments}
          previewKeys={{
            department: true,
          }}
        />
      ),
    },
    {
      label: t("buildings"),
      tab: (
        <PreviewDetails
          items={users.buildings}
          previewKeys={{
            building: true,
            address: true,
            city: true,
            country: true,
          }}
        />
      ),
    },
    {
      label: t("cities"),
      tab: (
        <PreviewDetails
          items={users.cities}
          previewKeys={{
            city: true,
          }}
        />
      ),
    },
    {
      label: t("countries"),
      tab: (
        <PreviewDetails
          items={users.countries}
          previewKeys={{
            country: true,
          }}
        />
      ),
    },
    {
      label: t("addresses"),
      tab: (
        <PreviewDetails
          items={users.addresses}
          previewKeys={{
            address: true,
          }}
        />
      ),
    },
  ];

  const getErrorsCount = () =>
    errors?.reduce((acc: number, curr: any) => {
      if (curr && Object.keys(curr)) {
        return acc + Object.keys(curr)?.length;
      }
      return acc;
    }, 0);

  const haveErrors = getErrorsCount();

  return (
    <CustomModal
      width={1000}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={users?.title}
    >
      <CustomTabs tabs={tabs} />
      <div
        className={`${styles["errors-container"]} cursor-pointer`}
        onClick={() => setErrorsVisible(true)}
      >
        {haveErrors ? (
          <>
            <img src={alertSvg} />
            <div className={styles["error-text"]}>{`${haveErrors} ${t(
              "errors-found",
            )}`}</div>
          </>
        ) : null}
      </div>

      {errors && errorsVisible ? (
        <DetectedErrorsModal
          visible={errorsVisible}
          setVisible={() => setErrorsVisible(!errorsVisible)}
          errors={errors}
        />
      ) : null}
      <ModalFooter
        saveText={t("confirm-and-save")}
        loading={isLoading}
        onSubmit={() => !haveErrors && handleBulkInvite()}
        onCancel={() => handleCancel()}
      />
    </CustomModal>
  );
}
