export const ChallengeSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 5.5C10 5.22386 9.77558 4.99741 9.50082 5.02498C8.68828 5.1065 7.90535 5.38615 7.22215 5.84265C6.39991 6.39206 5.75904 7.17295 5.3806 8.08658C5.00217 9.00021 4.90315 10.0055 5.09608 10.9755C5.289 11.9454 5.76521 12.8363 6.46447 13.5355C7.16373 14.2348 8.05465 14.711 9.02455 14.9039C9.99446 15.0969 10.9998 14.9978 11.9134 14.6194C12.827 14.241 13.6079 13.6001 14.1573 12.7779C14.6139 12.0946 14.8935 11.3117 14.975 10.4992C15.0026 10.2244 14.7761 10 14.5 10H10.5C10.2239 10 10 9.77614 10 9.5V5.5Z"
        fill="#303030"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99984 2.83366C9.99984 3.1098 9.77567 3.33171 9.5003 3.3524C8.3577 3.43825 7.25343 3.81749 6.29604 4.4572C5.19971 5.18974 4.34523 6.23093 3.84064 7.4491C3.33606 8.66728 3.20404 10.0077 3.46127 11.3009C3.71851 12.5941 4.35345 13.782 5.2858 14.7144C6.21815 15.6467 7.40603 16.2817 8.69924 16.5389C9.99244 16.7961 11.3329 16.6641 12.5511 16.1595C13.7692 15.6549 14.8104 14.8005 15.543 13.7041C16.1827 12.7467 16.5619 11.6425 16.6478 10.4999C16.6685 10.2245 16.8904 10.0003 17.1665 10.0003H17.8332C18.1093 10.0003 18.3347 10.2244 18.3182 10.5C18.2297 11.9726 17.7516 13.3985 16.9288 14.6301C16.0131 16.0005 14.7116 17.0686 13.1889 17.6993C11.6662 18.3301 9.9906 18.4951 8.37409 18.1735C6.75758 17.852 5.27272 17.0583 4.10728 15.8929C2.94185 14.7274 2.14817 13.2426 1.82663 11.6261C1.50509 10.0096 1.67011 8.33401 2.30084 6.8113C2.93158 5.28858 3.99968 3.98709 5.37009 3.07141C6.60162 2.24853 8.02759 1.77043 9.50012 1.68199C9.77576 1.66543 9.99984 1.89085 9.99984 2.16699V2.83366Z"
        fill="#303030"
      />
      <path
        d="M14.9998 4.49967V1.83301C14.9998 1.42099 14.5295 1.18579 14.1998 1.43301L11.8665 3.18301C11.7406 3.27743 11.6665 3.42563 11.6665 3.58301V7.83301C11.6665 8.10915 11.8904 8.33301 12.1665 8.33301H16.4165C16.5739 8.33301 16.7221 8.25891 16.8165 8.13301L18.5665 5.79967C18.8137 5.47006 18.5785 4.99967 18.1665 4.99967H15.4998C15.2237 4.99967 14.9998 4.77582 14.9998 4.49967Z"
        fill="#303030"
      />
    </svg>
  );
};
