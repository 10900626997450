import { Checkbox, Col, Row } from "antd";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { usePillarIdAndName } from "hooks";
import CustomCard from "components/custom-card/CustomCard";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import {
  useGetCategoriesTreeQuery,
  useGetClientsTreeQuery,
  useGetConfigTagsQuery,
} from "graphql/_generated/graphql";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "features/challenge/css/AddActivity.module.scss";
import CollapseAble from "features/assessment/questions/collapseables";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import { CustomDatePicker } from "components/form/custom-date-picker/CustomDatePicker";
import {
  ChallengeTypes,
  ChallengeGoalTypes,
} from "features/challenge/data/ChallengeTypes";
import ChallengeDuration from "features/challenge/data/ChallengeDuration";
import { useLoggedInUser } from "hooks/useLoggedInUser";

interface ChallengeOptionsProps {
  setFieldValue?: any;
  values: any;
  disabled?: boolean;
  isViwellChallenge: boolean;
  setIsViwellChallenge: any;
}

const ChallengeOptions: FunctionComponent<ChallengeOptionsProps> = ({
  setFieldValue,
  values,
  disabled,
  isViwellChallenge,
  setIsViwellChallenge,
}) => {
  const { t } = useTranslation();
  const { pillarsData } = usePillarIdAndName();
  const { isSuperAdmin, isViwellAdmin } = useLoggedInUser();

  const { data: companiesData } = useGetClientsTreeQuery(
    { clientId: null },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { data: tagsData } = useGetConfigTagsQuery();
  const [pillarId, setPillarId] = useState("");
  const { data: categoriesData } = useGetCategoriesTreeQuery(
    {
      pillarId: pillarId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const handleChange = (e: any) => {
    setIsViwellChallenge(e.target.checked);
  };

  const isGoalInRange = (key: string) => {
    const data = ChallengeGoalTypes[values.type]?.goals?.find(
      (item: any) => item.id === values.goalType,
    );
    return data?.[key] || undefined;
  };

  const isDurationInRange = (key: string) => {
    const data = ChallengeDuration?.find(
      (item: any) => item.id === values.duration_type,
    );
    return data?.[key] || undefined;
  };

  return (
    <CollapseAble
      defaultActiveKey={["challenge-options"]}
      headerKey={"challenge-options"}
      className={styles["activity-card"]}
      header={
        <div className="d-flex">
          <ContainerHeading text={"Challenge Options"} />
        </div>
      }
      hideExtra={true}
      expandIconPosition="end"
    >
      <div className={styles["content-card"]}>
        {isSuperAdmin || isViwellAdmin ? (
          <Row gutter={[24, 0]}>
            <Col sm={24} md={12} className={`${styles["viwell-checkbox"]}`}>
              <Checkbox
                checked={isViwellChallenge}
                disabled={disabled}
                onChange={handleChange}
              >
                VIWELL Challenge
              </Checkbox>
            </Col>

            <Col sm={24} md={12}>
              <CustomTreeSelect
                allowClear
                showArrow
                showSearch={false}
                treeNodeFilterProp="companyName"
                disabled={isViwellChallenge || disabled}
                treeLine={true && { showLeafIcon: false }}
                label="Companies"
                multiple={true}
                className="form-select"
                name="companies"
                treeData={companiesData?.getClientsTree}
                placeholder={t("company.select-company")}
                fieldNames={{ label: "companyName", value: "id" }}
                onChange={(values) => {
                  setFieldValue("companies", values);
                }}
              />
            </Col>
          </Row>
        ) : null}
        <Row gutter={[24, 0]}>
          <Col sm={24} md={12}>
            <CustomSelectField
              onChange={(value) => {
                setPillarId(value);
                setFieldValue("categoryId", "");
              }}
              label="Pillar"
              disabled={disabled}
              className="form-select"
              name="pillarId"
              placeholder="Select Pillar"
              options={pillarsData}
            />
          </Col>
          <Col sm={24} md={12}>
            <CustomTreeSelect
              showArrow
              treeNodeFilterProp="name"
              treeLine={true && { showLeafIcon: false }}
              disabled={!values.pillarId || disabled}
              label="Category"
              fieldNames={{
                label: "name",
                value: "id",
              }}
              className="form-select"
              name="categoryId"
              placeholder="Select Category"
              treeData={categoriesData?.getCategoriesTree}
            />
          </Col>

          <Col sm={24} md={12}>
            <CustomSelectField
              label="Tags"
              name="tagIds"
              mode="multiple"
              placeholder="Select Tags"
              disabled={disabled}
              className="form-select"
              options={tagsData?.getConfigTags.options}
              fieldNames={{ label: "label", value: "id" }}
            />
          </Col>
          <Col sm={24} md={12}>
            <CustomSelectField
              label="Challenge Type"
              name="type"
              disabled={disabled}
              placeholder="Select Challenge Type"
              className="form-select"
              options={ChallengeTypes}
              onChange={(value) => {
                setFieldValue("goalType", null);
                setFieldValue("goal", null);
              }}
              fieldNames={{ label: "title", value: "id" }}
            />
          </Col>
          <Col sm={24} md={12}>
            <CustomSelectField
              label="Goal Type"
              name="goalType"
              placeholder="Select Goal Type"
              disabled={!values.type || disabled}
              className="form-select"
              options={ChallengeGoalTypes[values.type]?.goals}
              fieldNames={{ label: "name", value: "id" }}
              onChange={(value) => {
                setFieldValue("goal", null);
              }}
            />
          </Col>
          <Col sm={24} md={12}>
            <CustomTextField
              type="number"
              label="Goal"
              name="goal"
              min={isGoalInRange("min")}
              max={isGoalInRange("max")}
              disabled={!values.goalType || disabled}
              placeholder="Enter Goal"
            />
          </Col>
          <Col sm={24} md={12}>
            <CustomDatePicker
              placeholder="Select the starting date"
              subLabel="Starting Date"
              name="startDate"
              disabled={disabled}
              showTime={true}
            />
          </Col>
          <Col sm={24} md={12}>
            <CustomDatePicker
              placeholder="Select the expiry date"
              subLabel="Expiry Date"
              name="expiryDate"
              disabled={disabled}
              showTime={true}
            />
          </Col>

          <Col sm={24} md={12}>
            <CustomTextField
              type="number"
              label="Duration"
              disabled={disabled}
              name="duration"
              min={isDurationInRange("min")}
              max={isDurationInRange("max")}
              placeholder="Enter Duration"
            />
          </Col>
          <Col sm={24} md={12}>
            <CustomSelectField
              label="Type"
              name="duration_type"
              showEmptyLabel={true}
              disabled={disabled}
              placeholder="Type"
              className="form-select"
              options={ChallengeDuration}
              fieldNames={{ label: "name", value: "id" }}
            />
          </Col>
        </Row>
      </div>
    </CollapseAble>
  );
};
export default ChallengeOptions;
