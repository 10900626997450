import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import { useUpdateProfile } from "features/dashboard/header/useUpdateProfile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DashboardLayout from "../dashboard/dashboard-layout/DashboardLayout";
import styles from "./CompanyDashboard.module.scss";
import { Assessments } from "./components/assessments";
import { Challenges } from "./components/challenges";
import { Checkins } from "./components/checkins";
import { OrganizationMetrices } from "./components/organization-metrices";
import { PillarsAndCategories } from "./components/pillars-and-categories";
import { Programmes } from "./components/programmes";

export const MIN_EMPLOYEES_COUNT = 5;

const CompanyDashboard = () => {
  const { t } = useTranslation();
  const [selectedSection, setSelectedSection] = useState<string>("check-ins");
  const [selectedInfoText, setSelectedInfoText] = useState<string>("");
  const [companyRegisteredDate, setCompanyRegisteredDate] =
    useState<string>("");
  const { handleFetchProfileData } = useUpdateProfile();

  useEffect(() => {
    handleFetchProfileData().then((data) => {
      setCompanyRegisteredDate(data.createdAt);
    });
  }, []);

  const visibleWidgetList = useSelector(
    (state: any) => state.dashboard.visibleWidgets,
  );

  const dashboardSections = [
    {
      title: t("check-ins"),
      id: "check-ins",
    },
    {
      title: t("assessments"),
      id: "assessments",
    },
    {
      title: t("pillars-and-categories"),
      id: "pillars-and-categories",
    },
    {
      title: t("classes"),
      id: "classes",
    },
    {
      title: t("challenges"),
      id: "challenges",
    },
    {
      title: t("organisations-metrics"),
      id: "organisations-metrics",
    },
  ];

  const changeSelectedSection = (id: string) => {
    const element = document.getElementById(id);

    setSelectedSection(id);
    element?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const isVisible = findInList(visibleWidgetList);

  return (
    <DashboardLayout>
      <Breadcrumbs
        data={[
          {
            label: t("dashboard"),
          },
          {
            label: t("employee-engagement"),
          },
        ]}
      />
      <div className={styles["sections-header"]}>
        {dashboardSections.map((item) => (
          <div
            className={`${styles["section-item"]} ${
              selectedSection === item.id ? styles["section-item-selected"] : ""
            }`}
            onClick={() => changeSelectedSection(item.id)}
          >
            {item.title}
          </div>
        ))}
      </div>
      <div id="check-ins">
        <Checkins
          selectedInfoText={selectedInfoText}
          setSelectedInfoText={setSelectedInfoText}
          companyRegisteredDate={companyRegisteredDate}
        />
      </div>
      <div id="assessments">
        <Assessments
          selectedInfoText={selectedInfoText}
          setSelectedInfoText={setSelectedInfoText}
        />
      </div>
      <div id="pillars-and-categories">
        <PillarsAndCategories
          selectedInfoText={selectedInfoText}
          setSelectedInfoText={setSelectedInfoText}
          companyRegisteredDate={companyRegisteredDate}
        />
      </div>
      <div id="classes">
        <Programmes
          selectedInfoText={selectedInfoText}
          setSelectedInfoText={setSelectedInfoText}
          companyRegisteredDate={companyRegisteredDate}
        />
      </div>
      <div id="challenges">
        <Challenges
          selectedInfoText={selectedInfoText}
          setSelectedInfoText={setSelectedInfoText}
          companyRegisteredDate={companyRegisteredDate}
        />
      </div>
      <div id="organisations-metrics">
        <OrganizationMetrices
          selectedInfoText={selectedInfoText}
          setSelectedInfoText={setSelectedInfoText}
          companyRegisteredDate={companyRegisteredDate}
        />
      </div>
    </DashboardLayout>
  );
};

export default CompanyDashboard;

const findInList = (list: Array<string>) => {
  return (item: string) => list.includes(item);
};
