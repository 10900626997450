import { Button, Col, Row, Select, Space, Tag } from "antd";
import { RedoOutlined } from "@ant-design/icons";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import { errorFormat, parseJwt, PERMS } from "helpers";
import CustomButton from "components/custom-button/CustomButton";
import CustomCard from "components/custom-card/CustomCard";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { Form, Formik } from "formik";
import {
  useGetClientsTreeQuery,
  useGetConfigAgeOfEmployeeQuery,
  useGetConfigCompanySizeQuery,
} from "graphql/_generated/graphql";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "features/company/css/EditCompany.module.scss";
import { CustomTextArea } from "components/form/custom-text-area/CustomTextArea";
import { CustomCheckBoxGroup } from "components/form/custom-checkbox-group/CustomCheckBoxGroup";
import UsersList from "features/users/UsersList";
import { useEditCompany } from "../../features/company/hooks/useEditCompany";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import InviteUsers from "features/users/invite-users/InviteUsers";
import { useSelector } from "react-redux";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { useErrorHandler } from "hooks/useErrorHandler";

const EditCompany = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const token = useSelector((state: any) => state.auth.token);
  const { isClient, isSuperAdmin } = useLoggedInUser();
  const { data: avgAgeOfEmployeesData } = useGetConfigAgeOfEmployeeQuery();
  const { data: companySizeData } = useGetConfigCompanySizeQuery();
  const { handleFetchEditClientData, handleUpdateClient, isLoading } =
    useEditCompany();
  const [id, setId] = useState();
  useEffect(() => {
    setId(location.state);
  }, []);

  useEffect(() => {
    if (!id) return;

    handleFetchEditClientData(id!).then((data: any) => {
      const { createdAt } = data?.getClientDetail;
      setInitialValues({
        ...data?.getClientDetail,
        createdAt: createdAt?.split("T")[0],
      });
    });
  }, [id]);

  const [initialValues, setInitialValues] = useState<any>({
    id: "",
    image: "",
    subscribers: 0,
    subscriptions: 0,
    companyName: "",
    companySize: "",
    avgAgeOfEmployee: "",
    goal: "",
    buildings: [],
    isProfileComplete: false,
    isActive: false,
    isEapEnabled: false,
    numberOfEmployee: 0,
    departments: [],
    positions: [],
    children: [],
    parent: {},
    hasChildren: false,
    adminEmail: "",
  });

  const [visible, setInviteVisible] = useState(false);
  const { data: companiesData, refetch } = useGetClientsTreeQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <DashboardLayout openKey={["Users"]}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        // validationSchema={editCompanySchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            await handleUpdateClient({
              clientUpdate: values,
              id,
            });
            customNotification("success", t("company-updated-successfully"));
            resetForm();
            refetch();
            navigate("/company");
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <div className={styles["add-content-header"]}>
                <h1 className={styles["title"]}>
                  {id ? t("edit-company") : t("create-new-company")}
                </h1>
                <Space>
                  <CheckPermissions permission={PERMS.INVITE_EMPLOYEE}>
                    <div className={styles["btn-div"]}>
                      <Button
                        className="light-btn"
                        htmlType="reset"
                        onClick={() => navigate("/company")}
                      >
                        {t("cancel")}
                      </Button>
                      <Button
                        loading={isLoading}
                        htmlType="submit"
                        className="primary-btn"
                      >
                        {t("save")}
                      </Button>
                    </div>
                  </CheckPermissions>
                </Space>
              </div>
              <div className={styles["content-card"]}>
                <CustomCard bordered={false} className="content-card">
                  <div style={{ display: "flex", gap: "30px" }}>
                    <div>
                      <CustomUploadFile
                        imgUrl={values.image}
                        name="image"
                        setFieldValue={setFieldValue}
                        accept="image/*"
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextArea name="goal" label={t("goal")} rows={4} />
                    </div>
                  </div>
                  <Row gutter={[24, 0]}>
                    <Col sm={24} md={12}>
                      <CustomTextField
                        disabled={isClient}
                        label={t("company-name")}
                        name="companyName"
                        placeholder={t("company-name")}
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomTextField
                        disabled
                        label={t("company-user-email")}
                        name="adminEmail"
                        placeholder={t("company-user-email")}
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomTextField
                        disabled
                        type="number"
                        label={t("total-invited-employees")}
                        name="subscribers"
                        placeholder={t("total-invited-employees")}
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomTextField
                        type="number"
                        disabled={isClient}
                        label={t("number-of-subscriptions")}
                        name="subscriptions"
                        placeholder={t("enter-number-of-subscriptions")}
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomTextField
                        disabled
                        label={t("total-employees")}
                        name="employees"
                        placeholder={t("total-employees")}
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomTextField
                        disabled
                        type="number"
                        label={t("total-registered-active-employees")}
                        name="totalActiveEmployees"
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomSelectField
                        label={t("company-size")}
                        name="companySize"
                        placeholder={t("company-size")}
                        className="form-select"
                        options={companySizeData?.getConfigCompanySize.options}
                        fieldNames={{ label: "label", value: "id" }}
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomSelectField
                        label={t("average-age-of-employees")}
                        name="avgAgeOfEmployee"
                        placeholder={t("average-age-of-employees")}
                        className="form-select"
                        options={
                          avgAgeOfEmployeesData?.getConfigAgeOfEmployee.options
                        }
                        fieldNames={{ label: "label", value: "id" }}
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomTextField
                        disabled
                        label={t("registered-date")}
                        name="createdAt"
                        placeholder={t("enter-registered-date")}
                      />
                    </Col>
                  </Row>
                  {/* <Row gutter={[24, 0]}>
                    <Col sm={24} md={12}>
                      <CustomTextField
                        label="Employees Count"
                        name="numberOfEmployee"
                        placeholder="Enter Employees Count"
                      />
                    </Col>
                  </Row> */}
                  {/* <div className={styles["form-control"]}>
                    <Space>
                      <Switch
                        id="child"
                        className="custom-switch"
                        onChange={(values) => handleChange(values)}
                      />
                      <label className={styles["child"]} htmlFor="child">
                        Is a child Company?
                      </label>
                    </Space>
                  </div> 
                  <div className={styles["form-control"]}>
                    {child && (
                      <CustomTreeSelect
                        allowClear
                        showArrow
                        treeNodeFilterProp="companyName"
                        treeLine={true && { showLeafIcon: false }}
                        label="Parent Company"
                        className="form-select"
                        name="parent"
                        treeData={companiesData?.getClientsTree}
                        placeholder="Select Parent Company"
                        fieldNames={{ label: "companyName", value: "id" }}
                      />
                    )}
                  </div>*/}
                  {values.parent && (
                    <>
                      <div className={styles["form-control"]}>
                        <label className="label">Parent</label>
                        <Tag
                          className={styles["tag"]}
                          onClick={() => {
                            navigate("/edit-company", {
                              state: values.parent.id,
                            });
                          }}
                        >
                          {values.parent.companyName}
                        </Tag>
                      </div>
                    </>
                  )}
                  {values.children.length > 0 && (
                    <>
                      <div className={styles["form-control"]}>
                        <label className="label">{t("children")}</label>
                        {values.children.map((item: any) => (
                          <Tag
                            className={styles["tag"]}
                            onClick={() => {
                              navigate("/edit-company", {
                                state: item.id,
                              });
                            }}
                          >
                            {item.companyName}
                          </Tag>
                        ))}
                      </div>
                    </>
                  )}
                  {!isClient && (
                    <div className={styles["form-control"]}>
                      <CustomCheckBoxGroup
                        name="isActive"
                        label="Status"
                        options={[{ label: t("active"), value: true }]}
                      >
                        {t("active")}
                      </CustomCheckBoxGroup>
                    </div>
                  )}
                  {!isClient && (
                    <div className={styles["form-control"]}>
                      <CustomCheckBoxGroup
                        name="isEapEnabled"
                        label="EAP"
                        options={[{ label: t("active"), value: true }]}
                      >
                        EAP
                      </CustomCheckBoxGroup>
                    </div>
                  )}
                </CustomCard>
              </div>
              {id && (
                <UsersList
                  companyChildren={initialValues?.children}
                  employerId={id}
                  isProfileComplete={values.isProfileComplete}
                  setInviteVisible={setInviteVisible}
                />
              )}
              <InviteUsers
                visible={visible}
                setVisible={setInviteVisible}
                employerId={id}
              />
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
};
export default EditCompany;
