import { SelectProps, Space } from "antd";
import { ErrorMessage } from "formik";
import { Select } from "formik-antd";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import styles from "./CustomSelectField.module.scss";

interface Props extends SelectProps {
  name: string;
  label?: string;
  subLabel?: string;
  options?: { label: string; value: string; disabled?: boolean }[] | any;
  required?: boolean;
  marginBtm?: boolean;
  value?: string | Array<string>;
  showEmptyLabel?: boolean;
}

export function CustomSelectField(props: Props) {
  const { errorT } = useErrorTranslation();

  const {
    name,
    label,
    fieldNames,
    subLabel,
    marginBtm,
    showEmptyLabel,
    required,
    ...SelectionProps
  } = props;

  return (
    <div className={marginBtm === false ? "" : styles["form-control"]}>
      {(label || required) && (
        <Space size={2}>
          {required && (
            <span className={`${styles["label"]} ${styles["text-danger"]}`}>
              &#42;
            </span>
          )}
          {label && !showEmptyLabel && (
            <label className="label" htmlFor={name}>
              {label}
            </label>
          )}
        </Space>
      )}

      {showEmptyLabel ? <div className="empty-label" /> : null}

      {subLabel && (
        <label className={styles["subLabel"]} htmlFor={name}>
          {subLabel}
        </label>
      )}

      <Select
        name={name}
        showArrow
        showSearch
        filterOption={(input: any, option: any) => {
          const label = fieldNames?.label
            ? option[fieldNames.label]
            : option?.label;

          return (label || "").toLowerCase().includes(input?.toLowerCase());
        }}
        getPopupContainer={(trigger) => trigger.parentNode}
        className={props.className ? props.className : "form-select"}
        fieldNames={fieldNames}
        {...SelectionProps}
      >
        {props.children}
      </Select>

      <ErrorMessage
        name={name}
        render={(msg) => {
          return <div className="text-danger">{errorT(msg)}</div>;
        }}
      />
    </div>
  );
}
