import { convertTranslationObjToArr } from "helpers";
import {
  MutationSetDocumentationArgs,
  useSetDocumentationMutation,
  useLazyGetDocumentationQuery,
  // useGetDocumentationQuery,
} from "graphql/_generated/graphql";
import { DocumentationSections as Sections } from "../../../pages/documentation/Documentation";
import {
  formatTranslationsForRequest,
  parseTranslationsFromResponse,
} from "../utils";

export const useGetSetDocumentation = () => {
  const [setDocumentation, { isLoading }] = useSetDocumentationMutation();
  const [getDocumentationFun, { isLoading: isFetching }] =
    useLazyGetDocumentationQuery();

  //this function is responsible for adding the onBoarding data
  const handleSetDocumentation = async (
    values: MutationSetDocumentationArgs,
  ) => {
    try {
      let translations = formatTranslationsForRequest(
        values.create.translations,
      );
      let params = { create: { ...values.create, translations } };

      await setDocumentation(params).unwrap();
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };

  const getDocumentation = async () => {
    try {
      const res = await getDocumentationFun().unwrap();
      let translations = await parseTranslationsFromResponse(
        res.getDocumentation?.translations,
      );

      return { ...res?.getDocumentation, translations };
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading: isLoading || isFetching,
    handleSetDocumentation,
    getDocumentation,
  };
};
