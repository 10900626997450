import { ErrorMessage, useField } from "formik";
import { Input, InputProps } from "formik-antd";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import styles from "./CustomTextField.module.scss";
import InfoCircleFilled from "@ant-design/icons/InfoCircleFilled";
import { Space, Tooltip } from "antd";
import { getLangDir } from "helpers/getLangDir";

interface Props extends InputProps {
  label?: string;
  placeholder?: string;
  type?: string;
  className?: string;
  hideError?: boolean;
  marginBtm?: boolean;
  isAddLanguage?: boolean;
  oldTextValue?: string;
  showEmptyLabel?: boolean;
  icon?: any;
  required?: boolean;
}

export function CustomTextField(props: Props) {
  const {
    name,
    label,
    className,
    type,
    marginBtm,
    showEmptyLabel,
    required,
    ...textFieldProps
  } = props;
  const { errorT } = useErrorTranslation();

  const [field, meta, helpers] = useField(name);
  const dir = getLangDir(meta.value);

  const inputInfoClassName = `${label ? styles["info-top"] : ""} ${
    dir === "rtl" ? styles["input-info-right"] : styles["input-info"]
  }`;

  const inputClassName = className
    ? `${className} ${dir === "rtl" ? "rtl-input" : ""}`
    : dir === "rtl"
    ? "from-input rtl-input"
    : "from-input";

  return (
    <div className={marginBtm === false ? "" : styles["form-control"]}>
      {(label || required) && (
        <Space size={2}>
          {required && (
            <span className={`${styles["label"]} ${styles["text-danger"]} `}>
              &#42;
            </span>
          )}
          {label && !showEmptyLabel && (
            <label className={styles["label"]} htmlFor={name}>
              {label}
            </label>
          )}
        </Space>
      )}
      {showEmptyLabel ? <div className="empty-label" /> : null}

      {type === "password" ? (
        <Input.Password
          name={name}
          placeholder={props.placeholder}
          className={className ? className : "from-input"}
          {...textFieldProps}
        />
      ) : (
        <Input
          {...textFieldProps}
          name={name}
          type={type}
          className={inputClassName}
          dir={dir}
        />
      )}
      {props?.isAddLanguage || props?.icon ? (
        <div className={inputInfoClassName}>
          {props?.icon || (
            <Tooltip title={props?.oldTextValue}>
              <InfoCircleFilled />
            </Tooltip>
          )}
        </div>
      ) : null}
      <div className={styles["error-msg"]}>
        {!props.hideError && (
          <ErrorMessage
            name={name}
            render={(msg) => <div className="text-danger">{errorT(msg)}</div>}
          />
        )}
      </div>
    </div>
  );
}
