import { useState } from "react";
import styles from "features/rewards/css/CompanyStats.module.scss";
import { useTranslation } from "react-i18next";
import { List } from "antd";
import CompanyItem from "./CompanyItem";
import { useGetCompaniesListByTierQuery } from "graphql/_generated/graphql";
import CompanyStatsDetails from "./CompanyStatsDetails";
interface CompanyStatsProps {
  tierId: string;
}

const CompanyStats: React.FunctionComponent<CompanyStatsProps> = ({
  tierId,
}) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const { data, isLoading } = useGetCompaniesListByTierQuery({
    tierId: tierId,
    pagination: {
      limit: 6,
    },
  });

  return (
    <div>
      <div className={styles["header"]}>
        <div className={styles["title"]}>{`${t("companies")} (${
          data?.getCompaniesListByTier.metadata.total ?? 0
        })`}</div>
        <div onClick={() => setVisible(true)} className={styles["see-btn"]}>
          {t("see-more")}
        </div>
      </div>
      <div className={styles["desc-container"]}>
        <List
          dataSource={data?.getCompaniesListByTier.data}
          renderItem={(item, index) => (
            <CompanyItem
              id={index + 1}
              title={item.companyName}
              image={item.image ?? ""}
              users={item.usersCount}
            />
          )}
          loading={isLoading}
        />
      </div>

      {visible && (
        <CompanyStatsDetails
          visible={visible}
          setVisible={setVisible}
          tierId={tierId}
        />
      )}
    </div>
  );
};

export default CompanyStats;
