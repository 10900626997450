import { ErrorMessage } from "formik";
import { Checkbox } from "formik-antd";
import { Checkbox as CheckboxAntD } from "antd";
import React from "react";
import styles from "./CustomCheckBoxGroup.module.scss";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
type Props = {
  name: string;
  label?: string;
  options: { label: string; value: string | boolean; icon?: string }[];
  children?: React.ReactNode;
  className?: string;
};

export function CustomCheckBoxGroup(props: Props) {
  const { name, label, options, children, className } = props;
  const { errorT } = useErrorTranslation();

  return (
    <>
      {label && (
        <label className="label" htmlFor={name}>
          {label}
        </label>
      )}
      {options.length < 2 ? (
        <Checkbox {...props}>{children}</Checkbox>
      ) : (
        <Checkbox.Group name={name} className={className}>
          {options.map((item, index) => (
            <div key={index}>
              <CheckboxAntD
                key={index}
                value={item.value}
                className={`pillar${index + 1}`}
              >
                <div className="hex">
                  <img src={item.icon} alt="" />
                </div>
                {children}
              </CheckboxAntD>
              <div className={styles["pillar-title"]}>{item.label}</div>
            </div>
          ))}
        </Checkbox.Group>
      )}
      <ErrorMessage
        name={name}
        render={(msg) => <div style={{ color: "red" }}>{errorT(msg)}</div>}
      />
    </>
  );
}
