// Third-party libraries
import { Col, Divider, Row } from "antd";
import { FieldArray, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Local components
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";

// Hooks
import { useLanguages, usePillarIdAndName } from "hooks";
import { useGetContentStatus } from "hooks/useGetContentStatus";
import { useCollection } from "../useCollection";

// GraphQL
import {
  Category,
  CollectionType,
  ConfigOptions,
  ContentType,
  Pillar,
  useGetCategoriesTreeQuery,
  useGetCollectionListQuery,
  useGetConfigTagsQuery,
  useLazyGetContentConfigQuery,
} from "graphql/_generated/graphql";

// Others
import styles from "./AddCollection.module.scss";
import { errorFormat } from "helpers";
import { filterLanguagesWithout, getLabelFromValue } from "../../utils";
import { collectionSchema } from "validations/schema";
import { MultiSelectSort } from "components/form/custom-sortable-select/CustomSortableSelect";
import { useErrorHandler } from "hooks/useErrorHandler";

interface AddCollectionProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
}

export interface CategoryWithChildren extends Category {
  children: Category[] | null;
}

const AddCollection: React.FunctionComponent<AddCollectionProps> = ({
  visible,
  setVisible,
  editId,
  setEditId,
}) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { pillarsData } = usePillarIdAndName();
  const { data: tagsData } = useGetConfigTagsQuery();
  const [getContentFunc] = useLazyGetContentConfigQuery();
  // const [pillarId, setPillarId] = useState(null);
  const [contentFilters, setContentFilters] = useState({});
  const [isContentLoading, setIsContentLoading] = useState(false);
  const { data: categoriesData } = useGetCategoriesTreeQuery(
    { pillarId: null },
    { refetchOnMountOrArgChange: true },
  );
  const { contentStatus } = useGetContentStatus();
  const { refetch } = useGetCollectionListQuery();
  const { languages } = useLanguages();
  const [contentData, setContentData] = useState<any>([]);
  const {
    handleAddNewCollection,
    handleFetchEditCollectionData,
    handleUpdateCollection,
    isLoading,
  } = useCollection();

  const [collectionTypeItems, setCollectionTypeItems] = useState<
    { label: string; value: string }[]
  >([]);

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  const defaultValues = {
    image: "",
    title: "",
    description: "",
    translations: [],
    pillarIds: [],
    categoryIds: [],
    collectionType: "",
    language: "",
    spokenLanguage: "",
    tagIds: [],
    contentIds: [],
    status: "",
  };

  const [initialValues, setInitialValues] = useState<any>(defaultValues);

  useEffect(() => {
    const collectionTypeItems = Object.keys(CollectionType).map(
      (item: string) => ({ label: item, value: item }),
    );
    setCollectionTypeItems(collectionTypeItems);
    fetchContent();
  }, []);

  useEffect(() => {
    const fetchEditCollectionData = async (id: string) => {
      const data = await handleFetchEditCollectionData(id);
      const dummyTags =
        data?.tags
          ?.filter(({ isActive }: { isActive: boolean }) => isActive)
          ?.map(({ id }: { id: string }) => id) || [];
      const contentArr =
        data?.contents?.map(({ id, title }: { id: string; title: string }) => ({
          id,
          label: title,
        })) || [];

      const v = {
        image: data?.image,
        title: data?.title,
        description: data?.description,
        translations: data?.translations,
        collectionType: data?.collectionType,
        language: data?.language,
        spokenLanguage: data?.spokenLanguage,
        pillarIds: data?.pillars?.map(({ id }: { id: string }) => id),
        categoryIds: data.categories
          .filter((category) => category.isActive)
          .map(({ id }) => id),
        tagIds: dummyTags,
        contentIds: contentArr,
        status: data?.status,
      };
      setInitialValues({
        ...defaultValues,
        ...v,
      });

      setContentFilters((prev) => ({
        ...prev,
        type: data?.collectionType,
      }));
    };

    editId ? fetchEditCollectionData(editId) : setInitialValues(defaultValues);
  }, [editId]);

  useEffect(() => {
    if (Object.keys(contentFilters).length) {
      fetchContent(contentFilters);
    }
  }, [contentFilters]);

  const fetchContent = async (args: { lang?: string; type?: string } = {}) => {
    const { lang, type } = args;
    setIsContentLoading(true);

    const params = {
      ...(lang && { language: lang }),
      ...(type === CollectionType.Topic && { type: ContentType.Video }),
    };

    try {
      const res = await getContentFunc(params);
      setContentData(res?.data?.getContentConfig?.options);
    } catch (error) {
      // Handle error
    } finally {
      setIsContentLoading(false);
    }
  };

  return (
    <CustomModal
      title={editId ? t("edit-program") : t("add-program")}
      width={900}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={collectionSchema}
        onSubmit={async (values, { resetForm }) => {
          if (!editId) {
            try {
              await handleAddNewCollection({ create: values });
              customNotification("success", "Program added successfully");
              refetch();
              setVisible(false);
              resetForm();
            } catch (error) {
              handleError(error as string | object);
            }
          } else {
            try {
              await handleUpdateCollection({ id: editId, updateObj: values });
              customNotification("success", "Program updated successfully");
              resetForm();
              refetch();
              setEditId(null);
              setVisible(false);
            } catch (error) {
              handleError(error as string | object);
            }
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <CustomUploadFile
              name="image"
              setFieldValue={setFieldValue}
              imgUrl={values.image}
              accept="image/*"
            />
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={12}>
                <CustomSelectField
                  label={t("text-language")}
                  required={true}
                  value={getLabelFromValue(languages, values)}
                  name="language"
                  placeholder={t("select-language")}
                  options={languages}
                  defaultValue={getLabelFromValue(languages, values)}
                  className="form-select"
                />
              </Col>
              <Col xs={24} sm={12}>
                <CustomTextField
                  name="title"
                  placeholder={t("title")}
                  label={t("title")}
                />
              </Col>
            </Row>
            <CustomTextEditor
              name="description"
              label={t("description")}
              placeholder={t("enter-description")}
            />

            {/*this field array is dynamically add the title and description */}
            <FieldArray
              name="translations"
              render={(arrayHelpers) => {
                return (
                  <div>
                    {values.translations && values.translations.length > 0 ? (
                      values.translations.map(
                        (translation: any, index: number) => {
                          return (
                            <div key={index}>
                              <div className="form-control">
                                <Row gutter={[24, 0]}>
                                  <Col xs={24} sm={12}>
                                    <CustomSelectField
                                      marginBtm={false}
                                      label={t("other-text-language")}
                                      required={true}
                                      value={getLabelFromValue(
                                        languages,
                                        values,
                                        index,
                                      )}
                                      name={`translations.${index}.lang`}
                                      placeholder={t("select-language")}
                                      options={languages}
                                      defaultValue={getLabelFromValue(
                                        languages,
                                        values,
                                        index,
                                      )}
                                      className="form-select"
                                    />
                                  </Col>
                                  <Col xs={24} sm={12}>
                                    <CustomTextField
                                      label={t(
                                        "write-title-in-selected-language",
                                      )}
                                      name={`translations.${index}.title`}
                                      placeholder={t(
                                        "enter-title-in-selected-language",
                                      )}
                                    />
                                  </Col>
                                </Row>
                              </div>
                              <CustomTextEditor
                                label={t(
                                  "write-description-in-selected-language",
                                )}
                                name={`translations.${index}.description`}
                                placeholder={t(
                                  "enter-description-in-selected-language",
                                )}
                              />

                              <div className={styles["flex-div"]}>
                                {values.translations.length + 1 <
                                languages.length ? (
                                  <span
                                    className={styles["add-btn"]}
                                    onClick={() =>
                                      arrayHelpers.push({
                                        lang: "",
                                        title: "",
                                        description: "",
                                      })
                                    } // insert an empty string at a position
                                  >
                                    + {t("add-new-language")}
                                  </span>
                                ) : (
                                  ""
                                )}
                                <span
                                  className={styles["remove-btn"]}
                                  onClick={() => arrayHelpers.remove(index)} // remove a language from the list
                                >
                                  - {t("remove-language")}
                                </span>
                              </div>
                              <Divider />
                            </div>
                          );
                        },
                      )
                    ) : (
                      <div className={styles["form-control"]}>
                        <span
                          className={styles["add-btn"]}
                          onClick={() =>
                            arrayHelpers.push({
                              lang: "",
                              title: "",
                              description: "",
                            })
                          }
                        >
                          + {t("add-new-language")}
                        </span>
                      </div>
                    )}
                  </div>
                );
              }}
            />
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={12}>
                <CustomSelectField
                  label={t("program-type")}
                  placeholder={t("select-program-type")}
                  name="collectionType"
                  options={collectionTypeItems}
                  onChange={(val) => {
                    setFieldValue("contentIds", null);
                    setContentFilters((prev) => ({
                      ...prev,
                      ...{ type: val },
                    }));
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <CustomSelectField
                  label={t("spoken-language")}
                  name="spokenLanguage"
                  placeholder={t("select-language")}
                  options={languages}
                  onChange={(val) => {
                    setFieldValue("contentIds", null);
                    setContentFilters((prev) => ({
                      ...prev,
                      ...{ lang: val },
                    }));
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <CustomSelectField
                  allowClear
                  mode="multiple"
                  label={t("pillar.pillars")}
                  className="form-select"
                  name="pillarIds"
                  placeholder={t("select-pillar")}
                  options={pillarsData}
                />
              </Col>
              <Col xs={24} sm={12}>
                <CustomTreeSelect
                  multiple
                  allowClear
                  showArrow
                  treeNodeFilterProp="name"
                  treeLine={true && { showLeafIcon: false }}
                  value={values.categoryIds}
                  label={t("category.categories")}
                  fieldNames={{
                    label: "name",
                    value: "id",
                  }}
                  className="form-select"
                  name="categoryIds"
                  placeholder={t("select-category")}
                  treeData={categoriesData?.getCategoriesTree.map(
                    (node: CategoryWithChildren) => ({
                      ...node,
                      disableCheckbox: !node.children,
                    }),
                  )}
                  treeCheckable
                  showCheckedStrategy="SHOW_CHILD"
                />
              </Col>

              <Col xs={24}>
                <CustomSelectField
                  label={t("status")}
                  name="status"
                  placeholder={t("select-status")}
                  className="form-select"
                  options={contentStatus}
                />
              </Col>
              <Col xs={24}>
                <CustomSelectField
                  mode="multiple"
                  name="tagIds"
                  label={t("tags")}
                  placeholder={t("select-tags")}
                  options={tagsData?.getConfigTags?.options}
                  fieldNames={{ label: "label", value: "id" }}
                />
              </Col>
              <Col xs={24}>
                <MultiSelectSort
                  setFieldValue={setFieldValue}
                  options={contentData!}
                  selected={values.contentIds}
                  name="contentIds"
                  label={t("content")}
                  placeholder={t("select-content")}
                  isDisabled={isContentLoading}
                />
              </Col>
            </Row>
            <ModalFooter
              saveText={t("save-program")}
              onCancel={handleCancel}
              loading={isLoading || isContentLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddCollection;
