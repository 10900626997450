import { useSelector } from "react-redux";
import { Location } from "react-router-dom";
import { useDeepMemo } from "./useDeepMemo";

// Custom hook to get and memoize navigation state
export const usePersistedState = (
  stateKey: string,
  location: Location,
  defaultValue: any,
) => {
  const state = useSelector((state: any) => state.navigation[stateKey]);
  const stateForCurrentPath = state[location.pathname] || defaultValue;
  return useDeepMemo(() => {
    return location.hash === "#persist" ? stateForCurrentPath : defaultValue;
  }, [stateForCurrentPath]);
};
