export const DollarSvg = () => {
  return (
    <svg
      width="14"
      height="24"
      viewBox="0 0 14 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.32"
        d="M9.552 19.76V18.176C9.04 18.1227 8.544 17.984 8.064 17.76C7.584 17.5253 7.17333 17.2533 6.832 16.944L7.44 16.032C7.78133 16.3307 8.15467 16.5867 8.56 16.8C8.976 17.0027 9.42933 17.104 9.92 17.104C10.5173 17.104 10.96 16.9547 11.248 16.656C11.5467 16.3467 11.696 15.9467 11.696 15.456C11.696 15.0187 11.5893 14.6613 11.376 14.384C11.1627 14.1067 10.8853 13.8667 10.544 13.664C10.2027 13.4613 9.84 13.2693 9.456 13.088C9.08267 12.896 8.72533 12.688 8.384 12.464C8.04267 12.2293 7.76533 11.9413 7.552 11.6C7.33867 11.2587 7.232 10.8267 7.232 10.304C7.232 9.58933 7.44533 8.99733 7.872 8.528C8.29867 8.048 8.85867 7.75467 9.552 7.648V6.032H10.512V7.616C11.024 7.66933 11.456 7.808 11.808 8.032C12.1707 8.256 12.496 8.52267 12.784 8.832L12.08 9.616C11.7813 9.328 11.488 9.104 11.2 8.944C10.9227 8.784 10.5653 8.704 10.128 8.704C9.63733 8.704 9.248 8.84267 8.96 9.12C8.672 9.39733 8.528 9.77067 8.528 10.24C8.528 10.624 8.63467 10.944 8.848 11.2C9.06133 11.4453 9.33867 11.6587 9.68 11.84C10.0213 12.0213 10.3787 12.2027 10.752 12.384C11.136 12.5653 11.4987 12.7787 11.84 13.024C12.1813 13.2587 12.4587 13.5627 12.672 13.936C12.8853 14.3093 12.992 14.784 12.992 15.36C12.992 16.1173 12.768 16.7467 12.32 17.248C11.872 17.7387 11.2693 18.0373 10.512 18.144V19.76H9.552Z"
        fill="#303030"
      />
    </svg>
  );
};
