import { Tabs } from "antd";
import CustomModal from "components/custom-modal/CustomModal";
import CustomTabs from "components/custom-tabs/CustomTabs";
import React from "react";
import { useTranslation } from "react-i18next";
import InviteMultipleUsers from "./InviteMultipleUsers";
import InviteSingleUser from "./InviteSingleUser";

const { TabPane } = Tabs;

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  employerId?: string | undefined;
};

export default function InviteUsers({
  visible,
  setVisible,
  employerId,
}: Props) {
  const { t } = useTranslation();
  const handleCancel = () => {
    setVisible(false);
  };

  const tabs = [
    {
      label: t("invite-single-user"),
      tab: <InviteSingleUser employerId={employerId} setVisible={setVisible} />,
    },
    {
      label: t("invite-bulk-users"),
      tab: (
        <InviteMultipleUsers employerId={employerId} setVisible={setVisible} />
      ),
    },
  ];

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={t("users.invite-users")}
    >
      <CustomTabs tabs={tabs} />
    </CustomModal>
  );
}
