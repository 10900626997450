import AddOption from "components/custom-button/AddOption";
import DeleteBtn from "components/custom-button/DeleteBtn";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { FieldArray } from "formik";
import * as React from "react";
import styles from "features/onboardings/css/AddOnBoarding.module.scss";
import { DeleteFilled } from "@ant-design/icons";
import { useLanguages } from "hooks/useLanguages";
import {
  filterLanguagesWithout,
  getLabelFromValue,
} from "features/content/utils";
import { useTranslation } from "react-i18next";

interface OnBoardingTranslationsProps {
  values: any;
}

const OnBoardingTranslations: React.FunctionComponent<
  OnBoardingTranslationsProps
> = ({ values }) => {
  const { t } = useTranslation();
  const { languagesWithOutEn } = useLanguages();
  return (
    <FieldArray
      name="translations"
      render={({ push, remove }) => (
        <>
          {values.translations &&
            values.translations.map((item: any, index: number) => (
              <div>
                <div className={styles["langs"]}>
                  <CustomSelectField
                    label={t("language")}
                    required={true}
                    value={getLabelFromValue(languagesWithOutEn, values, index)}
                    name={`translations.${index}.lang`}
                    placeholder={t("select-language")}
                    options={filterLanguagesWithout(
                      languagesWithOutEn,
                      values.translations,
                    )}
                    defaultValue={getLabelFromValue(
                      languagesWithOutEn,
                      values,
                      index,
                    )}
                  />
                  <CustomTextField
                    name={`translations.${index}.title`}
                    placeholder={t("title")}
                  />
                  <div className={styles["delete-btn"]}>
                    <DeleteBtn onClick={() => remove(index)}>
                      <DeleteFilled />
                    </DeleteBtn>
                  </div>
                </div>
                <CustomTextEditor name={`translations.${index}.description`} />
              </div>
            ))}
          {values.translations.length < 1 && (
            <div className={styles["add-translation"]}>
              <AddOption
                title={t("add-translation")}
                onClick={() =>
                  push({
                    lang: "",
                    title: "",
                    description: "",
                  })
                }
              />
            </div>
          )}
        </>
      )}
    />
  );
};

export default OnBoardingTranslations;
