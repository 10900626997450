import {
  ChallengeType,
  Trackable,
  CollectionType,
  ContentType,
} from "graphql/_generated/graphql";

export const ChallengeTypes = [
  { id: ChallengeType.Physical, title: "Physical Challenge" },
  { id: ChallengeType.Virtual, title: "Content Challenge" },
];

export const ChallengeGoalTypes: { [key: string]: any } = {
  [ChallengeType.Physical]: {
    goals: [
      {
        id: Trackable.Steps,
        name: "Steps (Walking)",
        unit: "Steps",
        min: 999,
        max: 999999,
      },
      {
        id: Trackable.Running,
        name: "km (Running)",
        unit: "KM",
        min: 1,
        max: 999999,
      },
      {
        id: Trackable.Cycling,
        name: "km (Cycling)",
        unit: "KM",
        min: 1,
        max: 9999,
      },
      {
        id: Trackable.Swimming,
        name: "m (Swimming)",
        unit: "M",
        min: 1,
        max: 99999,
      },
    ],
  },
  [ChallengeType.Virtual]: {
    goals: [
      // {
      //   name: "All",
      //   id: "Content-All",
      //   min: 1,
      //   max: 99,
      // },
      {
        name: "Topics",
        id: `Collection-${CollectionType.Topic}`,
        min: 1,
        max: 99,
      },
      {
        name: "Programs",
        id: `Collection-${CollectionType.Program}`,
        min: 1,
        max: 99,
      },
      {
        name: "Audio",
        id: `Content-${ContentType.Audio}`,
        min: 1,
        max: 99,
      },
      {
        name: "Recipe",
        id: `Content-${ContentType.Recipe}`,
        min: 1,
        max: 99,
      },
    ],
  },
};
