import CustomModal from "components/custom-modal/CustomModal";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import { Form, Formik } from "formik";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import uploadImgPlaceholder from "assets/images/imgPlaceHolder.svg";
import styles from "features/rewards/css/AddTier.module.scss";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import Icon from "@ant-design/icons";
import { PointsIcon } from "assets/icons/PointsSvg";
import ModalFooter from "components/custom-modal/ModalFooter";
import { useTier } from "../hooks/useTier";
import customNotification from "components/custom-notification/CustomNotification";
import { errorFormat, getLanguage } from "helpers";
import { addTierSchema } from "validations/schema/addTierSchema";
import { useGetTierDetailsQuery } from "graphql/_generated/graphql";
import { useErrorHandler } from "hooks/useErrorHandler";

interface AddTierProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId?: React.Dispatch<React.SetStateAction<string | null>>;
  langCode?: string;
  isAddLanguage?: boolean;
}

const AddTier: React.FunctionComponent<AddTierProps> = ({
  visible,
  setVisible,
  editId,
  setEditId,
  langCode,
  isAddLanguage,
}) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    points: "",
    tierCardImage: "",
    tierImage: "",
    tierIcon: "",
  });
  const handleCancel = () => {
    setVisible(false);
    setEditId?.(null);
  };

  const { handleAddTier, isLoading, handleGetTierDetail, handleUpdateTier } =
    useTier();

  useEffect(() => {
    if (editId) {
      handleGetTierDetail(editId).then((data) => {
        const translationData = langCode && data?.translations?.[langCode];
        setInitialValues({
          name: isAddLanguage ? translationData?.name : data?.name,
          points: data?.points,
          tierCardImage: data?.tierCardImage,
          tierImage: data?.tierImage,
          tierIcon: data?.tierIcon,
        });
      });
    }
  }, [editId]);
  const langLabel = getLanguage(langCode ?? "");
  return (
    <CustomModal
      title={
        editId && !isAddLanguage
          ? t("edit-tier")
          : `${t("add-tier")} ${isAddLanguage ? t("language") : ""}`
      }
      footer={false}
      visible={visible}
      onCancel={handleCancel}
      width={700}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={addTierSchema}
        onSubmit={async (values, { resetForm }) => {
          if (!editId && !isAddLanguage) {
            try {
              await handleAddTier({
                create: {
                  ...values,
                  language: langCode,
                  points: Number(values.points),
                },
              });
              customNotification("success", t("tier-added-successfully"));
              resetForm();
              setVisible(false);
            } catch (error) {
              handleError(error as string | object);
            }
          } else if (isAddLanguage && editId && langCode) {
            try {
              await handleUpdateTier({
                update: {
                  translations: {
                    [langCode]: {
                      name: values.name,
                    },
                  },
                },
                id: editId,
              });
              customNotification(
                "success",
                t("tier-language-added-successfully"),
              );
              resetForm();
              setVisible(false);
              await handleGetTierDetail(editId);
              setEditId?.(null);
            } catch (error) {
              handleError(error as string | object);
            }
          } else {
            try {
              await handleUpdateTier({ update: values, id: editId });
              customNotification("success", t("tier-updated-successfully"));
              setVisible(false);
              resetForm();
              await handleGetTierDetail(editId ?? "");
              setEditId?.(null);
            } catch (error) {
              handleError(error as string | object);
            }
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            {!isAddLanguage && (
              <div className={styles["tier-image"]}>
                <div className="challenge-detail-container">
                  <CustomUploadFile
                    infoText={t("tier-image")}
                    label={t("tier-image")}
                    setFieldValue={setFieldValue}
                    name="tierImage"
                    accept="image/png, .jpg, .jpeg"
                    customUploadText={<span>upload Image</span>}
                    customUploadImgSrc={uploadImgPlaceholder}
                    showFullPreview={true}
                    imgUrl={values.tierImage}
                  />
                </div>
                <div className="challenge-detail-container">
                  <CustomUploadFile
                    infoText={t("tier-viwell-card")}
                    label={t("tier-viwell-card")}
                    setFieldValue={setFieldValue}
                    name="tierCardImage"
                    accept="image/png, .jpg, .jpeg"
                    customUploadText={<span>upload Image</span>}
                    customUploadImgSrc={uploadImgPlaceholder}
                    showFullPreview={true}
                    imgUrl={values.tierCardImage}
                  />
                </div>
                <CustomUploadFile
                  infoText={t("tier-icon")}
                  label={t("tier-icon")}
                  setFieldValue={setFieldValue}
                  name="tierIcon"
                  accept="image/png, .jpg, .jpeg"
                  imgUrl={values.tierIcon}
                />
              </div>
            )}
            <CustomTextField
              name="name"
              label={t("tier-name")}
              placeholder={t("enter-tier-name")}
            />
            {!isAddLanguage && (
              <CustomTextField
                name="points"
                label={t("required-points")}
                placeholder={t("enter-tier-points")}
                suffix={<Icon component={PointsIcon} />}
                type="number"
              />
            )}
            <ModalFooter
              saveText={t("save-tier")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddTier;
