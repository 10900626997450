import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { useUpdateOrgMetrics } from "features/company-dashboard/hooks/useUpdateOrgMetrics";
import { orgStatSchema } from "validations/schema";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { useAddOrgMetrics } from "../hooks/useAddOrgMetrics";
import { getCompanyYears, getMonths } from "../utils";
import { useErrorHandler } from "hooks/useErrorHandler";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddEditOrgMetric: React.FC<Props> = ({
  visible,
  setVisible,
  orgMetric,
  getOrgMetricData,
  companyRegisteredDate,
}: any) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { handleUpdateOrgMetrics, isLoading } = useUpdateOrgMetrics();
  const { handleAddOrgMetrics, isLoading: isLoadingAddMetric } =
    useAddOrgMetrics();

  useEffect(() => {
    if (!orgMetric.value) return;
    const { year, month, value, unit, type } = orgMetric;
    setInitialValues({
      year: parseInt(year),
      month,
      value,
      metricUnit: unit,
      type,
    });
  }, [orgMetric]);

  const handleCancel = () => {
    setVisible(false);
  };

  const [initialValues, setInitialValues] = useState<any>({
    year: undefined,
    month: undefined,
    value: "",
    metricUnit: orgMetric.unit,
    type: orgMetric.type,
  });

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title={
        orgMetric?.value
          ? `${t("edit")} ${orgMetric?.title} ${t("value")}`
          : `${t("add")} ${orgMetric?.title} ${t("value")}`
      }
      showHeaderBottomBorder={true}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={orgStatSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            const obj = { ...values, value: values.value.toString() };
            if (initialValues?.value !== "") {
              await handleUpdateOrgMetrics({
                updateObj: obj,
              });
            } else {
              await handleAddOrgMetrics({
                create: obj,
              });
            }

            getOrgMetricData();
            setVisible(false);
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            <CustomSelectField
              label={t("year")}
              name="year"
              allowClear
              placeholder={t("year")}
              className="form-select"
              disabled={orgMetric?.value}
              options={getCompanyYears(companyRegisteredDate)}
              onChange={(value) => {
                setFieldValue("year", value);
                setFieldValue("month", null);
              }}
            />
            <CustomSelectField
              label={t("month")}
              name="month"
              allowClear
              placeholder={t("month")}
              className="form-select"
              disabled={orgMetric?.value}
              options={getMonths(values.year)}
            />
            <CustomTextField
              name="value"
              placeholder={t("enter-value")}
              label={t("value")}
              type="number"
              icon={orgMetric.icon}
              min={0}
              step={0.01}
            />
            <ModalFooter
              saveText={orgMetric?.value ? t("save") : t("add-value")}
              loading={isLoading || isLoadingAddMetric}
              onCancel={handleCancel}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddEditOrgMetric;
