import * as Yup from "yup";
import * as Validations from "validations/schema/common/validations";
import moment from "moment";

const ageSchema = Yup.string()
  .typeError("error:date-validation-error")
  .matches(
    /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
    "error:date-validation-error",
  )
  .transform(function (value, originalValue) {
    const parsedDate = moment(originalValue, "DD/MM/YYYY", true);
    return parsedDate.isValid() ? parsedDate.format("DD/MM/YYYY") : value;
  })
  .test("age", "error:age-validation-error", function (value) {
    if (typeof value === "undefined") {
      return false;
    }

    const dateValue = moment(value, "DD/MM/YYYY", true);
    const cutoff = moment().subtract(18, "years");
    const cutoffUpper = moment().subtract(100, "years");

    return dateValue.isBefore(cutoff) && dateValue.isAfter(cutoffUpper);
  });

const phoneValidation = Yup.string().matches(
  /^\+(?:[0-9]●?){6,14}[0-9]$/,
  "error:phone-validation-error",
);

const genderValidation = Yup.string().oneOf(
  ["Male", "Female", "Other"],
  "error:gender-validation-error",
);

export const inviteCommonSchema = Yup.object().shape({
  Email_address: Validations.email,
  Department_Name: Yup.string().required("error:department-required"),
  Employee_Position: Yup.string().required("error:position-required"),
  Building_Name: Yup.string().required("error:building-required"),
  First_Name: Yup.string().required("error:first-name-required"),
  Last_Name: Yup.string().required("error:last-name-required"),
  Date_of_Birth: ageSchema,
  Mobile_number: phoneValidation,
  Gender: genderValidation,
});

const inviteMultipleUsersSchema = Yup.object().shape({
  ...inviteCommonSchema.fields,
  City: Yup.string().required("error:city-required"),
  Country: Yup.string().required("error:country-required"),
});

export const InviteMultipleUsersSchemaForSuperAdmin = Yup.object().shape({
  parentId: Yup.string().required("error:company-required"),
  users: Yup.array()
    .of(
      Yup.object().shape({
        ...inviteMultipleUsersSchema.fields,
      }),
    )
    .min(1, "error:req-at-least-one-user"),
});

export const InviteMultipleUsersSchemaForClient = Yup.object().shape({
  users: Yup.array().of(
    Yup.object().shape({
      ...inviteMultipleUsersSchema.fields,
    }),
  ),
});
