import {
  MutationAddOrgMetricsArgs,
  useAddOrgMetricsMutation,
} from "graphql/_generated/graphql";

//define the type of useAddOrgMetrics hook
export type UseAddOrgMetrics = () => {
  isLoading: boolean;
  handleAddOrgMetrics: (values: MutationAddOrgMetricsArgs) => Promise<void>;
};

export const useAddOrgMetrics: UseAddOrgMetrics = () => {
  const [addOrgMetrics, { isLoading }] = useAddOrgMetricsMutation();

  //this function is responsible for adding OrgMetrics
  const handleAddOrgMetrics = async (values: MutationAddOrgMetricsArgs) => {
    try {
      await addOrgMetrics(values).unwrap();
    } catch (error: any) {
      throw error;
    }
  };

  return { isLoading, handleAddOrgMetrics };
};
