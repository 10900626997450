import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Tree, TreeProps } from "antd";
import { useTranslation } from "react-i18next";
import {
  RoleCreate,
  useGetAllPermissionsQuery,
  useGetConfigRolesQuery,
  useGetRoleListQuery,
} from "graphql/_generated/graphql";
import { useRoles } from "./hooks/useRoles";
import { useErrorHandler } from "hooks/useErrorHandler";
import { rolesSchema, permissionsSchema } from "validations/schema";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomTextArea } from "components/form/custom-text-area/CustomTextArea";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { removeDuplicatesInStringArr } from "helpers";
import TranslationFieldArr from "components/translation-field-array/TranslationFieldArr";
import styles from "./css/Permissions.module.scss";

interface AddRoleProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
}

type TreeNode = {
  key: string;
  label: string;
  children: TreeNode[] | undefined;
};

const ALL_PERMS = "All Permissions";

const AddRole: React.FunctionComponent<AddRoleProps> = ({
  setVisible,
  visible,
  editId,
  setEditId,
}) => {
  const {
    handleAddNewRole,
    handleFetchPreviousRoleData,
    handleUpdateRole,
    isLoading,
  } = useRoles();

  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { refetch } = useGetRoleListQuery();
  const { refetch: rolesFetch } = useGetConfigRolesQuery();
  const { data: permissionsDataArr } = useGetAllPermissionsQuery();
  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
    setPermissionsInitialValues([]);
    setInitialValues({ name: "", description: undefined, translations: [] });
    setShowPermissionsError(false);
  };
  const [permissionsInitialValues, setPermissionsInitialValues] = useState<any>(
    [],
  );

  const [showPermissionsError, setShowPermissionsError] =
    useState<boolean>(false);

  useEffect(() => {
    if (permissionsInitialValues.length > 0) {
      setShowPermissionsError(false);
    }
  }, [permissionsInitialValues]);

  const [initialValues, setInitialValues] = useState<Partial<RoleCreate>>({
    name: "",
    description: undefined,
    translations: [],
  });

  useEffect(() => {
    if (editId) {
      handleFetchPreviousRoleData(editId).then((data) => {
        setInitialValues({
          name: data.name,
          description: data?.description,
          translations: data?.translations,
        });
        setPermissionsInitialValues(data.permissions);
      });
    } else {
      setInitialValues({
        name: "",
        description: "",
        translations: [],
      });
    }
  }, [editId]);

  const onCheck: TreeProps["onCheck"] = (checkedKeys) => {
    let updatedKeys;

    if (typeof checkedKeys === "object" && "checked" in checkedKeys) {
      updatedKeys = checkedKeys.checked.filter((key) => key !== ALL_PERMS);
    } else {
      updatedKeys = checkedKeys.filter((key) => key !== ALL_PERMS);
    }

    setPermissionsInitialValues(updatedKeys);
  };

  return (
    <CustomModal
      width={600}
      title={editId ? t("edit-role") : t("add-new-role")}
      footer={null}
      visible={visible}
      onCancel={handleCancel}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={rolesSchema}
        onSubmit={async (values, { resetForm }) => {
          //this is manual validation for checkboxes this is because we are not handling the checkboxes with formik
          if (permissionsInitialValues.length === 0) {
            setShowPermissionsError(true);
            return;
          }

          try {
            if (!editId) {
              await handleAddNewRole({
                create: {
                  ...values,
                  name: values.name ?? "",
                  permissions: removeDuplicatesInStringArr(
                    permissionsInitialValues,
                  ),
                },
              });
              customNotification("success", t("role-added-successfully"));
            } else {
              await handleUpdateRole({
                updateObj: {
                  ...values,
                  permissions: removeDuplicatesInStringArr(
                    permissionsInitialValues,
                  ),
                },
                updateRoleId: editId,
              });
              customNotification("success", t("role-updated-successfully"));
            }

            resetForm();
            rolesFetch();
            refetch();
            setVisible(false);
            setEditId(null);
            setPermissionsInitialValues([]);
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ values }) => (
          <Form>
            <CustomTextField
              name="name"
              label={t("name")}
              placeholder={t("enter-name")}
            />
            <CustomTextArea
              label={t("description")}
              name="description"
              rows={5}
              placeholder={t("enter-description")}
            />
            <TranslationFieldArr values={values} attribute="name" />
            <div className={styles["mb"]}>
              <Tree
                showLine
                checkable
                onCheck={onCheck}
                checkedKeys={permissionsInitialValues}
                defaultExpandedKeys={[ALL_PERMS]}
                treeData={
                  [
                    {
                      key: ALL_PERMS,
                      label: t("all-permissions"),
                      children: permissionsDataArr?.getAllPermissions,
                    },
                  ] as TreeNode[]
                }
                fieldNames={{ title: "label", key: "key" }}
              />
              {showPermissionsError && (
                <div className={styles["error"]}>
                  {t("at-least-one-permission-required")}
                </div>
              )}
            </div>
            <ModalFooter
              saveText={t("save-role")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddRole;
