import styles from "./Playground.module.scss";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import CheckinHeader from "features/assessment/questions/checkin-header/CheckinHeader";
import Question from "features/assessment/questions/Question";
import { TestFunction, RetryHelper } from "helpers/RetryHelper";
import { useEffect, useState } from "react";
import checkArrayOrder from "helpers/checkArrayOrder";

const Playground: React.FunctionComponent = () => {

  const loadR = async () => {
    try {
      const r = await RetryHelper(TestFunction, {
        retryIntervalMs: 1000,
        logs: true,
        exponentialBackoff: true
      });
      console.log('rrrr', r);
    } catch (error) {
      console.error("errorerrorerror", error);
    }
  }

  useEffect(() => {
    // loadR()
    console.log('orderorderorder', checkArrayOrder([1, 2, 3, 4, 6, 5]));
  }, [])

  return (
    <DashboardLayout>
      {/* <Question questionType="multipleChoice" /> */}
      {/* <CheckinQuestion /> */}
    </DashboardLayout>
  );
};

export default Playground;
