import {
  MutationUpdateOrgMetricArgs,
  useUpdateOrgMetricMutation,
} from "graphql/_generated/graphql";

//define the type of useUpdateOrgMetrics hook
export type UseUpdateOrgMetrics = () => {
  isLoading: boolean;
  handleUpdateOrgMetrics: (
    values: MutationUpdateOrgMetricArgs,
  ) => Promise<void>;
};

export const useUpdateOrgMetrics: UseUpdateOrgMetrics = () => {
  const [updateOrgMetrics, { isLoading }] = useUpdateOrgMetricMutation();
  //this function is responsible for updating OrgMetrics
  const handleUpdateOrgMetrics = async (
    values: MutationUpdateOrgMetricArgs,
  ) => {
    try {
      await updateOrgMetrics(values).unwrap();
    } catch (error: any) {
      throw error;
    }
  };

  return { isLoading, handleUpdateOrgMetrics };
};
