import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import { Form, Formik } from "formik";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import {
  useGetEmployeeListQuery,
  useLazyGetClientsTreeQuery,
  useLazyGetPositionsConfigQuery,
  useLazyGetBuildingsConfigQuery,
  useLazyGetDepartmentsConfigQuery,
  useLazyGetEmployeeListQuery,
} from "graphql/_generated/graphql";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  inviteSingleUserSchemaForClient,
  inviteSingleUserSchemaForSuperAdmin,
} from "validations/schema";
import { useEditEmployeeDetail } from "../hooks/useEditEmployee";
import { useUsers } from "../hooks/useUsers";
import { editUserSchema } from "validations/schema/inviteSingleUserSchema";
import CustomPhoneInput from "components/form/custom-phone-input/CustomPhoneInput";
import { CustomDatePicker } from "components/form/custom-date-picker/CustomDatePicker";
import moment from "moment";
import { Modal, Select } from "antd";
import { CONFIRM_MODAL_CONFIG } from "helpers/constants";
import styles from "features/users/css/InviteSingleUsers.module.scss";
import { deepCompareObjects } from "helpers/deepCompareObjects";
import { useErrorHandler } from "hooks/useErrorHandler";

const { Option } = Select;

type Props = {
  setVisible: (x: boolean) => void;
  employerId?: string | undefined;
  employeeId?: string | undefined;
  selectedEmployee?: any;
  setEmployeeId?: React.Dispatch<React.SetStateAction<string | null>>;
};

export enum Gender {
  Male = "Male",
  Female = "Female",
  Other = "Other",
}
interface EmployeeCreateDto {
  email: string;
  departmentId?: string;
  positionId?: string;
  buildingId?: string;
  // city: string;
  firstName?: string;
  lastName?: string;
  gender?: Gender;
  phoneNumber?: string | null;
  dob?: string;
}

interface EmployeeData {
  employeeCreateDto: EmployeeCreateDto;
  employerId: string;
}

const InviteSingleUser = (props: Props) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const [employerId, setEmployerId] = useState<null | string>(null);
  const [departmentId, setDepartmentId] = useState<null | string>(null);
  const [configData, setConfigData] = useState<{
    companies: any;
    departments: any;
    positions: any;
    buildings: any;
  }>({
    companies: [],
    departments: [],
    positions: [],
    buildings: [],
  });
  const {
    companyId: loggedInEmployerId,
    isClient,
    isSuperAdmin,
    isViwellAdmin,
  } = useLoggedInUser();

  const { refetch } = useGetEmployeeListQuery();

  const [getEmployeeListFun] = useLazyGetEmployeeListQuery();

  const {
    isLoading: isLoadingEmployee,
    handleFetchEmployeeDetailData,
    handleUpdateEmployeeDetail,
    handleUpdateEmployeeEmail,
  } = useEditEmployeeDetail();
  const [getClientsTreeQueryFun, { isLoading: isClientLoading }] =
    useLazyGetClientsTreeQuery();
  const [getDepartmentsConfigQuery] = useLazyGetDepartmentsConfigQuery();
  const [getPositionsConfigQuery] = useLazyGetPositionsConfigQuery();
  const [getBuildingsConfigQuery] = useLazyGetBuildingsConfigQuery();
  const isEditMode = !!props.employeeId;
  const { isLoading, inviteSingleEmployee } = useUsers();
  const initValues: EmployeeData = useMemo(
    () => ({
      employeeCreateDto: {
        email: "",
        departmentId: "",
        positionId: "",
        buildingId: "",
        // city: "",
        phoneNumber: null,
        firstName: "",
        lastName: "",
        dob: moment("1980-01-01").format("DD/MM/YYYY"),
        gender: Gender.Male,
      },
      employerId: "",
    }),
    [],
  );
  const [initialValues, setInitialValues] = useState<EmployeeData>(initValues);

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    fetchConfigs();
  }, [employerId]);

  useEffect(() => {
    fetchPositions();
  }, [employerId, departmentId]);

  useEffect(() => {
    getEmployeeDetail();
  }, [props.employeeId]);

  useEffect(() => {
    if (configData.positions?.options?.length <= 0) return;

    const position = configData.positions?.options?.find(
      (x: any) => x?.id === initialValues?.employeeCreateDto?.positionId,
    );
    position &&
      setInitialValues((value) => {
        return {
          ...value,
          employeeCreateDto: {
            ...value.employeeCreateDto,
            departmentId: position.extra?.departmentId,
          },
        };
      });
  }, [
    configData.positions,
    props.employeeId,
    initialValues?.employeeCreateDto?.positionId,
  ]);

  const getEmployeeDetail = async () => {
    if (isEditMode) {
      try {
        const { buildingId, positionId, clientId } =
          await handleFetchEmployeeDetailData(props.employeeId!);

        const { email, firstName, lastName, dob, gender, phoneNumber } =
          props.selectedEmployee;

        setInitialValues({
          employeeCreateDto: {
            ...initValues.employeeCreateDto,
            email: email || "",
            // city,
            positionId,
            buildingId,
            firstName,
            lastName,
            dob,
            gender,
            phoneNumber,
          },
          employerId: clientId!,
        });

        setEmployerId(clientId);
      } catch (error) {
        const {
          email,
          // city,
          employer: { id: employerId },
        } = props.selectedEmployee;
        setInitialValues({
          employeeCreateDto: {
            ...initValues.employeeCreateDto,
            email: email || "",
            // city,
          },
          employerId,
        });

        setEmployerId(props.selectedEmployee?.employer?.id);
      }
    } else {
      setInitialValues(initValues);
    }
  };

  useEffect(() => {
    if (props.employerId) {
      setEmployerId(props.employerId);
      setInitialValues((value) => {
        value.employerId = props.employerId!;
        return value;
      });
    }

    if (loggedInEmployerId) {
      setEmployerId(loggedInEmployerId);
    }
  }, [loggedInEmployerId, props.employerId]);

  const fetchPositions = async () => {
    if ((props.employerId || loggedInEmployerId) && !employerId) return;

    const { data: positionData } = await getPositionsConfigQuery({
      clientId: employerId,
      departmentId,
    });

    setConfigData((prevData) => ({
      ...prevData,
      positions: positionData?.getPositionsConfig,
    }));
  };

  const fetchClients = async () => {
    const { data: clientData } = await getClientsTreeQueryFun({
      clientId: null,
    });

    setConfigData((prevData) => ({
      ...prevData,
      companies: clientData?.getClientsTree,
    }));
  };

  const fetchConfigs = async () => {
    if ((props.employerId || loggedInEmployerId) && !employerId) return;

    const [{ data: departmentData }, { data: buildingsData }] =
      await Promise.all<any>([
        getDepartmentsConfigQuery({
          clientId: employerId,
        }),

        getBuildingsConfigQuery({
          clientId: employerId,
        }),
      ]);

    setConfigData((prevData) => ({
      ...prevData,
      departments: departmentData?.getDepartmentsConfig,
      buildings: buildingsData?.getBuildingsConfig,
    }));
  };

  return (
    <div>
      <Formik
        enableReinitialize
        validateOnBlur
        initialValues={initialValues}
        validationSchema={
          (isSuperAdmin || isViwellAdmin) && !isEditMode
            ? inviteSingleUserSchemaForSuperAdmin
            : isClient && !isEditMode
            ? inviteSingleUserSchemaForClient
            : isEditMode
            ? editUserSchema
            : null
        }
        onSubmit={async (values, { resetForm }) => {
          try {
            if (
              isEditMode &&
              values.employeeCreateDto.email ===
                initialValues.employeeCreateDto.email
            ) {
              await handleUpdateEmployeeDetail({
                id: props.employeeId,
                clientId: values.employerId,
                update: values.employeeCreateDto,
              });
              customNotification("success", t("employee-updated-successfully"));
              resetForm();
              refetch();
              props.setVisible(false);
              props.setEmployeeId && props.setEmployeeId(null);
            } else if (
              isEditMode &&
              values.employeeCreateDto.email !=
                initValues.employeeCreateDto.email
            ) {
              props.setVisible(false);
              Modal.confirm({
                ...CONFIRM_MODAL_CONFIG,
                closable: true,
                centered: true,
                title: t("warning"),
                content: (
                  <>
                    <div className={styles["confirm-content"]}>
                      {t("confirm-email-change-for-employee")}
                    </div>
                  </>
                ),
                width: 600,
                onOk: async () => {
                  try {
                    if (props.employeeId) {
                      //first API call
                      const msg = await handleUpdateEmployeeEmail(
                        props.employeeId,
                        values.employeeCreateDto.email,
                      );
                      customNotification("success", msg);
                      await getEmployeeListFun().unwrap();
                      //2nd API call incase other fields are changed
                      const { email, ...restValues } = values.employeeCreateDto;
                      const { email: initialEmail, ...restInitialValues } =
                        initialValues.employeeCreateDto;
                      if (!deepCompareObjects(restValues, restInitialValues)) {
                        await handleUpdateEmployeeDetail({
                          id: props.employeeId,
                          clientId: values.employerId,
                          update: values.employeeCreateDto,
                        });
                        await getEmployeeListFun().unwrap();
                      }
                    }
                    resetForm();
                    props.setVisible(false);
                    props.setEmployeeId && props.setEmployeeId(null);
                  } catch (error) {
                    handleError(error as string | object);
                  }
                },
              });
            } else {
              await inviteSingleEmployee({
                parentId: employerId,
                employeeCreateDto: values.employeeCreateDto,
              });
              customNotification("success", t("employee-invited-successfully"));
              resetForm();
              refetch();
              props.setVisible(false);
              props.setEmployeeId && props.setEmployeeId(null);
            }
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            {(!isEditMode || props.selectedEmployee?.email) && (
              <CustomTextField
                disabled={props.selectedEmployee?.isProfileComplete}
                label={t("email")}
                name="employeeCreateDto.email"
                placeholder={t("please-enter-your-email")}
                required
              />
            )}

            {!isClient && (
              <CustomTreeSelect
                allowClear
                showArrow
                showSearch={false}
                treeNodeFilterProp="companyName"
                disabled={isEditMode || !!props.employerId}
                // value={employerId}
                treeLine={true && { showLeafIcon: false }}
                label={t("company")}
                className="form-select"
                name="employerId"
                treeData={configData.companies}
                placeholder={t("select-company")}
                fieldNames={{ label: "companyName", value: "id" }}
                onChange={(values) => {
                  setFieldValue("employerId", values);
                  setFieldValue("employeeCreateDto.departmentId", "");
                  setFieldValue("employeeCreateDto.positionId", "");
                  setEmployerId(values);
                }}
                required
              />
            )}
            <CustomTextField
              label={t("first-name")}
              name="employeeCreateDto.firstName"
              placeholder=""
              required
            />
            <CustomTextField
              label={t("last-name")}
              name="employeeCreateDto.lastName"
              placeholder=""
              required
            />
            <CustomSelectField
              label={t("gender")}
              defaultValue={Gender.Male}
              placeholder={t("gender")}
              name="employeeCreateDto.gender"
            >
              {Object.values(Gender).map((item, index) => (
                <Option value={item}>{item}</Option>
              ))}
            </CustomSelectField>
            <CustomPhoneInput
              value={values.employeeCreateDto?.phoneNumber}
              setFieldValue={setFieldValue}
              name="employeeCreateDto.phoneNumber"
              placeholder={t("contact-number")}
              label={t("contact-number")}
            />
            <CustomDatePicker
              name="employeeCreateDto.dob"
              label={t("date-of-birth")}
              format="DD/MM/YYYY"
              placeholder={t("select-date-of-birth")}
              disabledDate={(current) => {
                // Disable all dates after today
                return current && current > moment().endOf("day");
              }}
              required
            />
            <CustomSelectField
              label={t("department")}
              className="form-select"
              showSearch={false}
              name="employeeCreateDto.departmentId"
              options={configData.departments?.options}
              fieldNames={{ label: "label", value: "id" }}
              placeholder={t("select-department")}
              onChange={(values) => {
                setDepartmentId(values);
                setFieldValue("employeeCreateDto.positionId", "");
              }}
              required
            />
            <CustomSelectField
              label={t("position")}
              className="form-select"
              showSearch={false}
              name="employeeCreateDto.positionId"
              options={configData.positions?.options}
              placeholder={t("select-positions")}
              fieldNames={{ label: "label", value: "id" }}
              disabled={!values.employeeCreateDto.departmentId}
              required
            />
            <CustomSelectField
              label={t("building")}
              className="form-select"
              showSearch={false}
              name="employeeCreateDto.buildingId"
              options={configData.buildings?.options}
              fieldNames={{ label: "label", value: "id" }}
              placeholder={t("select-building")}
              required
            />
            <ModalFooter
              saveText={props.employeeId ? t("update-user") : t("invite-user")}
              loading={isLoading || isLoadingEmployee}
              onCancel={() => {
                props.setVisible(false);
                props.setEmployeeId && props.setEmployeeId(null);
              }}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InviteSingleUser;
