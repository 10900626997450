import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setSearch } from "store/navigationSlice";
import { usePersistedState } from "./usePersistedState";

export const defaultSearch = { search: undefined };

const useSearch = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = usePersistedState("search", location, defaultSearch);

  const handleSearch = (e: any) => {
    let params;
    if (e.target.value) {
      params = { text: e.target.value };
    }
    dispatch(
      setSearch({
        location,
        ...{ search: params },
      }),
    );

    navigate("#persist");
  };

  return { search, handleSearch };
};

export default useSearch;
