import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import { Form, Formik } from "formik";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { addRewardCategory } from "validations/schema/addRewardCategorySchema";
import { useTier } from "../hooks/useTier";
import customNotification from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers/errorFormat";
import { RewardCategoryCreate } from "graphql/_generated/graphql";
import { getLanguage } from "helpers";
import { useErrorHandler } from "hooks/useErrorHandler";

interface AddRewardCategoryProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
  langCode?: string;
  isAddLanguage?: boolean;
}

const AddRewardCategory: React.FunctionComponent<AddRewardCategoryProps> = ({
  visible,
  setVisible,
  editId,
  setEditId,
  isAddLanguage,
  langCode,
}) => {
  const { handleError } = useErrorHandler();
  const { t } = useTranslation();

  const {
    isLoading,
    handleAddRewardCategory,
    handleGetRewardCategory,
    handleUpdateRewardCategory,
  } = useTier();

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  const [initialValues, setInitialValues] = useState<RewardCategoryCreate>({
    language: "",
    image: "",
    name: "",
  });

  useEffect(() => {
    if (editId) {
      handleGetRewardCategory(editId).then((data) => {
        const translationData = langCode && data?.translations?.[langCode];
        setInitialValues({
          name: isAddLanguage ? translationData?.name : data?.name,
          image: data.image,
          language: data.language,
        });
      });
    }
  }, [editId]);

  const langLabel = getLanguage(langCode ?? "");

  return (
    <CustomModal
      title={
        editId && !isAddLanguage
          ? t("edit-category")
          : `${t("add-category")} ${isAddLanguage ? t("language") : ""}`
      }
      footer={false}
      visible={visible}
      onCancel={handleCancel}
      width={600}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={addRewardCategory}
        onSubmit={async (values, { resetForm }) => {
          if (!editId && !isAddLanguage) {
            try {
              await handleAddRewardCategory({
                create: {
                  ...values,
                  language: langCode,
                },
              });
              customNotification(
                "success",
                t("reward-category-added-successfully"),
              );
              resetForm();
              setVisible(false);
            } catch (error) {
              handleError(error as string | object);
            }
          } else if (isAddLanguage && editId && langCode) {
            try {
              await handleUpdateRewardCategory({
                update: {
                  translations: {
                    [langCode]: {
                      name: values.name,
                    },
                  },
                },
                id: editId,
              });
              setEditId(null);
              customNotification(
                "success",
                t("reward-category-language-added-successfully"),
              );
              resetForm();
              setVisible(false);
            } catch (error) {
              handleError(error as string | object);
            }
          } else {
            try {
              await handleUpdateRewardCategory({
                update: values,
                id: editId,
              });
              customNotification(
                "success",
                t("reward-category-updated-successfully"),
              );
              setEditId(null);
              setVisible(false);
              resetForm();
            } catch (error) {
              handleError(error as string | object);
            }
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            {!isAddLanguage && (
              <CustomUploadFile
                name="image"
                setFieldValue={setFieldValue}
                accept="image/*"
                imgUrl={values.image}
              />
            )}
            <CustomTextField
              label={t("category-title")}
              name="name"
              placeholder={t("enter-category-name")}
            />
            <ModalFooter
              saveText={t("save-category")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddRewardCategory;
