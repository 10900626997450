import Dragger from "antd/lib/upload/Dragger";
import CustomModal from "components/custom-modal/CustomModal";
import { Form, Formik } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styles from "features/rewards/css/AddBulkRewards.module.scss";
import cloudUpload from "assets/images/cloud-upload.svg";
import CustomButton from "components/custom-button/CustomButton";
import ModalFooter from "components/custom-modal/ModalFooter";
import * as XLSX from "xlsx";
import { normalizeRewardKeys } from "helpers/normalizeRewardKeys";
import { HandleErrors } from "features/users/invite-users/HandleErrors";
import { ImportBulkRewardsSchema } from "validations/schema/addPartnerRewardSchema";
import { useReward } from "../hooks/useReward";
import customNotification from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers/errorFormat";
import { Language } from "graphql/_generated/graphql";
import { Modal } from "antd";
import BulkRewardsErrors from "./BulkRewardsErrors";
import { keyMapper } from "helpers";
import { rewardDataMap } from "./rewardDataMap";
import { getLanguageCode } from "helpers/getLanguages";
import { useErrorHandler } from "hooks/useErrorHandler";
import { CONFIRM_MODAL_CONFIG } from "helpers/constants";

interface AddBulkRewardsProps {
  partnerId: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddBulkRewards: React.FunctionComponent<AddBulkRewardsProps> = ({
  partnerId,
  visible,
  setVisible,
}) => {
  const { handleError } = useErrorHandler();
  const { t } = useTranslation();

  const handleCancel = () => {
    setVisible(false);
  };

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const { handleImportBulkRewards, isLoading } = useReward();

  const initialObj = {
    partnerId: partnerId,
    rewards: [
      {
        name: "",
        isDateEnable: false,
        startDate: undefined,
        endDate: undefined,
        nameAR: undefined,
        descriptionAR: undefined,
      },
    ],
  };

  return (
    <CustomModal
      title={t("offers.import")}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      centered
      width={540}
    >
      <Formik
        initialValues={initialObj}
        validationSchema={ImportBulkRewardsSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            const rewards = values.rewards.map((item) => {
              const { nameAR, descriptionAR, ...rest } = item;
              const translations = nameAR
                ? { ar: { name: nameAR, description: descriptionAR } }
                : null;
              return {
                ...rest,
                language: getLanguageCode(Language.English),
                translations,
              };
            });
            const res = await handleImportBulkRewards({
              createBulk: {
                partnerId: values.partnerId,
                rewards,
              },
            });
            resetForm();
            setVisible(false);
            if (res.recordsImported) {
              customNotification("success", t("rewards-imported-successfully"));
            } else if (res.errors) {
              Modal.error({
                ...CONFIRM_MODAL_CONFIG,
                closable: true,
                centered: true,
                title: t("can-not-upload"),
                width: 700,
                content: <BulkRewardsErrors data={res.errors} />,
              });
            }
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ setFieldValue, errors }) => (
          <Form>
            <div className={styles["uploader"]}>
              <Dragger
                maxCount={1}
                multiple={false}
                accept=".xlsx,.xls"
                customRequest={dummyRequest}
                onChange={async (e: any) => {
                  try {
                    const file = e.file.originFileObj;
                    const data = await file.arrayBuffer();
                    const workbook = await XLSX.read(data);
                    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                    const jsonData = await XLSX.utils.sheet_to_json(worksheet, {
                      raw: false,
                    });
                    jsonData.forEach((item: any) => {
                      item.isDateEnable = item.isDateEnable === "TRUE";
                    });
                    const normalizedData = normalizeRewardKeys(jsonData);
                    const mappedData = keyMapper(normalizedData, rewardDataMap);
                    setFieldValue("rewards", mappedData);
                  } catch (e) {
                    console.log(e);
                  }
                }}
              >
                <div className={styles["center-div"]}>
                  <img src={cloudUpload} alt="Upload" />
                  <h2 className={styles["select-file"]}>
                    {t("select-xls-file")}
                  </h2>
                  <div className={styles["file-size"]}>{t("file-size")}</div>
                  <div>
                    <a
                      className={styles["downloadLink"]}
                      href={process.env.PUBLIC_URL + "/Bulk_Rewards.xlsx"}
                      download={"Bulk_Rewards.xlsx"}
                    >
                      {t("download-file-template")}
                    </a>
                  </div>
                  <CustomButton className="primary-btn">
                    {t("select-reward-file")}
                  </CustomButton>
                </div>
              </Dragger>
              <HandleErrors errors={errors.rewards} />
            </div>
            <ModalFooter
              saveText={t("import-rewards")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddBulkRewards;
