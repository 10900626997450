import { convertTranslationArrToObj } from "helpers";
import {
  MutationAddNewDepartmentArgs,
  useAddNewDepartmentMutation,
  useGetDepartmentListQuery,
} from "graphql/_generated/graphql";

export const useAddDepartment = () => {

  const [addDepartmentFun, { isLoading }] = useAddNewDepartmentMutation();
  const { refetch } = useGetDepartmentListQuery();

  //this function is responsible for adding the department data
  const handleAddDepartment = async (values: MutationAddNewDepartmentArgs) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.create.translations
      );
      let params = { create: { ...values.create, translations } };
      await addDepartmentFun(params).unwrap();
      refetch();

    } catch (error: any) {
      throw error;
    }
  };

  return { isLoading, handleAddDepartment };
};
