import {
  useDeleteRewardCategoryMutation,
  useGetRewardCategoryListQuery,
} from "graphql/_generated/graphql";

export const useDeleteRewardCategory = () => {
  const [deleteCategoryFun, { isLoading }] = useDeleteRewardCategoryMutation();
  const { refetch } = useGetRewardCategoryListQuery();

  const handleDeleteRewardCategory = async (id: string) => {
    try {
      await deleteCategoryFun({ deleteRewardCategoryId: id }).unwrap();
      refetch();
    } catch (error) {
      throw error;
    }
  };
  return {
    handleDeleteRewardCategory,
    isLoading,
  };
};
