import axios from "axios";
import { useLazyGetSignedUrlQuery } from "graphql/_generated/graphql";
import { useState } from "react";

type TypeUseImageUpload = () => {
  loading: boolean;
  handleImageUpload: (file: any, config: any) => Promise<string>;
};

export const useImageUpload: TypeUseImageUpload = () => {
  const [imgUploadFun] = useLazyGetSignedUrlQuery();
  const [loading, setLoading] = useState<boolean>(false);

  const handleImageUpload = async (file: any, config: any) => {
    setLoading(true);
    let extension = file.name.split(".").pop().toLowerCase();

    try {
      const res = await imgUploadFun({ extension }).unwrap();

      const response = await axios.put(res.getSignedUrl, file, config);
      setLoading(false);

      return response.request.responseURL.split("?")[0];
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  return { loading, handleImageUpload };
};
