import "antd/dist/antd.css";
import "./assets/fonts/almarai-fonts.css";
import { routes } from "routes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { permissions } from "store/permissionsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import getAdminRoutes from "routes/getAdminRoutes";
import { useLazyGetPermissionsQuery } from "graphql/_generated/graphql";
import NotFound from "components/results/not-found/NotFound";
import { useZohoScript } from "components/zoho-chat/useZohoScript";
import { checkIfPermittedRoutes, parseJwt, whereToNavigate } from "helpers";
import { CONFIG } from "helpers/constants";
import "assets/css/App.scss";
import Redirect from "components/results/redirect/Redirect";

const downloadUrl = process.env.REACT_APP_DOWNLOAD_URL || CONFIG.DOWNLOAD_URL;
const widgetUrl =
  process.env.REACT_APP_ZOHO_SALES_IQ_WIDGET_URL ||
  CONFIG.ZOHO_SALES_IQ_WIDGET_URL;
const widgetCode =
  process.env.REACT_APP_ZOHO_SALES_IQ_WIDGET_CODE ||
  CONFIG.ZOHO_SALES_IQ_WIDGET_CODE;

function App() {
  const dispatch = useDispatch();
  const { token } = useSelector((state: any) => state.auth);
  const { userType, isProfileComplete } = parseJwt(token);
  const permissionsArr = useSelector(
    (state: any) => state.permissions.permissions,
  );

  // this will change the direction
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();

  const [finalRoutes, setFinalRoutes] = useState<any>([]);
  const [getPermissionsFun, { isLoading }] = useLazyGetPermissionsQuery();

  async function getPermissions() {
    const data = await getPermissionsFun().unwrap();

    dispatch(permissions(data?.getPermissions));
  }

  useZohoScript(widgetUrl!, widgetCode!);

  useEffect(() => {
    if (userType) getPermissions();
  }, [userType]);

  useEffect(() => {
    const adminRoutes = getAdminRoutes(userType);
    const isLoggedIn = !!token;

    if (isLoggedIn) {
      if (isProfileComplete) {
        setFinalRoutes(
          checkIfPermittedRoutes(
            [...routes.loggedIn, ...adminRoutes],
            permissionsArr,
            userType,
          ),
        );
      } else {
        setFinalRoutes(routes.onBoarding);
      }
    } else {
      setFinalRoutes(routes.loggedOut);
    }
  }, [token, permissionsArr]);

  if (isLoading) {
    return <>Loading</>;
  }

  return (
    <>
      <div className="App">
        <Routes>
          {finalRoutes.map(
            (item: { path: string; element: any }, index: number) => {
              return (
                <Route path={item.path} element={item.element} key={index} />
              );
            },
          )}

          <Route
            path="/"
            element={
              <Navigate
                to={whereToNavigate(token, isProfileComplete, userType)}
              />
            }
          />

          <Route
            path="/signup-mobile/*"
            element={<Redirect url={downloadUrl!} />}
          />
          <Route
            path="/verifyOtp-mobile/*"
            element={<Redirect url={downloadUrl!} />}
          />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
