import packageJson from "../../../../package.json";
import "./Sidebar.module.scss";
import { Layout, Menu } from "antd";
import Icon from "@ant-design/icons";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import viwellLogo from "assets/images/viwell-logo.svg";
import React, { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import viwellMobLogo from "assets/images/viwell-mob-logo.svg";
import { checkIfPermitted, parseJwt, PERMS } from "helpers";
import {
  ActivitySvg,
  AdminSvg,
  AssessmentListSvg,
  AssessmentSvg,
  AssessmentTypeSvg,
  AvatarSvg,
  BadgeSvg,
  ChallengeSvg,
  ChervanLeftSvg,
  ChervanRightSvg,
  ClipboardSvg,
  CompanySvg,
  ConfigSvg,
  ContentsSvg,
  ContentSvg,
  DashboardSvg,
  DepartmentSvg,
  EmployeesSvg,
  ExpertSvg,
  MetricSvg,
  PositionSvg,
  ProgramSvg,
  RangeSvg,
  ReportSvg,
  RewardSvg,
  UnitSvg,
  UserSvg,
  ChallengeRewardsSvg,
  PartnerSvg,
} from "assets/icons";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { OnBoardingSvg } from "assets/icons/OnBoardingSvg";
import { DeleteRequestsSvg } from "assets/icons/DeleteRequestsSvg";
import { DeleteAccountsSvg } from "assets/icons/DeleteAccountsSvg";
import { TiersIcon } from "assets/icons/TiersSvg";
import { MenuCalendarSvg } from "assets/icons/MenuCalendarSvg";

interface SidebarProps {
  openKey: string[];
}

const Sidebar: React.FunctionComponent<SidebarProps> = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [collapsed, setCollapsed] = useState(false);
  const { t, i18n } = useTranslation();
  const dir = i18n.dir();
  const { Sider } = Layout;
  const navigate = useNavigate();

  const permissionsArr = useSelector(
    (state: any) => state.permissions.permissions,
  );

  const { isClient, isSuperAdmin, isViwellAdmin, userType } = useLoggedInUser();

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  useLayoutEffect(() => {
    width < 992 ? setCollapsed(true) : setCollapsed(false);
  }, [width]);

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const rootSubmenuKeys = [
    "Users",
    "Content",
    "Configs",
    "Assessment",
    "Reports",
    "Activity",
    "MobileSettings",
    "Rewards",
  ];
  const [openKeys, setOpenKeys] = React.useState(props.openKey);

  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find(
      (key: string) => openKeys.indexOf(key) === -1,
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const location = useLocation();
  const path = location.pathname;

  const menuItems = [
    ...(isClient
      ? [
          {
            key: "/company-dashboard",
            label: t("dashboard"),
            type: "menu",
            icon: <Icon component={DashboardSvg} />,
            perm: PERMS.DASHBOARD,
          },
        ]
      : []),
    {
      key: "Users",
      label: t("users"),
      type: "subMenu",
      icon: <Icon component={UserSvg} />,
      perm: PERMS.GET_ALL_EMPLOYEES,
      children: [
        {
          key: "/employees",
          label: t("employees"),
          icon: <Icon component={EmployeesSvg} />,
          perm: PERMS.GET_ALL_EMPLOYEES,
        },
        {
          key: "/company",
          label: t("company"),
          type: "menu",
          icon: <Icon component={CompanySvg} />,
          perm: PERMS.GET_ALL_CLIENTS,
        },
        {
          key: "/specialist",
          label: t("specialists"),
          icon: <Icon component={ExpertSvg} />,
          perm: PERMS.GET_ALL_SPECIALIST,
        },
        // @TODO: Replace permissions
        {
          key: "/delete-requests",
          label: t("delete-requests"),
          perm: PERMS.GET_DELETION_REQUESTS,
          icon: <Icon component={DeleteRequestsSvg} />,
        },
        {
          key: "/deleted-accounts",
          label: t("deleted-accounts"),
          perm: PERMS.GET_DELETED_USER_LIST,
          icon: <Icon component={DeleteAccountsSvg} />,
        },
        ...(isSuperAdmin || isViwellAdmin
          ? [
              {
                key: "/admins",
                label: t("admins"),
                perm: PERMS.GET_ALL_VIWELL_ADMINS,
                icon: <Icon component={AdminSvg} />,
              },
            ]
          : []),
        ...(isClient
          ? [
              {
                key: "/client-admins",
                label: t("admins"),
                perm: PERMS.GET_ALL_CLIENT_ADMINS,
                icon: <Icon component={AdminSvg} />,
              },
            ]
          : []),
      ],
    },
    {
      key: "Assessment",
      label: t("assessments"),
      type: "subMenu",
      icon: <Icon component={AssessmentSvg} />,
      perm: PERMS.GET_ALL_ASSESSMENT,
      children: [
        {
          key: "/assessment-list",
          label: t("assessment"),
          icon: <Icon component={AssessmentListSvg} />,
          perm: PERMS.GET_ALL_ASSESSMENT,
        },
        {
          key: "/assessment-types",
          label: t("assessment-types"),
          icon: <Icon component={AssessmentTypeSvg} />,
          perm: PERMS.GET_ALL_ASSESSMENT_TYPES,
        },
      ],
    },
    {
      key: "Content",
      label: t("content"),
      type: "subMenu",
      icon: <Icon component={ContentSvg} />,
      perm: PERMS.GET_ALL_CONTENTS,
      children: [
        {
          key: "/configurations",
          label: t("configurations"),
          icon: <Icon component={ConfigSvg} />,
          perm: PERMS.GET_ALL_CONTENTS,
        },
        {
          key: "/content",
          label: t("contents"),
          icon: <Icon component={ContentsSvg} />,
          perm: PERMS.GET_ALL_CONTENTS,
        },
        {
          key: "/programs",
          label: t("programs-topics"),
          icon: <Icon component={ProgramSvg} />,
          perm: PERMS.GET_ALL_COLLECTION,
        },
      ],
    },
    {
      key: "Activity",
      label: t("activities"),
      type: "menu",
      icon: <Icon component={ActivitySvg} />,
      perm: PERMS.GET_ALL_CHALLENGES,
      children: [
        {
          key: "/challenges",
          label: t("challenges"),
          type: "menu",
          icon: <Icon component={ChallengeSvg} />,
          perm: PERMS.GET_ALL_CHALLENGES,
        },
        {
          key: "/challenge-rewards",
          label: t("challenge-rewards"),
          type: "menu",
          icon: <Icon component={ChallengeRewardsSvg} />,
          perm: PERMS.GET_ALL_CONTENTS,
        },
        {
          key: "/badges",
          label: t("badges"),
          type: "menu",
          icon: <Icon component={BadgeSvg} />,
          perm: PERMS.GET_ALL_ACHIEVEMENTS,
        },
      ],
    },
    {
      key: "Configs",
      label: t("configurations"),
      type: "subMenu",
      icon: <Icon component={ConfigSvg} />,
      perm: PERMS.GET_ALL_DEPARTMENTS,
      children: [
        ...(isClient
          ? [
              {
                key: "/departments",
                label: t("departments"),
                icon: <Icon component={DepartmentSvg} />,
                perm: PERMS.GET_ALL_DEPARTMENTS,
              },
              {
                key: "/buildings",
                label: t("buildings"),
                icon: <Icon component={UnitSvg} />,
                perm: PERMS.GET_ALL_DEPARTMENTS,
              },
              {
                key: "/positions",
                label: t("positions"),
                icon: <Icon component={PositionSvg} />,
                perm: PERMS.GET_ALL_POSITIONS,
              },
            ]
          : []),
        {
          key: "/wellbeing",
          label: t("wellbeing.title"),
          type: "menu",
          icon: <Icon component={MenuCalendarSvg} />,
          perm: PERMS.GET_WELLBEING_CALENDER_LIST,
        },
        {
          key: "/roles",
          label: t("roles"),
          type: "menu",
          icon: <Icon component={ClipboardSvg} />,
          perm: PERMS.GET_ALL_ROLES,
        },
        {
          key: "/units",
          label: t("units"),
          icon: <Icon component={UnitSvg} />,
          perm: PERMS.GET_ALL_MEASUREMENTS,
        },
        {
          key: "/eap",
          label: t("employee-assistance-program"),
          icon: <Icon component={AvatarSvg} />,
          perm: PERMS.GET_EAP_LIST,
        },
      ],
    },
    {
      key: "MobileSettings",
      label: t("mobile-settings"),
      type: "menu",
      icon: <Icon component={ReportSvg} />,
      children: [
        {
          key: "/avatars",
          label: t("avatars"),
          icon: <Icon component={AvatarSvg} />,
          perm: PERMS.GET_ALL_AVATARS,
        },
        {
          key: "/documentation",
          label: t("documentation"),
          type: "menu",
          icon: <Icon component={ReportSvg} />,
          perm: PERMS.GET_ALL_ACHIEVEMENTS,
        },
        {
          key: "/onboardings",
          label: t("onboardings"),
          type: "menu",
          icon: <Icon component={OnBoardingSvg} />,
          perm: PERMS.GET_ALL_ACHIEVEMENTS,
        },
        {
          key: "/faqs",
          label: t("faqs"),
          type: "menu",
          icon: <QuestionCircleOutlined />,
          perm: PERMS.GET_ALL_AVATARS,
        },
      ],
    },
    {
      key: "Rewards",
      label: t("rewards"),
      type: "menu",
      icon: <Icon component={RewardSvg} />,
      children: [
        {
          key: "/partners",
          label: t("sidebar.partners"),
          type: "menu",
          icon: <Icon component={PartnerSvg} />,
          perm: PERMS.GET_PARTNER_LIST,
        },
        {
          key: "/tiers",
          label: t("sidebar.tiers"),
          type: "menu",
          icon: <Icon component={TiersIcon} />,
          perm: PERMS.GET_TIER_LIST,
        },
        {
          key: "/reward-categories",
          label: t("sidebar.reward-categories"),
          type: "menu",
          icon: <Icon component={DashboardSvg} />,
          perm: PERMS.GET_REWARD_CATEGORY_LIST,
        },
      ],
    },
  ];

  //this function will filter the menuArr according to the given permissions
  function filteredRolesArr(arr: any[]) {
    const menuArr: any[] = [];
    arr.map((item) => {
      if (item.children) {
        const obj = { ...item, children: filteredRolesArr(item.children) };
        obj.children.length && menuArr.push(obj);
      } else {
        checkIfPermitted(item.perm, permissionsArr, userType) &&
          menuArr.push(item);
      }
    });
    return menuArr;
  }

  return (
    <Sider
      className="viwell-sidebar"
      width="250"
      collapsible
      collapsed={collapsed}
      trigger={null}
    >
      <div className="logo-div">
        <div>
          <img src={collapsed ? viwellMobLogo : viwellLogo} alt="Viwell" />
          <div className="version-num">{packageJson.version}</div>
        </div>
        <div
          className={`menu-toggler  ${
            dir === "rtl" ? "toggler-left" : "toggler-right"
          }`}
          onClick={handleCollapsed}
        >
          <Icon component={collapsed ? ChervanRightSvg : ChervanLeftSvg} />
        </div>
      </div>

      <Menu
        items={filteredRolesArr(menuItems)}
        onClick={(item) => navigate(item.key)}
        className="viwell-menu"
        mode="inline"
        defaultSelectedKeys={[path]}
        defaultOpenKeys={[`${props.openKey}`]}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      />
    </Sider>
  );
};

export default Sidebar;
