import { MoreOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import CustomButton from "components/custom-button/CustomButton";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomMenu from "components/custom-menu/CustomMenu";
import CustomTable from "components/custom-table/CustomTable";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import {
  MeasurementList,
  useGetMeasurementListQuery,
  useLazyGetMeasurementListQuery,
} from "graphql/_generated/graphql";
import moment from "moment";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AddUnit from "./AddUnit";
import { useErrorHandler } from "hooks/useErrorHandler";

interface UnitsListProps {}

const UnitsList: React.FunctionComponent<UnitsListProps> = (props) => {
  //Initializing States
  const { t } = useTranslation();
  const [editId, setEditId] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<MeasurementList>();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  //Hooks
  const { isFetching, refetch } = useGetMeasurementListQuery();
  const [getAllMeasurementsDataFun] = useLazyGetMeasurementListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleError } = useErrorHandler();

  const handleMenu = ({ key, id }: any) => {
    if (key === "edit-unit") {
      setVisible(true);
      setEditId(id);
    }
  };

  useEffect(() => {
    fetchDataFromApi(
      getAllMeasurementsDataFun,
      setColumnsData,
      filtersData,
    ).catch((e) => handleError(e));
  }, [isFetching]);

  const columns: ColumnsType<MeasurementList["data"][0]> = [
    {
      title: t("id"),
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, _record, index) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: t("name"),
      key: "unit",
      dataIndex: "unit",
      sorter: {
        multiple: 1,
      },
    },
    {
      title: t("description"),
      key: "description",
      dataIndex: "description",
      render: (_text, record, index) => (
        <div className="cut-text">{record.description}</div>
      ),
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) => moment(createdAt).format("MMMM Do YYYY"),
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) => moment(updatedAt).format("MMMM Do YYYY"),
    },
    {
      title: "",
      dataIndex: "id",
      key: "action",
      align: "center",
      render: (id: string) => (
        <>
          <CustomDropDown
            overlay={
              <CustomMenu
                items={[{ label: t("edit-unit"), key: "edit-unit" }]}
                onClick={({ domEvent, key, keyPath }) =>
                  handleMenu({ domEvent, key, keyPath, id })
                }
              />
            }
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </CustomDropDown>
        </>
      ),
    },
  ];

  return (
    <>
      <CustomTable
        filters={{
          handleCallback: async (filtersData: any) => {
            await fetchDataFromApi(
              getAllMeasurementsDataFun,
              setColumnsData,
              filtersData,
            );
            setPageSize(filtersData?.pagination?.offset?.pageSize);
            setCurrentPage(filtersData?.pagination?.offset?.page);
            setFiltersData(filtersData);
          },
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],
        }}
        rowKey="id"
        loading={isLoading}
        columns={columns}
        totalItems={columnsData?.metadata.total}
        onClick={() => setVisible(true)}
        headerTitle={t("units.title")}
        btnText={t("units.add-new-unit")}
        dataSource={columnsData?.data}
      />
      <AddUnit
        visible={visible}
        setVisible={setVisible}
        editId={editId}
        setEditId={setEditId}
      />
    </>
  );
};

export default UnitsList;
