import { FunctionComponent, useEffect, useState } from "react";
import { convertTranslationArrToObj as convert } from "helpers";
import { Space } from "antd";
import CustomButton from "components/custom-button/CustomButton";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { Formik, Form } from "formik";
import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";
import ContainerHeading from "features/assessment/questions/ContainerHeading/ContainerHeading";
import DocumentationTranslations from "features/documentation/DocumentationTranslations";
import styles from "features/documentation/css/Documentation.module.scss";
import { useGetSetDocumentation } from "features/documentation/hooks/useGetSetDocumentation";
import { DocumentationCreate } from "graphql/_generated/graphql";
import customNotification from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers";
import { useNavigate } from "react-router-dom";
import { documentationSchema } from "validations/schema/documentationSchema";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import { useErrorHandler } from "hooks/useErrorHandler";

interface DocumentationProps {}
export enum DocumentationSections {
  privacyPolicy = "privacyPolicy",
  termsAndConditions = "termsAndConditions",
  aboutViwell = "aboutViwell",
}

const Documentation: FunctionComponent<DocumentationProps> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const [initialValues, setInitialValues] = useState<DocumentationCreate>({
    translations: {},
    privacyPolicy: "",
    termsAndConditions: "",
    aboutViwell: "",
  });

  const { getDocumentation, handleSetDocumentation, isLoading } =
    useGetSetDocumentation();

  useEffect(() => {
    getDocumentation().then((data) => {
      setInitialValues(data);
    });
  }, []);

  return (
    <DashboardLayout openKey={["MobileSettings"]}>
      <Breadcrumbs
        data={[
          {
            label: "Mobile Settings",
          },
          {
            label: "Documentation",
          },
        ]}
      />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={documentationSchema}
        onSubmit={async (values) => {
          try {
            await handleSetDocumentation({
              create: { ...values },
            });
            customNotification("success", t("success-update"));
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <div className={styles["doc-header"]}>
              <div className={styles["header-title"]}>{t("documentation")}</div>
              <Space>
                <CustomButton
                  className="light-btn"
                  htmlType="reset"
                  onClick={() => navigate(-1)}
                >
                  {t("cancel")}
                </CustomButton>
                <CustomButton
                  loading={isLoading}
                  htmlType="submit"
                  className="primary-btn"
                >
                  {t("save-publish")}
                </CustomButton>
              </Space>
            </div>
            <div className={styles["divider"]}>
              <CollapseAble
                defaultActiveKey={["privacy"]}
                headerKey="privacy"
                hideExtra
                expandIconPosition="end"
                header={<ContainerHeading text={t("privacy-policy")} />}
              >
                <div key={"privacy"}>
                  <CustomTextEditor
                    label={t("content-description")}
                    name="privacyPolicy"
                  />
                  <DocumentationTranslations
                    sectionName={DocumentationSections.privacyPolicy}
                    values={values}
                  />
                </div>
              </CollapseAble>
              <CollapseAble
                defaultActiveKey={[""]}
                headerKey="terms"
                hideExtra
                expandIconPosition="end"
                header={<ContainerHeading text={t("terms-conditions")} />}
              >
                <div key={"terms"}>
                  <CustomTextEditor
                    label={t("content-description")}
                    name="termsAndConditions"
                  />
                  <DocumentationTranslations
                    sectionName={DocumentationSections.termsAndConditions}
                    values={values}
                  />
                </div>
              </CollapseAble>
              <CollapseAble
                defaultActiveKey={[""]}
                headerKey="aboutViwell"
                hideExtra
                expandIconPosition="end"
                header={<ContainerHeading text={t("about-viwell")} />}
              >
                <div key={"aboutViwell"}>
                  <CustomTextEditor
                    label={t("content-description")}
                    name="aboutViwell"
                  />
                  <DocumentationTranslations
                    sectionName={DocumentationSections.aboutViwell}
                    values={values}
                  />
                </div>
              </CollapseAble>
            </div>
          </Form>
        )}
      </Formik>
    </DashboardLayout>
  );
};

export default Documentation;
