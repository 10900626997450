export const PERMS = {
  TOGGLE_AVATAR_ACTIVATION: "toggleAvatarActivation",
  ADD_NEW_AVATAR: "addNewAvatar",
  ADD_NEW_CHALLENGE: "addNewChallenge",
  ADD_NEW_ACHIEVEMENT: "addNewAchievement",
  TOGGLE_CATEGORY_ACTIVATION: "toggleCategoryActivation",
  TOGGLE_ACTIVITY_ACTIVATION: "toggleActivityActivation",
  TOGGLE_ACHIEVEMENT_ACTIVATION: "toggleAchievementActivation",
  UPDATE_CATEGORY: "updateCategory",
  ADD_NEW_CATEGORY: "addNewCategory",
  INVITE_CLIENT: "inviteClient",
  TOGGLE_USER_ACTIVATION: "toggleUserActivation",
  TOGGLE_CLIENT_ACTIVATION: "toggleClientActivation",
  ADD_NEW_VIDEO_CONTENT: "addNewVideoContent",
  ADD_NEW_AUDIO_CONTENT: "addNewAudioContent",
  ADD_NEW_TEXT_CONTENT: "addNewTextContent",
  ADD_NEW_RECIPE_CONTENT: "addNewRecipeContent",
  ADD_NEW_FILE_CONTENT: "addNewFileContent",
  ADD_NEW_DEPARTMENT: "addNewDepartment",
  INVITE_EXPERT: "InviteExpert",
  COMPLETE_EXPERT_PROFILE: "completeExpertProfile",
  UPDATE_PILLAR: "updatePillar",
  UPDATE_ACHIEVEMENT: "updateAchievement",
  UPDATE_CHALLENGE: "updateChallenge",
  ADD_NEW_PILLAR: "addNewPillar",
  ADD_NEW_POSITION: "addNewPosition",
  ADD_NEW_ROLE: "addNewRole",
  TOGGLE_TAG_ACTIVATION: "toggleTagActivation",
  UPDATE_TAG: "updateTag",
  ADD_NEW_TAG: "addNewTag",
  INVITE_EMPLOYEE: "inviteEmployee",
  ADD_NEW_SPECIALIST: "addNewSpecialist",
  GET_ACHIEVEMENT_LIST: "getAchievementList",
  GET_ALL_CLIENTS: "getClientList",
  GET_ALL_EMPLOYEES: "getEmployeeList",
  GET_ALL_EXPERTS: "getAllExperts",
  GET_ALL_CONTENTS: "getContentList",
  GET_ALL_ROLES: "getRoleList",
  GET_ALL_DEPARTMENTS: "getDepartmentList",
  GET_ALL_POSITIONS: "getPositionList",
  GET_ALL_CATEGORIES: "getCategoryList",
  GET_ALL_TAGS: "getTagList",
  GET_ALL_PERMISSIONS: "getAllPermissions",
  TOGGLE_DEPARTMENT_ACTIVATION: "toggleDepartmentActivation",
  UPDATE_DEPARTMENT: "updateDepartment",
  UPDATE_POSITION: "updatePosition",
  TOGGLE_POSITION_ACTIVATION: "togglePositionActivation",
  TOGGLE_ROLE_ACTIVATION: "toggleRoleActivation",
  UPDATE_ROLE: "updateRole",
  GET_ALL_COLLECTION: "getCollectionList",
  GET_ALL_SPECIALIST: "getSpecialistList",
  GET_ALL_ASSESSMENT: "getAssessments",
  GET_ALL_ASSESSMENT_TYPES: "getAssessmentTypes",
  GET_ALL_ACTIVITIES: "getActivityList",
  GET_ALL_ACHIEVEMENTS: "getAchievementList",
  GET_ALL_AVATARS: "getAvatarList",
  GET_ALL_MEASUREMENTS: "getMeasurementList",
  GET_ALL_RANGES: "getRangeList",
  GET_ALL_METRICS: "getMetricList",
  GET_ALL_REWARDS: "getPartnerRewardList",
  GET_ALL_VIWELL_ADMINS: "getViwellAdminList",
  GET_DELETED_USER_LIST: "getDeletedUserList",
  GET_DELETION_REQUESTS: "getDeletionRequests",
  GET_ALL_CLIENT_ADMINS: "getClientAdminList",
  GET_CONTENT: "getContent",
  GET_COLLECTION: "getCollection",
  GET_ACTIVITY: "getActivity",
  GET_ASSESSMENT: "getAssessment",
  CREATE_WELLBEING_CALENDER: "createWellBeingCalender",
  UPDATE_WELLBEING_CALENDER: "updateWellBeingCalender",
  DELETE_WELLBEING_CALENDER: "deleteWellBeingCalender",
  GET_WELLBEING_CALENDER_LIST: "getWellBeingCalenderList",
  DASHBOARD: "Dashboard Permissions",
  GET_EAP_LIST: "getEAPList",
  GET_SINGLE_CHALLENGE: "getChallenge",
  TOGGLE_CHALLENGE_ACTIVATION: "toggleChallengeActivation",
  GET_ALL_CHALLENGES: "getChallengeList",
  GET_PARTNER_LIST: "getPartnerList",
  GET_TIER_LIST: "getTierList",
  GET_REWARD_CATEGORY_LIST: "getRewardCategoryList",
  ADD_NEW_PARTNER: "addNewPartner",
  PARTNER_ACTIVATION: "togglePartnerActivation",
  ADD_NEW_TIER: "addNewTier",
  TOGGLE_TIER_ACTIVATION: "toggleTierActivation",
  ADD_REWARD_CATEGORY: "addNewRewardCategory",
  TOGGLE_REWARD_CATEGORY_ACTIVATION: "toggleRewardCategoryActivation",
  UPDATE_PARTNER: "updatePartner",
  ADD_PARTNER_REWARD: "addNewPartnerReward",
  TOGGLE_PARTNER_REWARD_ACTIVATION: "togglePartnerRewardActivation",
  BULK_IMPORT_REWARDS: "bulkImportPartnerRewards",
  UPDATE_TIER: "updateTier",
  GET_PARTNER_DETAILS: "getPartnerDetails",
  GET_TIER_DETAILS: "getTierDetails",
  TOGGLE_ADMIN_ACTIVATION: "toggleViwellAdminActivation",
  RESEND_INVITE: "reSendInvite",
  UPDATE_VIWELL_ADMIN_PROFILE: "updateViwellAdminProfile",
};

export const CONFIRM_MODAL_CONFIG = {
  className: "custom-modal",
  okButtonProps: {
    className: "primary-btn",
  },
  cancelButtonProps: {
    className: "btn-light",
  },
};

export const CONFIG = {
  ENV: "dev",
  TINY_MCE_API_KEY: "ygl5gw339tjjdzh8ugtdf53dioy0r3o6ycg5y2h4jmtik9zk",
  REGIONAL_KEY_HEADER: "x-regional-key",
  REGIONAL_KEY: "GLOBAL",
  BASE_URL: "https://api-dev.viwell.app/",
  ZOHO_SALES_IQ_WIDGET_CODE:
    "4a93a564e14225b40bb0f9517e5f1e5909cd91508b0f1d3496258aafcd52b126",
  ZOHO_SALES_IQ_WIDGET_URL: "https://salesiq.zoho.com/widget",
  BASE_IMAGE_URL: "https://media-dev.viwell.app/",
  KSA_BASE_IMAGE_URL: "https://media-dev-ksa.viwell.app/",
  DOWNLOAD_URL:
    "https://viwell.com/services/digital-wellbeing-platform/mobile-app-download-page/",
  PAGINATION_MAX_LIMIT: 1000,
};
