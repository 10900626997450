import { DatePicker, Select, Space, Switch, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import CustomTable from "components/custom-table/CustomTable";
import {
  useGetTierListQuery,
  useLazyGetTierListQuery,
  TierList,
} from "graphql/_generated/graphql";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MoreOutlined } from "@ant-design/icons";
import moment from "moment";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomButton from "components/custom-button/CustomButton";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import { PERMS } from "helpers/constants";
import customNotification from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers/errorFormat";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import { useTier } from "../hooks/useTier";
import { getLanguage } from "helpers";
import CustomLanguageSelector from "components/form/custom-language-selector/CustomLanguageSelector";
import AddTier from "./AddTier";
import CustomConfirmModal from "components/custom-confirm-modal/CustomConfirmModal";
import { useLanguages } from "hooks";
import { useNavigate } from "react-router-dom";
import { getLangDir } from "helpers/getLangDir";
import { useErrorHandler } from "hooks/useErrorHandler";

interface TiersListProps {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const TiersList: React.FunctionComponent<TiersListProps> = (props) => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const [langVisibility, setLangVisibility] = useState(false);
  const [langCode, setLangCode] = useState<string>();
  const [isAddLanguage, setIsAddLanguage] = useState(false);
  const [isAddTier, setIsAddTier] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [editId, setEditId] = useState<string | null>(null);
  const [tierData, setTiersData] = useState<MappedTierList>();
  const [isActivated, setIsActivated] = useState(false);

  const { isFetching } = useGetTierListQuery();
  const [columnsData, setColumnsData] = useState<TierList>();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [getAllTagsDataFun] = useLazyGetTierListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleError } = useErrorHandler();
  const { languages } = useLanguages();

  const {
    handleTierActivation,
    handleDeleteTier,
    isLoading: isLoadingTier,
  } = useTier();

  useEffect(() => {
    fetchDataFromApi(getAllTagsDataFun, setColumnsData, filtersData).catch(
      (e) => handleError(e),
    );
  }, [isFetching]);

  const mappedColumnsData = columnsData?.data.map((item) => {
    return (
      {
        ...item,
        hasChildren: true,
        children:
          item?.translations && Object.keys?.(item?.translations)
            ? Object.keys?.(item?.translations)?.map((record) => {
                return {
                  ...item,
                  name: item?.translations?.[record]?.name,
                  language: record,
                };
              })
            : null,
      } ?? []
    );
  });

  type MappedTierList = NonNullable<typeof mappedColumnsData>[0];

  const handleMenu = ({ key, id, record }: any) => {
    if (key === "tier-details") {
      navigation(`/tier-details/${id}/${record?.language}`);
    }
    if (key === "add-new-language") {
      setTiersData(record);
      setLangVisibility(true);
      setEditId(id);
      setIsAddLanguage(true);
    }
    if (key === "edit-tier") {
      setIsAddLanguage(false);
      setEditId(id);
      setIsAddTier(true);
      setLangCode(undefined);
    }
    if (key === "delete-tier") {
      setIsDelete(true);
      setEditId(id);
      () => submitDelete();
    }
    if (key === "edit-language") {
      setLangCode(record.language);
      setIsAddLanguage(true);
      setEditId(id);
      setIsAddTier(true);
    }
  };

  const handleSubmit = (values: any, actions: any) => {
    setLangCode(values?.language);
    setLangVisibility(false);
    setIsAddTier(true);
    actions.resetForm();
  };

  const submitDelete = async () => {
    try {
      await handleDeleteTier(editId ?? "");
      customNotification("success", t("deleted-tier-successfully"));
      setIsDelete(false);
      setEditId(null);
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const handleToggleTier = (record: MappedTierList) => {
    setIsActivated(true);
    setTiersData(record);
  };

  const handleToggleTierActivation = async () => {
    try {
      const isDepartmentActive = await handleTierActivation(tierData?.id ?? "");
      customNotification(
        "success",
        isDepartmentActive
          ? t("tier-activation-successfully")
          : t("tier-deactivate-successfully"),
      );
      setIsActivated(false);
      setTiersData(undefined);
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const columns: ColumnsType<MappedTierList> = [
    {
      title: `${t("id")}`,
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },

      render: (_text, record, index) =>
        record.hasChildren ? (currentPage - 1) * pageSize + index + 1 : null,
    },
    {
      title: `${t("title")}`,
      key: "name",
      dataIndex: "name",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => {
        return (
          <div className={record?.hasChildren ? "parent-row" : "child-row"}>
            <Space>
              <CustomAvatar shape="square" imgSrc={record?.tierImage ?? ""} />
              <span className={`cut-text-${getLangDir(record.name)}`}>
                {record.name}
              </span>
            </Space>
          </div>
        );
      },
    },
    {
      title: `${t("language")}`,
      key: "language",
      dataIndex: "language",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => getLanguage(record.language),
    },
    {
      title: `${t("required-points")}`,
      key: "points",
      dataIndex: "points",
      align: "center",
      sorter: {
        multiple: 1,
      },
    },
    {
      title: `${t("creation-date")}`,
      key: "createdAt",
      dataIndex: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) => moment(createdAt).format("MMMM Do YYYY"),
    },
    {
      title: `${t("updated-at")}`,
      key: "updatedAt",
      dataIndex: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) => moment(updatedAt).format("MMMM Do YYYY"),
    },
    {
      title: t("status-label"),
      dataIndex: "isActive",
      key: "isActive",
      align: "center" as "center",

      render: (isActive: boolean) =>
        isActive ? (
          <Tag className="active-tag"> &bull; {`${t("active")}`}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      align: "center" as "center",
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      render: (_text, record, _index) => (
        <>
          <CheckPermissions permission={PERMS.TOGGLE_TIER_ACTIVATION}>
            <Switch
              className="custom-switch"
              defaultChecked
              checked={record.isActive}
              onClick={() => handleToggleTier(record)}
            />
          </CheckPermissions>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "Actions",
      align: "center",
      render: (id: string, record) => {
        const mainActions = [
          {
            key: "tier-details",
            label: t("tier-details"),
          },
          {
            key: "edit-tier",
            label: t("edit-tier"),
          },
          {
            key: "add-new-language",
            label: t("add-new-language"),
          },
          {
            key: "delete-tier",
            label: t("delete-tier"),
          },
        ];
        const langActions = [{ key: "edit-language", label: "Edit Language" }];
        return (
          <CustomDropDown
            menu={{
              items: record?.hasChildren ? mainActions : langActions,
              onClick: ({ domEvent, key, keyPath }) =>
                handleMenu({ domEvent, key, keyPath, id, record }),
            }}
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </CustomDropDown>
        );
      },
    },
  ];

  return (
    <>
      <CustomTable
        expandIconColumnIndex={1}
        filters={{
          handleCallback: async (filtersData: any) => {
            await fetchDataFromApi(
              getAllTagsDataFun,
              setColumnsData,
              filtersData,
            );
            setPageSize(filtersData?.pagination?.offset?.pageSize);
            setCurrentPage(filtersData?.pagination?.offset?.page);
            setFiltersData(filtersData);
          },
          search: true,
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
              placeholder: t("creation"),
            },
            {
              applyAs: "and",
              key: "updatedAt",
              comparison: "Range",
              placeholder: t("modified"),
            },
          ],
          manualFilters: [
            {
              key: "language",
              value: "language",
              comparison: "Equal",
              applyAs: "and",
              render: (onChangeCallback) => (
                <Select
                  options={languages}
                  fieldNames={{ label: "label", value: "value" }}
                  className="filter-select"
                  onChange={onChangeCallback}
                  allowClear
                  placeholder={t("language-filter")}
                />
              ),
            },
          ],
        }}
        permission={PERMS.ADD_NEW_TIER}
        onClick={() => props?.setVisible(true)}
        headerTitle={t("tiers")}
        btnText={t("add-new-tier")}
        dataSource={mappedColumnsData ?? []}
        columns={columns}
        rowKey={(record) =>
          record.hasChildren ? record.id : record.id + record.name
        }
        totalItems={columnsData?.metadata?.total}
        loading={isLoading}
        className="custom-table"
      />
      {langVisibility && (
        <CustomLanguageSelector
          visible={langVisibility}
          setVisible={setLangVisibility}
          onSubmit={handleSubmit}
          data={tierData}
          label={t("tier")}
        />
      )}
      {isAddTier && (
        <AddTier
          visible={isAddTier}
          setVisible={setIsAddTier}
          editId={editId}
          setEditId={setEditId}
          langCode={langCode}
          isAddLanguage={isAddLanguage}
        />
      )}
      {isDelete && (
        <CustomConfirmModal
          visible={isDelete}
          setVisible={setIsDelete}
          desc={t("sure-to-delete-tier")}
          onSubmit={submitDelete}
          loading={isLoadingTier}
        />
      )}
      {isActivated && (
        <CustomConfirmModal
          visible={isActivated}
          setVisible={setIsActivated}
          desc={
            tierData?.isActive
              ? t("confirm-deactivate-tier")
              : t("confirm-activate-tier")
          }
          loading={isLoadingTier}
          onSubmit={handleToggleTierActivation}
        />
      )}
    </>
  );
};

export default TiersList;
