import { useAddMeasurement } from "./useAddMeasurement";
import { useUpdateMeasurement } from "./useUpdateMeasurement";

export const useMeasurement = () => {
  const { handleAddNewMeasurement, isLoading } = useAddMeasurement();

  const {
    handleUpdateMeasurement,
    handleFetchMeasurementData,
    isLoading: isLoadingUpdateMeasurement,
  } = useUpdateMeasurement();

  return {
    isLoading: isLoading || isLoadingUpdateMeasurement,
    handleAddNewMeasurement,
    handleUpdateMeasurement,
    handleFetchMeasurementData,
  };
};
