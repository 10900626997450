import Icon, { DownOutlined, UnlockOutlined } from "@ant-design/icons";
import { Badge, Dropdown, Layout, Select, Space } from "antd";
import CustomButton from "components/custom-button/CustomButton";
import CustomMenu from "components/custom-menu/CustomMenu";
import { getLanguages, parseJwt } from "helpers";
import { BellSvg } from "assets/icons/BellSvg";
import { LogoutSvg } from "assets/icons/LogoutSvg";
import { MailSvg } from "assets/icons/MailSvg";
import { ProfileSvg } from "assets/icons/ProfileSvg";
import {
  api,
  useGetProfileQuery,
  useGetRegionListQuery,
  useLazyGetProfileQuery,
} from "graphql/_generated/graphql";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectTheme } from "services/theme/theme.service";
import { removePermissions } from "store/permissionsSlice";
import { RootState, store } from "store/store";
import { removeToken, setRegion, logout } from "../../auth/authSlice";
import { direction, theme } from "../dashboardSlice";
import ChangePassword from "./ChangePassword";
import styles from "./Header.module.scss";
import UpdateProfile from "./UpdateProfile";
import { useLogout } from "./useLogout";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import { DownArrowSvg } from "assets/icons/DownArrowSvg";
import { getRegionIcon } from "helpers/getRegionIcons";

type Props = {};
export default function Header({}: Props) {
  const { Option } = Select;
  const [visible, setVisible] = useState<boolean>(false);
  const [password, setPassword] = useState(false);
  const { companyId, isClient, isSuperAdmin, isViwellAdmin } =
    useLoggedInUser();
  const dispatch = useDispatch();
  const [lang, setLang] = useState([]);
  const { handleLogout: logoutFun } = useLogout();
  const { data: profile } = useGetProfileQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const { data: regionList } = useGetRegionListQuery();
  const [getProfileFun] = useLazyGetProfileQuery();
  async function fetchLanguages() {
    let data = await getLanguages();
    setLang(data);
  }

  useEffect(() => {
    fetchLanguages();
  }, []);

  const handleRegionChange = (value: string) => {
    dispatch(setRegion(value));
  };

  const navigate = useNavigate();
  const refreshToken = useSelector((state: any) => state.auth.refreshToken);
  const currActiveTheme = useSelector((state: any) => state.dashboard.theme);
  const defaultRegion = useSelector((state: RootState) => state.auth.region);
  const token = useSelector((state: RootState) => state.auth.token);
  const { regionalServiceKey } = parseJwt(token);

  useEffect(() => {
    if (regionalServiceKey && isClient) {
      dispatch(setRegion(regionalServiceKey));
    }
  }, []);

  const handleLogout = async () => {
    try {
      await logoutFun({});
      dispatch(removeToken());
      dispatch(removePermissions());
      dispatch(logout());
      navigate("/");
      store.dispatch(api.util.resetApiState());
    } catch (error) {}
  };

  const handleLangMenu = ({ domEvent, key, keyPath }: any) => {
    const item: any = lang[key];
    changeLanguage(item.value);
  };

  const languageMenu = (
    <CustomMenu
      items={lang.map((item: any, index: number) => {
        dispatch(direction(item.direction));
        return { label: item.label, key: index };
      })}
      onClick={({ domEvent, key, keyPath }) =>
        handleLangMenu({ domEvent, key, keyPath })
      }
    />
  );

  const { t, i18n } = useTranslation();
  const changeLanguage = (lng: string | undefined) => {
    i18n.changeLanguage(lng);
  };
  const { Header } = Layout;

  const handleMenu = ({ key, id }: any) => {
    if (key === "logout") {
      handleLogout();
    } else if (key === "Edit") {
      setVisible(true);
    } else if (key === "changePassword") {
      setPassword(true);
    } else if (key === "profile") {
      navigate("/edit-company", {
        state: companyId,
      });
    }
  };

  const menu = (
    <CustomMenu
      className="profile-menu"
      items={[
        ...(isClient
          ? [
              {
                label: t("edit-company-profile"),
                key: "profile",
                icon: <Icon component={ProfileSvg} />,
              },
            ]
          : []),
        ...[
          {
            label: t("edit-profile"),
            key: "Edit",
            icon: <Icon component={ProfileSvg} />,
          },
          {
            label: t("change-password"),
            key: "changePassword",
            icon: <UnlockOutlined />,
          },
          {
            label: t("logout"),
            key: "logout",
            icon: <Icon component={LogoutSvg} />,
          },
        ],
      ]}
      onClick={({ domEvent, key, keyPath }) =>
        handleMenu({ domEvent, key, keyPath })
      }
    />
  );

  useEffect(() => {
    if (currActiveTheme === "dark") {
      selectTheme("dark");
    } else {
      selectTheme("light");
    }
  }, [currActiveTheme]);

  const themeChange = (checked: boolean) => {
    if (checked) {
      dispatch(theme("dark"));
    } else {
      dispatch(theme("light"));
      selectTheme("light");
    }
  };

  return (
    <>
      <Header className="viwell-header">
        <div className={styles["admin-header"]}>
          <Space size={30}>
            <CustomButton type="link" className="link-btn">
              <Badge dot color="black">
                <Icon component={BellSvg} />
              </Badge>
            </CustomButton>
            <CustomButton type="link" className="link-btn">
              <Icon component={MailSvg} />
            </CustomButton>
            {(isSuperAdmin || isViwellAdmin) && (
              <Select
                style={{ width: "160px" }}
                onChange={handleRegionChange}
                defaultValue={defaultRegion}
                bordered={false}
                suffixIcon={<Icon component={DownArrowSvg} />}
              >
                {regionList?.getRegionList &&
                  regionList?.getRegionList?.map((item) => (
                    <Option
                      value={item.regionalServiceKey}
                      key={item.id}
                      label={item.country}
                    >
                      <Space size={6}>
                        {item.regionalServiceKey ? (
                          <Icon
                            component={getRegionIcon(item.regionalServiceKey)}
                          />
                        ) : null}
                        <span>{item.country}</span>
                      </Space>
                    </Option>
                  ))}
              </Select>
            )}

            <Dropdown overlay={menu} placement="bottomRight">
              <CustomButton type="link" className="link-btn profile-menu">
                <Space>
                  <CustomAvatar
                    shape="square"
                    size="large"
                    imgSrc={profile?.getProfile.image ?? ""}
                  />
                  <Space>
                    {profile?.getProfile.firstName}
                    {profile?.getProfile.lastName}
                  </Space>
                  <DownOutlined />
                </Space>
              </CustomButton>
            </Dropdown>

            {/* <Switch
            className="custom-switch"
            defaultChecked
            checked={currActiveTheme == "dark" ? true : false}
            onChange={themeChange}
          /> */}
          </Space>
        </div>
      </Header>
      <UpdateProfile visible={visible} setVisible={setVisible} />
      {password && (
        <ChangePassword visible={password} setVisible={setPassword} />
      )}
    </>
  );
}
