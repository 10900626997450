import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, FormikHelpers } from "formik";
import {
  useGetPositionListQuery,
  useGetDepartmentsConfigQuery,
  PositionCreate,
} from "graphql/_generated/graphql";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import TranslationFieldArr from "components/translation-field-array/TranslationFieldArr";
import { positionSchema } from "validations/schema";
import { usePosition } from "../usePosition";
import { useErrorHandler } from "hooks/useErrorHandler";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
};

const defaultValues = {
  name: "",
  description: "",
  departmentId: "",
  translations: [],
};

const AddPosition = ({ setVisible, setEditId, visible, editId }: Props) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { refetch } = useGetPositionListQuery();
  const { data: departmentData } = useGetDepartmentsConfigQuery();

  const [initialValues, setInitialValues] =
    useState<PositionCreate>(defaultValues);

  const {
    isLoading,
    handleAddPosition,
    handleFetchEditPositionData,
    handleUpdatePosition,
  } = usePosition();

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  useEffect(() => {
    if (editId) {
      handleFetchEditPositionData(editId).then((data) => {
        setInitialValues({
          name: data.name ?? "",
          description: data.description ?? "",
          translations: data.translations,
          departmentId: data.department?.isActive ? data.department.id : "",
        });
      });
    } else {
      setInitialValues(defaultValues);
    }
  }, [editId]);

  const handleSubmit = async (
    values: PositionCreate,
    { resetForm }: FormikHelpers<PositionCreate>,
  ) => {
    try {
      if (!editId) {
        await handleAddPosition({ create: values });
        customNotification("success", t("position-added-successfully"));
      } else {
        await handleUpdatePosition({ id: editId, updateObj: values });
        customNotification("success", t("position-updated-successfully"));
        setEditId(null);
      }
      resetForm();
      refetch();
      setVisible(false);
    } catch (error) {
      handleError(error as string | object);
    }
  };

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title={t("position.add-position")}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={positionSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <CustomTextField
              label={t("position-name")}
              name="name"
              placeholder={t("position.position-name")}
            />
            <CustomTextEditor
              name="description"
              label={t("description")}
              placeholder={t("description")}
            />
            <CustomSelectField
              label={t("select-department")}
              name="departmentId"
              className="form-select"
              placeholder={t("select-department")}
              options={departmentData?.getDepartmentsConfig.options}
              fieldNames={{ label: "label", value: "id" }}
            />
            <TranslationFieldArr values={values} attribute="name" />
            <ModalFooter
              loading={isLoading}
              saveText={t("save-position")}
              onCancel={handleCancel}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddPosition;
