import { MoreOutlined } from "@ant-design/icons";
import moment from "moment";
import { Dropdown, Modal, Space, Switch, Tag } from "antd";
import CustomButton from "components/custom-button/CustomButton";
import {
  PositionList,
  useGetPositionListQuery,
  useLazyGetPositionListQuery,
} from "graphql/_generated/graphql";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import AddPosition from "../add-position/AddPosition";
import { usePosition } from "../usePosition";
import { errorFormat, getTranslatedValue, PERMS } from "helpers";
import { customNotification } from "components/custom-notification/CustomNotification";
import CustomTable from "components/custom-table/CustomTable";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import CustomMenu from "components/custom-menu/CustomMenu";
import { ColumnsType } from "antd/lib/table";
import { languages } from "i18n/languages";
import { CONFIRM_MODAL_CONFIG } from "helpers/constants";
import { useErrorHandler } from "hooks/useErrorHandler";

const PositionsList = () => {
  //Initializing states
  const { t } = useTranslation();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<PositionList>();
  const [editId, setEditId] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  //hooks
  const { isFetching, refetch } = useGetPositionListQuery();
  const [getAllPositionsDataFun] = useLazyGetPositionListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleActivationPosition, handleRemovePosition } = usePosition();
  const { handleError } = useErrorHandler();

  const handleMenu = ({ key, id, record }: any) => {
    if (key === "edit-position") {
      handleEdit(id);
    } else if (key === "delete-position") {
      Modal.confirm({
        ...CONFIRM_MODAL_CONFIG,
        title: t("confirm-delete"),
        content: (
          <>
            <div>{record?.name}</div>
          </>
        ),
        onOk: async () => {
          try {
            await handleRemovePosition(id);
            customNotification("success", t("position-removed-successfully"));
            refetch();
          } catch (error) {
            handleError(error as string | object);
          }
        },
      });
    }
  };

  const handleEdit = (id: string) => {
    setEditId(id);
    setVisible(true);
  };

  useEffect(() => {
    fetchDataFromApi(getAllPositionsDataFun, setColumnsData, filtersData).catch(
      (e) => handleError(e),
    );
  }, [isFetching]);

  const columns: ColumnsType<PositionList["data"][0]> = [
    {
      title: t("id"),
      key: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, index) => (
        <Space>
          <div className="cut-text">{getTranslatedValue(record, "name")}</div>
        </Space>
      ),
    },
    {
      title: t("department"),
      key: "department",
      render: (_text, record, index) => record.department.name,
    },
    {
      title: t("languages"),
      dataIndex: "translations",
      key: "translations",
      render: (_text, record, index) => (
        <Space>
          English
          {record.translations &&
            Object.keys(record.translations).map((key, index) => {
              return languages.filter((item) => item.value === key)[0].label;
            })}
        </Space>
      ),
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) =>
        moment(createdAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) =>
        moment(updatedAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      title: t("status"),
      dataIndex: "isActive",
      key: "isActive",
      align: "center" as "center",
      render: (isActive: boolean) =>
        isActive ? (
          <Tag className="active-tag">{t("active")}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      align: "center" as "center",
      render: (id: string, { isActive }) => (
        <CheckPermissions permission={PERMS.TOGGLE_POSITION_ACTIVATION}>
          <CustomPopconfirm
            onConfirm={async () => {
              try {
                const isPositionActive = await handleActivationPosition(id);
                refetch();
                customNotification(
                  "success",
                  isPositionActive
                    ? t("position-activated-successfully")
                    : t("position-deactivated-successfully"),
                );
                refetch();
              } catch (error) {
                handleError(error as string | object);
              }
            }}
            title={
              isActive
                ? t("confirm-deactivate-position")
                : t("confirm-activate-position")
            }
          >
            <Switch
              className="custom-switch"
              defaultChecked
              checked={isActive}
            />
          </CustomPopconfirm>
        </CheckPermissions>
      ),
    },
    {
      align: "center" as "center",
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id: string, record) => (
        <Dropdown
          overlay={
            <CheckPermissions permission={PERMS.UPDATE_POSITION}>
              <CustomMenu
                items={[
                  { label: t("edit-position"), key: "edit-position" },
                  { label: t("delete-position"), key: "delete-position" },
                ]}
                onClick={({ domEvent, key, keyPath }) =>
                  handleMenu({ domEvent, key, keyPath, id, record })
                }
              />
            </CheckPermissions>
          }
        >
          <CustomButton type="link" className="link-btn">
            <MoreOutlined />
          </CustomButton>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <CustomTable
        filters={{
          handleCallback: async (filtersData: any) => {
            await fetchDataFromApi(
              getAllPositionsDataFun,
              setColumnsData,
              filtersData,
            );
            setPageSize(filtersData?.pagination?.offset?.pageSize);
            setCurrentPage(filtersData?.pagination?.offset?.page);
            setFiltersData(filtersData);
          },
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
        }}
        rowKey="id"
        loading={isLoading}
        columns={columns}
        totalItems={columnsData?.metadata?.total}
        permission={PERMS.ADD_NEW_POSITION}
        onClick={() => setVisible(true)}
        headerTitle={t("positions")}
        btnText={t("position.add-position")}
        dataSource={columnsData?.data}
      />
      <AddPosition
        visible={visible}
        setVisible={setVisible}
        editId={editId}
        setEditId={setEditId}
      />
    </>
  );
};

export default PositionsList;
