import CustomModal from "components/custom-modal/CustomModal";
import { PERMS } from "helpers";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../CompanyDashboard.module.scss";
import CustomButton from "components/custom-button/CustomButton";
import { MoreOutlined, PlusCircleFilled } from "@ant-design/icons";
import CustomTable from "components/custom-table/CustomTable";
import { Dropdown, Pagination } from "antd";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomMenu from "components/custom-menu/CustomMenu";
import { useGetOrgMetric } from "../hooks/useGetOrgMetric";
import moment from "moment";
import { getValueUnit } from "../utils";
import {
  MetricSortOrder,
  OrgMetricDetails,
  OrgMetricDetailsList,
  PaginationInput,
} from "graphql/_generated/graphql";
import { ColumnsType } from "antd/lib/table";
import { DefaultRecordType } from "rc-table/lib/interface";
import {
  IMetricValuesProps,
  SelectedMetricProps,
} from "../components/organization-metrices/types";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleEditCurrentValue: (value: undefined | OrgMetricDetails) => void;
  orgMetric: SelectedMetricProps;
  metricData: Array<IMetricValuesProps> | [];
};

interface ValuesProps extends OrgMetricDetails {
  year: string;
  month: number;
}

const ShowOrgMetricValues: React.FC<Props> = ({
  visible,
  setVisible,
  orgMetric,
  handleEditCurrentValue,
  metricData,
}) => {
  const { t } = useTranslation();
  const [metricValues, setMetricValues] = useState<OrgMetricDetailsList>({
    data: [],
    metadata: {
      total: 0,
    },
  });

  const [orgValuesSorting, setOrgValuesSorting] = useState<MetricSortOrder>({
    month: "",
    value: "",
  });

  const { handleGetOrgMetricValues, isLoading } = useGetOrgMetric();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagination, setPagination] = useState<PaginationInput>({
    limit: 6,
    offset: 0,
  });

  useEffect(() => {
    handleGetOrgMetricValues({
      year: orgMetric.selectedYear,
      metricType: orgMetric.type,
      pagination,
      sort: orgValuesSorting,
    }).then((data) => {
      setMetricValues(data);
    });
  }, [metricData, orgValuesSorting, pagination]);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleMenu = ({
    key,
    record,
  }: {
    key: string;
    record: OrgMetricDetails;
  }) => {
    if (key === "edit-current-value") {
      const data = record.key.split("-");
      const valuesData: ValuesProps = {
        ...record,
        year: data[0],
        month: parseInt(data[1]),
      };
      handleEditCurrentValue(valuesData);
    }
  };

  const columns: ColumnsType<OrgMetricDetails> = [
    {
      title: t("month"),
      dataIndex: "key",
      key: "key",
      sorter: {
        multiple: 1,
      },
      render: (_text: string, _record: DefaultRecordType) => {
        const data = _record.key.split("-");
        return (
          <div>{`${moment(data[1], "MM").format("MMM")} .${data[0]}`}</div>
        );
      },
    },
    {
      title: `${t("value")} (${getValueUnit(orgMetric.unit)})`,
      dataIndex: "value",
      key: "value",
      sorter: {
        multiple: 1,
      },
    },
    {
      align: "center" as "center",
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id: string, record: OrgMetricDetails) => (
        <>
          <Dropdown
            overlay={
              <CheckPermissions permission={PERMS.UPDATE_ROLE}>
                <CustomMenu
                  items={[
                    {
                      label: t("edit-current-value"),
                      key: "edit-current-value",
                    },
                  ]}
                  onClick={({ domEvent, key, keyPath }) =>
                    handleMenu({ key, record })
                  }
                />
              </CheckPermissions>
            }
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </Dropdown>
        </>
      ),
    },
  ];

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title={orgMetric?.title}
      showHeaderBottomBorder={true}
    >
      <div className={styles["add-value-container"]}>
        <CustomButton
          className="primary-btn margin-left"
          icon={<PlusCircleFilled />}
          onClick={() => handleEditCurrentValue(undefined)}
        >
          {t("add-new-value")}
        </CustomButton>
      </div>
      <div className={styles["metrices-values-container"]}>
        <CustomTable
          filters={{
            handleCallback: async (filtersData: any) => {
              const sortData = {
                month: filtersData?.sorting?.["key"],
                value: filtersData?.sorting?.["value"],
              };
              setOrgValuesSorting(sortData);
            },
          }}
          className="custom-table"
          columns={columns}
          dataSource={metricValues?.data}
          loading={isLoading}
          showSearch={false}
        />
      </div>
      <div className={"pagination-margin"}>
        <Pagination
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            setPagination({
              offset: (page - 1) * pageSize,
              limit: pageSize,
            });
          }}
          current={currentPage}
          pageSize={pagination.limit || 5}
          showTotal={(total, range) => (
            <span>
              {t("showing")}&nbsp;
              <b>
                {range[0]}-{range[1]}
              </b>
              &nbsp;{t("of")} <b>{total}</b>&nbsp; {t("items")}
            </span>
          )}
          total={metricValues?.metadata?.total || 0}
        />
      </div>
    </CustomModal>
  );
};

export default ShowOrgMetricValues;
