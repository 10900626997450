import {
  useDeleteTierMutation,
  useGetTierListQuery,
} from "graphql/_generated/graphql";

export const useDeleteTier = () => {
  const [deleteTierFun, { isLoading }] = useDeleteTierMutation();

  const { refetch } = useGetTierListQuery();

  const handleDeleteTier = async (id: string) => {
    try {
      await deleteTierFun({ deleteTierId: id }).unwrap();
      refetch();
    } catch (error) {
      throw error;
    }
  };

  return {
    handleDeleteTier,
    isLoading,
  };
};
