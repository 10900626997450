export const PercentageSvg = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.32"
        d="M8.944 13.936C8.22933 13.936 7.65333 13.648 7.216 13.072C6.77867 12.496 6.56 11.6747 6.56 10.608C6.56 9.56267 6.77867 8.752 7.216 8.176C7.65333 7.6 8.22933 7.312 8.944 7.312C9.65867 7.312 10.2347 7.6 10.672 8.176C11.1093 8.752 11.328 9.56267 11.328 10.608C11.328 11.6747 11.1093 12.496 10.672 13.072C10.2347 13.648 9.65867 13.936 8.944 13.936ZM8.944 13.12C9.34933 13.12 9.68 12.912 9.936 12.496C10.192 12.0693 10.32 11.44 10.32 10.608C10.32 9.776 10.192 9.15733 9.936 8.752C9.68 8.336 9.34933 8.128 8.944 8.128C8.528 8.128 8.192 8.336 7.936 8.752C7.68 9.15733 7.552 9.776 7.552 10.608C7.552 11.44 7.68 12.0693 7.936 12.496C8.192 12.912 8.528 13.12 8.944 13.12ZM9.248 18.192L15.04 7.312H15.936L10.144 18.192H9.248ZM16.256 18.192C15.5413 18.192 14.9653 17.904 14.528 17.328C14.0907 16.752 13.872 15.9307 13.872 14.864C13.872 13.8187 14.0907 13.008 14.528 12.432C14.9653 11.856 15.5413 11.568 16.256 11.568C16.9707 11.568 17.5467 11.856 17.984 12.432C18.4213 13.008 18.64 13.8187 18.64 14.864C18.64 15.9307 18.4213 16.752 17.984 17.328C17.5467 17.904 16.9707 18.192 16.256 18.192ZM16.256 17.376C16.6613 17.376 16.992 17.168 17.248 16.752C17.504 16.3253 17.632 15.696 17.632 14.864C17.632 14.032 17.504 13.4133 17.248 13.008C16.992 12.592 16.6613 12.384 16.256 12.384C15.84 12.384 15.504 12.592 15.248 13.008C14.992 13.4133 14.864 14.032 14.864 14.864C14.864 15.696 14.992 16.3253 15.248 16.752C15.504 17.168 15.84 17.376 16.256 17.376Z"
        fill="#303030"
      />
    </svg>
  );
};
