import { useState, useEffect } from "react";
import styles from "features/rewards/css/PartnerDetail.module.scss";
import { useTranslation } from "react-i18next";
import { Select, Space, Switch } from "antd";
import CustomButton from "components/custom-button/CustomButton";
import {
  PlusCircleFilled,
  DownloadOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import CustomTable from "components/custom-table/CustomTable";
import AddPartnerReward from "./AddPartnerReward";
import CustomLanguageSelector from "components/form/custom-language-selector/CustomLanguageSelector";
import { ColumnsType } from "antd/lib/table";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import { PERMS, errorFormat, getLanguage } from "helpers";
import moment from "moment";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import {
  Comparison,
  PartnerRewardList,
  useGetConfigRewardTypesQuery,
  useGetConfigTiersQuery,
  useGetPartnerRewardListQuery,
  useLazyGetPartnerRewardListQuery,
} from "graphql/_generated/graphql";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import CustomConfirmModal from "components/custom-confirm-modal/CustomConfirmModal";
import { useReward } from "../hooks/useReward";
import customNotification from "components/custom-notification/CustomNotification";
import AddBulkRewards from "./AddBulkRewards";
import { getLangDir } from "helpers/getLangDir";
import { useErrorHandler } from "hooks/useErrorHandler";
interface PartnerRewardsProps {
  partnerId: string;
}

const PartnerRewards: React.FunctionComponent<PartnerRewardsProps> = ({
  partnerId,
}) => {
  const { t } = useTranslation();
  const [langVisibility, setLangVisibility] = useState(false);
  const [langCode, setLangCode] = useState<string>();
  const [isAddLanguage, setIsAddLanguage] = useState(false);
  const [isAddPartnerReward, setIsAddPartnerReward] = useState(false);
  const [editId, setEditId] = useState<string | null>(null);
  const [isActivated, setIsActivated] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [rewardData, setRewardData] = useState<MappedPartnerRewardList>();
  const [isBulkUpload, setIsBulkUpload] = useState(false);

  const [columnsData, setColumnsData] = useState<PartnerRewardList>();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { isFetching } = useGetPartnerRewardListQuery();
  const [getPartnerListFun] = useLazyGetPartnerRewardListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { data: tiersData } = useGetConfigTiersQuery();
  const { data: rewardTypesData } = useGetConfigRewardTypesQuery();
  const { handleError } = useErrorHandler();

  const {
    handleTogglePartnerReward,
    handleDeleteReward,
    isLoading: isLoadingReward,
  } = useReward();

  const partnerFilter = {
    key: "partnerId",
    value: partnerId,
    comparison: Comparison.Equal,
  };

  useEffect(() => {
    setFiltersData({
      filters: {
        and: [partnerFilter],
      },
    });
  }, []);

  useEffect(() => {
    if (!filtersData) {
      fetchDataFromApi(getPartnerListFun, setColumnsData, {
        filters: {
          and: [partnerFilter],
        },
      });
    } else {
      fetchDataFromApi(getPartnerListFun, setColumnsData, filtersData);
    }
  }, [isFetching, filtersData]);

  const mappedColumnsData = columnsData?.data.map((item) => {
    return (
      {
        ...item,
        hasChildren: true,
        children:
          item?.translations && Object.keys?.(item?.translations)
            ? Object.keys?.(item?.translations)?.map((record) => {
                return {
                  ...item,
                  name: item?.translations?.[record]?.name,
                  language: record,
                };
              })
            : null,
      } ?? []
    );
  });

  const handleSubmit = (values: any, actions: any) => {
    setLangCode(values?.language);
    setLangVisibility(false);
    setIsAddPartnerReward(true);
    actions.resetForm();
  };

  const handleMenu = ({ key, id, record }: any) => {
    if (key === "edit-reward") {
      setIsAddLanguage(false);
      setEditId(id);
      setIsAddPartnerReward(true);
      setLangCode(undefined);
    }
    if (key === "delete-reward") {
      setRewardData(record);
      setIsDelete(true);
      () => submitDeleteReward();
    }
    if (key === "add-new-language") {
      setRewardData(record);
      setLangVisibility(true);
      setEditId(id);
      setIsAddLanguage(true);
    }
    if (key === "edit-language") {
      setLangCode(record.language);
      setIsAddLanguage(true);
      setEditId(id);
      setIsAddPartnerReward(true);
    }
  };

  const handleToggleReward = (record: MappedPartnerRewardList) => {
    setRewardData(record);
    setIsActivated(true);
  };

  const handleToggleRewardActivation = async () => {
    try {
      const isActivated = await handleTogglePartnerReward(rewardData?.id ?? "");
      customNotification(
        "success",
        isActivated
          ? t("reward-activated-successfully")
          : t("reward-deactivated-successfully"),
      );
      setIsActivated(false);
      setRewardData(undefined);
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const submitDeleteReward = async () => {
    try {
      await handleDeleteReward(rewardData?.id);
      customNotification("success", t("deleted-reward-successfully"));
      setIsDelete(false);
      setRewardData(undefined);
    } catch (error) {
      handleError(error as string | object);
    }
  };

  type MappedPartnerRewardList = NonNullable<typeof mappedColumnsData>[0];

  const columns: ColumnsType<MappedPartnerRewardList> = [
    {
      title: `${t("id")}`,
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, index) =>
        record.hasChildren ? (currentPage - 1) * pageSize + index + 1 : null,
    },
    {
      title: `${t("title")}`,
      key: "name",
      dataIndex: "name",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => {
        return (
          <div className={record?.hasChildren ? "parent-row" : "child-row"}>
            <Space>
              <CustomAvatar shape="square" imgSrc={record?.image ?? ""} />
              <span className={`cut-text-${getLangDir(record.name)}`}>
                {record.name}
              </span>
            </Space>
          </div>
        );
      },
    },
    {
      title: `${t("language")}`,
      key: "language",
      dataIndex: "language",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => getLanguage(record.language),
    },
    {
      title: `${t("type")}`,
      key: "rewardType",
      dataIndex: "rewardType",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => record.rewardType?.name,
    },
    {
      title: `${t("tier")}`,
      key: "tier",
      dataIndex: "tier",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => record.tier?.name,
    },
    {
      title: `${t("provider")}`,
      key: "rewardProvider",
      dataIndex: "rewardProvider",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => record.rewardProvider?.name,
    },
    {
      title: `${t("category")}`,
      key: "rewardCategory:",
      dataIndex: "rewardCategory:",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => record.rewardCategory?.name,
    },
    {
      title: `${t("creation-date")}`,
      key: "createdAt",
      dataIndex: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) => moment(createdAt).format("MMMM Do YYYY"),
    },
    {
      title: `${t("updated-at")}`,
      key: "updatedAt",
      dataIndex: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) => moment(updatedAt).format("MMMM Do YYYY"),
    },
    {
      align: "center" as "center",
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      render: (_text, record, _index) => (
        <>
          <CheckPermissions permission={PERMS.TOGGLE_PARTNER_REWARD_ACTIVATION}>
            <Switch
              className="custom-switch"
              defaultChecked
              checked={record.isActive}
              onClick={() => handleToggleReward(record)}
            />
          </CheckPermissions>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "Actions",
      align: "center",
      render: (id: string, record) => {
        const mainActions = [
          {
            key: "edit-reward",
            label: t("edit-reward"),
          },
          {
            key: "add-new-language",
            label: t("add-new-language"),
          },
          {
            key: "delete-reward",
            label: t("delete-reward"),
          },
        ];
        const langActions = [{ key: "edit-language", label: "Edit Language" }];
        return (
          <CustomDropDown
            menu={{
              items: record?.hasChildren ? mainActions : langActions,
              onClick: ({ domEvent, key, keyPath }) =>
                handleMenu({ domEvent, key, keyPath, id, record }),
            }}
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </CustomDropDown>
        );
      },
    },
  ];

  const partnerActions = () => {
    return (
      <Space size={10}>
        <CheckPermissions permission={PERMS.BULK_IMPORT_REWARDS}>
          <CustomButton
            onClick={() => {
              setIsBulkUpload(true);
            }}
            icon={<DownloadOutlined />}
            className="light-btn"
          >
            {t("import-rewards")}
          </CustomButton>
        </CheckPermissions>
        <CheckPermissions permission={PERMS.ADD_PARTNER_REWARD}>
          <CustomButton
            onClick={() => {
              setLangVisibility(true);
              setIsAddLanguage(false);
              setRewardData(undefined);
            }}
            icon={<PlusCircleFilled />}
            className="primary-btn"
          >
            {t("add-reward")}
          </CustomButton>
        </CheckPermissions>
      </Space>
    );
  };

  return (
    <>
      <CustomTable
        expandIconColumnIndex={1}
        dataSource={mappedColumnsData}
        columns={columns}
        filters={{
          handleCallback: async (filtersData: any) => {
            const mergedFilters = { ...filtersData };
            mergedFilters.filters = {
              ...mergedFilters.filters,
              and: [partnerFilter, ...(mergedFilters.filters?.and || [])],
            };
            setPageSize(mergedFilters?.pagination?.offset?.pageSize);
            setCurrentPage(mergedFilters?.pagination?.offset?.page);
            setFiltersData(mergedFilters);
            fetchDataFromApi(getPartnerListFun, setColumnsData, mergedFilters);
          },
          search: true,
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],
          manualFilters: [
            {
              key: "rewardTypeId",
              value: "rewardTypeId",
              comparison: "Equal",
              applyAs: "and",
              render: (onChangeCallback) => (
                <Select
                  options={rewardTypesData?.getConfigRewardTypes.options}
                  fieldNames={{ label: "label", value: "id" }}
                  className="filter-select"
                  onChange={onChangeCallback}
                  allowClear
                  placeholder={t("type-filter")}
                />
              ),
            },
            {
              key: "tier.id",
              value: "createdAt",
              comparison: "Equal",
              applyAs: "and",
              render: (onChangeCallback) => (
                <Select
                  options={tiersData?.getConfigTiers.options}
                  fieldNames={{ label: "label", value: "id" }}
                  className="filter-select"
                  onChange={onChangeCallback}
                  allowClear
                  placeholder={t("tier-filter")}
                />
              ),
            },
          ],
        }}
        totalItems={columnsData?.metadata?.total}
        loading={isLoading}
        className="custom-table"
        rowKey={(record, index) =>
          record.hasChildren ? record.id : record.id + index
        }
        headerTitle={t("partner-rewards")}
        actions={partnerActions}
      />
      {langVisibility && (
        <CustomLanguageSelector
          title={isAddLanguage ? "" : `${t("reward")} ${t("main-language")}`}
          visible={langVisibility}
          setVisible={setLangVisibility}
          onSubmit={handleSubmit}
          saveText={isAddLanguage ? undefined : t("add-reward")}
          data={rewardData}
          label={t("reward")}
        />
      )}
      {isAddPartnerReward && (
        <AddPartnerReward
          visible={isAddPartnerReward}
          setVisible={setIsAddPartnerReward}
          editId={editId}
          setEditId={setEditId}
          langCode={langCode}
          partnerId={partnerId}
          isAddLanguage={isAddLanguage}
        />
      )}
      {isActivated && (
        <CustomConfirmModal
          visible={isActivated}
          setVisible={setIsActivated}
          desc={
            rewardData?.isActive
              ? t("sure-to-deactivate-reward")
              : t("sure-to-activate-reward")
          }
          loading={isLoadingReward}
          onSubmit={handleToggleRewardActivation}
        />
      )}
      {isDelete && (
        <CustomConfirmModal
          visible={isDelete}
          setVisible={setIsDelete}
          desc={t("sure-to-delete-reward")}
          loading={isLoadingReward}
          onSubmit={submitDeleteReward}
        />
      )}
      {isBulkUpload && (
        <AddBulkRewards
          partnerId={partnerId}
          visible={isBulkUpload}
          setVisible={setIsBulkUpload}
        />
      )}
    </>
  );
};

export default PartnerRewards;
