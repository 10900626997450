import * as Yup from "yup";
import { requiredFieldValidation } from "validations/schema/common/commonValidations";
import * as Validations from "validations/schema/common/validations";

export const inviteCompanySchema = Yup.object().shape({
  companyName: Validations.requiredAlphaFieldWithoutSpaces("company"),
  email: Validations.email,
  roleId: Validations.required("role"),
  subscriptions: Validations.requiredFieldPositiveNumber("subscriptions"),
  regionalServiceKey: Validations.required("region"),
});

export const editCompanySchema = inviteCompanySchema.shape({
  image: requiredFieldValidation("${path} must have an Image"),
  subscriptions: requiredFieldValidation("${path} must have subscriptions"),
  companyName: requiredFieldValidation("${path} must have companyName"),
  companySize: requiredFieldValidation("${path} must have companySize"),
  avgAgeOfEmployee: requiredFieldValidation(
    "${path} must have avgAgeOfEmployee",
  ),
  goal: requiredFieldValidation("${path} must have a goal"),
  isEapEnabled: requiredFieldValidation("${path} must have EAP"),
});
