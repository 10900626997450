import { Col, Row } from "antd";
import AuthLayout from "features/auth/auth-layout/AuthLayout";
import { setToken } from "features/auth/authSlice";
import CustomButton from "components/custom-button/CustomButton";
import { customNotification } from "components/custom-notification/CustomNotification";
import CustomPhoneInput from "components/form/custom-phone-input/CustomPhoneInput";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextArea } from "components/form/custom-text-area/CustomTextArea";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { errorFormat } from "helpers";
import { useRefreshToken } from "hooks/useRefreshToken";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { FieldArray, Form, Formik } from "formik";
import {
  useGetConfigAgeOfEmployeeQuery,
  useGetConfigCompanySizeQuery,
  useGetConfigPillarsQuery,
  useLazyGetPermissionsQuery,
} from "graphql/_generated/graphql";
import React from "react";
import { useTranslation } from "react-i18next";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { messaging, getToken } from "services/firebase/firebase.service";
import { permissions } from "store/permissionsSlice";
import { clientProfileSchema } from "validations/schema";
import styles from "features/company/css/CompanyProfile.module.scss";
import { useCompanyProfile } from "features/company/hooks/useCompanyProfile";
import { useErrorHandler } from "hooks/useErrorHandler";

const CompanyProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleError } = useErrorHandler();
  const { data: pillarsData } = useGetConfigPillarsQuery();
  const [isLoading, handleCompleteClientProfile] = useCompanyProfile();
  const { data: companySizeData } = useGetConfigCompanySizeQuery();
  const { data: avgAgeOfEmployeesData } = useGetConfigAgeOfEmployeeQuery();
  const refreshToken = useSelector((state: any) => state.auth.refreshToken);
  const { handleRefreshToken } = useRefreshToken();
  const [getPermissionsFun] = useLazyGetPermissionsQuery();
  const initialValues = {
    phoneNumber: "",
    companySize: "",
    mainFocus: [],
    avgAgeOfEmployee: "",
    goal: "",
    clientAdmin: {
      firstName: "",
      lastName: "",
    },
    // buildings: [
    //   {
    //     name: "",
    //     address: {
    //       address: "",
    //       city: "",
    //       coordinates: { lat: "", lng: "" },
    //       country: "",
    //     },
    //   },
    // ],
  };

  return (
    <AuthLayout>
      <Formik
        initialValues={initialValues}
        validationSchema={clientProfileSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            const res = await handleCompleteClientProfile({
              completeProfileDTO: values,
            });

            resetForm();

            // const deviceToken = await getToken(messaging);

            //get new tokens, we are getting new tokens again because, after the profile complete, in old tokens has the isProfileComplete value is false but now the profile is complete that's why we need to get the new token so we can get the isProfileComplete updated value
            const newTokens = await handleRefreshToken({
              tokens: { deviceToken: "", refreshToken },
            });

            //replace the new tokens with the new tokens in the store
            dispatch(setToken(newTokens.refreshToken));

            // navigate to company dashboard
            navigate("/company-dashboard");

            //call permissions api, this api will give us all the permissions which permissions are available for this specific role
            const data = await getPermissionsFun().unwrap();

            // store the Permissions to store
            dispatch(permissions(data.getPermissions));

            customNotification("success", res.completeClientProfile.msg);
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ setFieldValue, errors }) => {
          return (
            <div className={styles["company-card"]}>
              <h1 className={styles["company-profile"]}>
                {t("complete-company-profile")}
              </h1>
              <Form>
                <Row gutter={[24, 0]}>
                  <Col xs={24} sm={12}>
                    <CustomTextField
                      name="clientAdmin.firstName"
                      placeholder={t("first-name")}
                      label={t("first-name")}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <CustomTextField
                      name="clientAdmin.lastName"
                      placeholder={t("last-name")}
                      label={t("last-name")}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <CustomPhoneInput
                      setFieldValue={setFieldValue}
                      name="phoneNumber"
                      placeholder={t("contact-number")}
                      label={t("contact-number")}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <CustomSelectField
                      label={t("average-age-of-employees")}
                      name="avgAgeOfEmployee"
                      placeholder={t("average-age-of-employees")}
                      className="form-select"
                      options={
                        avgAgeOfEmployeesData?.getConfigAgeOfEmployee.options
                      }
                      fieldNames={{ label: "label", value: "id" }}
                    />
                  </Col>
                </Row>
                <CustomSelectField
                  label={t("company-size")}
                  name="companySize"
                  placeholder={t("company-size")}
                  className="form-select"
                  options={companySizeData?.getConfigCompanySize.options}
                  fieldNames={{ label: "label", value: "id" }}
                />

                <CustomSelectField
                  label={t("main-focus")}
                  name="mainFocus"
                  mode="multiple"
                  placeholder={t("select-pillars")}
                  className="form-select"
                  options={pillarsData?.getConfigPillars.options}
                  fieldNames={{ label: "label", value: "id" }}
                />

                <CustomTextArea
                  label={t("2022-goal")}
                  name="goal"
                  rows={10}
                  placeholder={t("write-your-2022-goal")}
                />

                <div className={styles["btn-div"]}>
                  <CustomButton
                    className="primary-btn"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    {t("update-profile")}
                  </CustomButton>
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </AuthLayout>
  );
};

export default CompanyProfile;
