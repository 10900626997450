import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { errorFormat } from "helpers";
import TranslationFieldArr from "components/translation-field-array/TranslationFieldArr";
import { FieldArray, Form, Formik } from "formik";
import {
  BuildingCreate,
  useGetBuildingsConfigQuery,
  useGetConfigCountriesQuery,
} from "graphql/_generated/graphql";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { buildingSchema } from "validations/schema";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useBuilding } from "./useBuilding";
import styles from "../../company/company-profile/CompanyProfile.module.scss";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { useErrorHandler } from "hooks/useErrorHandler";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
};

const AddBuilding = ({ setVisible, visible, editId, setEditId }: Props) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();

  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    address: {
      address: "",
      city: "",
      // coordinates: { lat: "", lng: "" },
      country: "",
    },
    translations: [],
  });

  const {
    isLoading,
    handleAddBuilding,
    handleFetchPreviousBuildingData,
    handleUpdateBuilding,
    countries,
  } = useBuilding();

  const { refetch: buildingFetch } = useGetBuildingsConfigQuery();

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  useEffect(() => {
    if (editId) {
      handleFetchPreviousBuildingData(editId).then((data: any) => {
        setInitialValues(data);
      });
    } else {
      setInitialValues({
        translations: [],
        name: "",
        address: {
          address: "",
          city: "",
          // coordinates: { lat: "", lng: "" },
          country: "",
        },
      });
    }
  }, [editId]);

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title={t("add-building")}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={buildingSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            if (!editId) {
              await handleAddBuilding({ create: values });
              customNotification("success", t("building-added-successfully"));
            } else {
              await handleUpdateBuilding({
                id: editId,
                updateObj: values,
              });
              customNotification("success", t("building-updated-successfully"));
            }

            setEditId(null);
            resetForm();
            buildingFetch();
            setVisible(false);
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <CustomTextField
              label={t("building-name")}
              name="name"
              placeholder={t("building-name-placeholder")}
            />
            <CustomTextField label={t("address")} name={`address.address`} />
            <CustomTextField label={t("city")} name={`address.city`} />
            <CustomSelectField
              label={t("select-country")}
              className="form-select"
              name="address.country"
              placeholder={t("select-country-placeholder")}
              options={countries}
              fieldNames={{ label: "label", value: "code" }}
            />
            <TranslationFieldArr values={values} attribute="name" />
            <ModalFooter
              saveText={t("save")}
              loading={isLoading}
              onCancel={handleCancel}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddBuilding;
