import { Checkbox, Space } from "antd";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import styles from "features/users/css/UserDetails.module.scss";
import { Form, Formik } from "formik";
import { GetEmployeeDetailQuery } from "graphql/_generated/graphql";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetEmployeeDetails } from "../hooks/useGetEmployeeDetails";

interface EmployeeDetailsProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  employeeId: string | null;
  setEmployeeId?: React.Dispatch<React.SetStateAction<string | null>>;
}

const EmployeeDetails: React.FunctionComponent<EmployeeDetailsProps> = ({
  visible,
  setVisible,
  employeeId,
  setEmployeeId,
}) => {
  const [employeeData, setEmployeeData] =
    useState<GetEmployeeDetailQuery["getEmployeeDetail"]>();

  const { t } = useTranslation();
  const { handleGetEmployeeDetails, isLoading } = useGetEmployeeDetails();

  const handleCancel = () => {
    setVisible(false);
    setEmployeeId && setEmployeeId(null);
  };

  useEffect(() => {
    if (employeeId) {
      handleGetEmployeeDetails(employeeId).then((data) => {
        setEmployeeData(data);
      });
    }
  }, [employeeId]);

  return (
    <CustomModal
      width={920}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={t("employee-details")}
    >
      <Formik
        enableReinitialize
        initialValues={{}}
        onSubmit={(values, { resetForm }) => {
          resetForm();
          setVisible(false);
          setEmployeeId && setEmployeeId(null);
        }}
      >
        <Form>
          <div className={styles["form-grid"]}>
            <CustomAvatar
              imgSrc={employeeData?.image ?? employeeData?.avatar?.imgPath}
              shape="circle"
              size={120}
              isUserProfile={!!employeeData?.image}
            />
            <div>
              <div className={styles["desc-container"]}>
                <div className={styles["detail"]}>
                  <div className={styles["label"]}>{t("name")}</div>
                  <h4 className={styles["description"]}>
                    {employeeData?.firstName} {employeeData?.lastName}
                  </h4>
                </div>
                <div className={styles["detail"]}>
                  <div className={styles["label"]}>{t("created-at")}</div>
                  <h4 className={styles["description"]}>
                    {moment(employeeData?.createdAt).format("DD/MM/YYYY")}
                  </h4>
                </div>
                <div className={styles["detail"]}>
                  <div className={styles["label"]}>{t("membership-id")}</div>
                  <h4 className={styles["description"]}>
                    {employeeData?.walletInfo?.memberShipCardNumber}
                  </h4>
                </div>
                <div className={styles["detail"]}>
                  <div className={styles["label"]}>{t("points")}</div>
                  <h4 className={styles["description"]}>
                    {employeeData?.walletInfo?.points}
                  </h4>
                </div>
                <div className={styles["detail"]}>
                  <div className={styles["label"]}>{t("tier")}</div>
                  <Space>
                    <h4 className={styles["description"]}>
                      {employeeData?.walletInfo?.tierName}
                    </h4>
                    <CustomAvatar
                      size={12}
                      imgSrc={employeeData?.walletInfo?.tierIcon ?? ""}
                    />
                  </Space>
                </div>
                <div className={styles["detail"]}>
                  <div className={styles["label"]}>{t("company")}</div>
                  <h4 className={styles["description"]}>
                    {employeeData?.employer?.companyName}
                  </h4>
                </div>
                <div className={styles["detail"]}>
                  <div className={styles["label"]}>{t("department")}</div>
                  <h4 className={styles["description"]}>
                    {employeeData?.orgInfo?.department?.name}
                  </h4>
                </div>
                <div className={styles["detail"]}>
                  <div className={styles["label"]}>{t("position-name")}</div>
                  <h4 className={styles["description"]}>
                    {employeeData?.orgInfo?.position?.name}
                  </h4>
                </div>
                <div className={styles["detail"]}>
                  <div className={styles["label"]}>{t("building")}</div>
                  <h4 className={styles["description"]}>
                    {employeeData?.orgInfo?.building?.name}
                  </h4>
                </div>
              </div>
              <div className={styles["desc-container"]}>
                <div className={styles["status-detail"]}>
                  <div className={styles["label"]}>{t("status")}</div>
                  <Checkbox
                    checked={!!employeeData?.isActive}
                    value={!!employeeData?.isActive}
                  >
                    {employeeData?.isActive ? t("active") : t("deactivated")}
                  </Checkbox>
                </div>
              </div>
            </div>
          </div>
          <ModalFooter
            onCancel={handleCancel}
            saveText={t("save")}
            loading={isLoading}
          />
        </Form>
      </Formik>
    </CustomModal>
  );
};

export default EmployeeDetails;
