import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { convertTranslationArrToObj, errorFormat } from "helpers";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { faqsSchema } from "validations/schema";
import { useFAQs } from "../useFAQs";
import { useState, useEffect } from "react";
import { useGetFaqListQuery, FaqCreate } from "graphql/_generated/graphql";
import TranslationFieldArr from "../translation-field-array/TranslationFieldArr";
import { useErrorHandler } from "hooks/useErrorHandler";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
};

const AddFAQ = ({ setVisible, visible, editId, setEditId }: Props) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();

  //FAQs hooks
  const { refetch } = useGetFaqListQuery();
  const { handleFetchEditFaq, handleUpdateFaq, handleAddFaq, isLoading } =
    useFAQs();

  //Initializing states
  const [initialValues, setInitialValues] = useState<FaqCreate>({
    question: "",
    answer: "",
    isActive: true,
    translations: [],
  });

  //this function is used to close modal with unsaved changes
  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  //Re-Initializing states after fetching faq data for specific id
  useEffect(() => {
    if (editId) {
      handleFetchEditFaq(editId).then((data) => {
        setInitialValues(data);
      });
    } else {
      setInitialValues({
        question: "",
        answer: "",
        translations: [],
        isActive: true,
      });
    }
  }, [editId]);

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={t(editId ? "faq.edit-question" : "faq.add-question")}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={faqsSchema}
        onSubmit={async (values, { resetForm }) => {
          if (!editId) {
            try {
              await handleAddFaq({
                create: {
                  ...values,
                  isActive: true,
                },
              });
              customNotification("success", t("question-added-successfully"));
              resetForm();
              setVisible(false);
            } catch (error) {
              handleError(error as string | object);
            }
          } else {
            try {
              await handleUpdateFaq({
                id: editId,
                update: values,
              });
              setEditId(null);
              customNotification("success", t("question-updated-successfully"));
              resetForm();
              refetch();
              setVisible(false);
            } catch (error) {
              handleError(error as string | object);
            }
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <CustomTextField name="question" placeholder={t("question")} />
            <CustomTextField name="answer" placeholder={t("answer")} />
            <TranslationFieldArr values={values} />
            <ModalFooter
              saveText={t("save-question")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddFAQ;
