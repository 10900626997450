import { StringMap } from "components/interfaces/StringMap";

export const rewardDataMap: StringMap = {
  nameEN: "name",
  descriptionEN: "description",
  nameAR: "nameAR",
  descriptionAR: "descriptionAR",
  image: "image",
  isDateEnable: "isDateEnable",
  rewardTypeName: "rewardTypeName",
  tierName: "tierName",
  rewardProviderName: "rewardProviderName",
  rewardCategoryName: "rewardCategoryName",
  companyVisibility: "companyVisibility",
  companiesNameList: "companiesNameList",
  startDate: "startDate",
  endDate: "endDate",
};
