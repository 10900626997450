import {
  MutationUpdateMeasurementArgs,
  useUpdateMeasurementMutation,
  useLazyGetMeasurementQuery,
} from "graphql/_generated/graphql";

export const useUpdateMeasurement = () => {
  const [updateMeasurementFun, { isLoading }] = useUpdateMeasurementMutation();

  const [prevEditDataFun, { isLoading: prevDataLoading }] =
    useLazyGetMeasurementQuery();

  //this function is responsible for updating the Unit
  const handleUpdateMeasurement = async (
    values: MutationUpdateMeasurementArgs
  ) => {
    try {
      await updateMeasurementFun({
        updateMeasurementId: values.id,
        updateObj: values.updateObj,
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  //this function is responsible for fetching the Unit data
  const handleFetchMeasurementData = async (id: string) => {
    try {
      const res = await prevEditDataFun({ getMeasurementId: id }).unwrap();
      return res.getMeasurement;
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading: isLoading || prevDataLoading,
    handleUpdateMeasurement,
    handleFetchMeasurementData,
  };
};
