import CustomModal from "components/custom-modal/CustomModal";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { inviteCompanySchema } from "validations/schema";
import ModalFooter from "../../../components/custom-modal/ModalFooter";
import { useCompany } from "../useCompany";
import {
  useGetClientListQuery,
  useGetClientListWithoutChildrenQuery,
  useGetConfigRolesQuery,
  useGetRegionListQuery,
} from "graphql/_generated/graphql";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomCheckBoxGroup } from "components/form/custom-checkbox-group/CustomCheckBoxGroup";
import { useErrorHandler } from "hooks/useErrorHandler";

//define props
type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
};

export default function InviteChildClient({ setVisible, visible, id }: Props) {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { isLoading, inviteCompany } = useCompany();
  const { data: rolesData } = useGetConfigRolesQuery();
  const { refetch } = useGetClientListWithoutChildrenQuery();
  const { refetch: refetchList } = useGetClientListQuery();
  const { data: regionList } = useGetRegionListQuery();

  //this function is responsible for
  const handleCancel = () => {
    setVisible(false);
  };

  //define initial values of the form
  const [initialValues, setInitialValues] = useState<any>({
    roleId: "",
    image: null,
    email: "",
    companyName: "",
    parent: "",
    subscriptions: "",
    isEapEnabled: false,
    regionalServiceKey: "",
  });

  useEffect(() => {
    setInitialValues({ ...initialValues, parent: id });
  }, [id]);

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={t("invite-company")}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={inviteCompanySchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            await inviteCompany({
              client: values,
            });
            resetForm();
            refetch();
            refetchList();
            setVisible(false);
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <CustomUploadFile
              imgUrl={values.image}
              name="image"
              setFieldValue={setFieldValue}
              accept="image/*"
            />

            <CustomTextField
              label={t("company-name")}
              name="companyName"
              placeholder={t("enter-company-name")}
            />

            <CustomTextField
              label={t("company-user-email")}
              type="email"
              name="email"
              placeholder={t("enter-company-email")}
            />
            <CustomTextField
              type="number"
              name="subscriptions"
              label={t("subscriptions")}
              placeholder={t("enter-subscriptions")}
            />
            <CustomSelectField
              name="regionalServiceKey"
              label={t("region")}
              placeholder={t("select-region")}
              options={regionList?.getRegionList}
              fieldNames={{
                label: "country",
                value: "regionalServiceKey",
              }}
            />
            <CustomSelectField
              label={t("role")}
              className="form-select"
              name="roleId"
              placeholder={t("select-role")}
              options={rolesData?.getConfigRoles.options}
              fieldNames={{
                label: "label",
                value: "id",
              }}
            />
            <div className="form-control">
              <CustomCheckBoxGroup
                name="isEapEnabled"
                options={[{ label: t("active"), value: true }]}
              >
                {t("enable-eap")}
              </CustomCheckBoxGroup>
            </div>
            <ModalFooter
              saveText={t("invite-child-company")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
}
