import { MoreOutlined } from "@ant-design/icons";
import { Avatar, Space, Switch, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import CustomButton from "components/custom-button/CustomButton";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomMenu from "components/custom-menu/CustomMenu";
import CustomTable from "components/custom-table/CustomTable";
import moment from "moment";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  ContentStatus,
  useGetCollectionListQuery,
  useGetSpecialistConfigQuery,
  useLazyGetCollectionListQuery,
  CollectionList as CollectionListType,
} from "graphql/_generated/graphql";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { useCollection } from "../useCollection";
import { customNotification } from "components/custom-notification/CustomNotification";
import { PERMS, capitalize, errorFormat } from "helpers";
import AddCollection from "../add-collection/AddCollection";
import { useNavigate } from "react-router-dom";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import { languages } from "i18n/languages";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import { useLanguages } from "hooks";
import { getLabelFromValue } from "../../utils";
import { createRenderFunction } from "../add-collection/utils";
import { useErrorHandler } from "hooks/useErrorHandler";

interface CollectionListProps {}

const renderTextLanguages = createRenderFunction("language", "translations");

const CollectionList: React.FunctionComponent<CollectionListProps> = () => {
  //Initializing states
  const [filtersData, setFiltersData] = useState<any>(null);
  const [editId, setEditId] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [columnsData, setColumnsData] = useState<CollectionListType>();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { languages } = useLanguages();

  //Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isFetching, refetch } = useGetCollectionListQuery();
  const { refetch: refetchSpecialistConfig } = useGetSpecialistConfigQuery();
  const [getAllCollectionsDataFun] = useLazyGetCollectionListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleActivationCollection } = useCollection();
  const { handleError } = useErrorHandler();

  //Helpers
  const handleMenu = ({ key, id }: any) => {
    if (key === "edit") {
      setVisible(true);
      setEditId(id);
    }
    if (key === "collection-detail") {
      navigate(`/program-detail/${id}`, { replace: true });
    }
  };

  //UseEffect
  useEffect(() => {
    fetchDataFromApi(
      getAllCollectionsDataFun,
      setColumnsData,
      filtersData,
    ).catch((e) => handleError(e));
  }, [isFetching]);

  const columns: ColumnsType<CollectionListType["data"][0]> = [
    {
      title: t("id"),
      key: "id",
      dataIndex: "id",
      width: "5%",
      sorter: {
        multiple: 1,
      },
      render: (_text, _record, index) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: t("title"),
      key: "title",
      dataIndex: "title",
      width: "20%",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, index) => (
        <Space>
          <CustomAvatar shape="square" imgSrc={record.image} />
          <span>{record.title}</span>
        </Space>
      ),
    },
    {
      title: capitalize(t("type")),
      key: "collectionType",
      dataIndex: "collectionType",
      width: "7%",
    },
    {
      title: capitalize(t("categories")),
      key: "categories",
      dataIndex: "categories",
      width: "13%",
      render: (_text, record, _index) => {
        const activeCategories = record.categories?.filter(
          (category) => category.isActive,
        );

        return activeCategories?.map((category) => category.name).join(", ");
      },
    },
    {
      title: capitalize(t("pillars")),
      key: "pillars",
      dataIndex: "pillars",
      width: "10%",
      render: (_text, record, _index) =>
        record.pillars?.map((pillar) => pillar.title).join(", "),
    },
    {
      title: t("text-languages"),
      dataIndex: "translations",
      key: "translations",
      // responsive: ["xxl"],
      width: "10%",
      render: (_text, record, _index) => (
        <Space>{renderTextLanguages(record, languages).join(", ")}</Space>
      ),
    },
    {
      title: t("spoken-language"),
      dataIndex: "spokenLanguage",
      key: "spokenLanguage",
      render: (_text, record, _index) => (
        <span>{getLabelFromValue(languages, _text)}</span>
      ),
    },
    {
      title: t("no-of-content"),
      key: "id",
      dataIndex: "contents",
      align: "center" as "center",
      width: "5%",
      responsive: ["xxl"],
      render: (_text, record, _index) => <span>{record.contents.length}</span>,
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      key: "createdAt",
      width: "10%",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) => moment(createdAt).format("MMMM Do YYYY"),
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: "10%",
      sorter: {
        multiple: 1,
      },
      responsive: ["xxl"],
      render: (updatedAt: string) => moment(updatedAt).format("MMMM Do YYYY"),
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      width: "10%",
      align: "center",
      render: (status: ContentStatus) =>
        status === ContentStatus.Published ? (
          <Tag className="active-tag">{status}</Tag>
        ) : status === ContentStatus.Draft ? (
          <Tag className="de-active-tag">{status}</Tag>
        ) : (
          <Tag className="pending-tag">{status}</Tag>
        ),
    },
    {
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      width: "10%",
      align: "center" as "center",
      render: (id: string, { isActive }) => (
        <CustomPopconfirm
          onConfirm={async () => {
            try {
              const isCollectionActive = await handleActivationCollection(id);
              customNotification(
                "success",
                isCollectionActive
                  ? t("collection-activate-success")
                  : t("collection-deactivate-success"),
              );
              refetch();
              refetchSpecialistConfig();
            } catch (error) {
              handleError(error as string | object);
            }
          }}
          title={
            isActive
              ? t("activation-confirmation-deactivate")
              : t("activation-confirmation-activate")
          }
        >
          <Switch className="custom-switch" defaultChecked checked={isActive} />
        </CustomPopconfirm>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: "10%",
      align: "center" as "center",
      render: (id: string) => (
        <>
          <CustomDropDown
            overlay={
              <CustomMenu
                items={[
                  {
                    label: t("edit-program"),
                    key: "edit",
                  },
                  {
                    label: t("view-detail"),
                    key: "collection-detail",
                  },
                ]}
                onClick={({ domEvent, key, keyPath }) =>
                  handleMenu({ domEvent, key, keyPath, id })
                }
              />
            }
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </CustomDropDown>
        </>
      ),
    },
  ];

  return (
    <>
      <CustomTable
        filters={{
          handleCallback: async (filtersData: any) => {
            await fetchDataFromApi(
              getAllCollectionsDataFun,
              setColumnsData,
              filtersData,
            );
            setPageSize(filtersData?.pagination?.offset?.pageSize);
            setCurrentPage(filtersData?.pagination?.offset?.page);
            setFiltersData(filtersData);
          },
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
              placeholder: t("created-at"),
            },
          ],
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
            placeholder: t("status"),
          },
        }}
        totalItems={columnsData?.metadata?.total}
        loading={isLoading}
        columns={columns}
        permission={PERMS.ADD_NEW_POSITION}
        onClick={() => setVisible(true)}
        headerTitle={t("programs")}
        btnText={t("add-program")}
        dataSource={columnsData?.data}
      />

      <AddCollection
        visible={visible}
        setVisible={setVisible}
        editId={editId}
        setEditId={setEditId}
      />
    </>
  );
};

export default CollectionList;
