import CategoriesList from "features/categories/categories-list/CategoriesList";
import CustomTabs from "components/custom-tabs/CustomTabs";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import PillarList from "features/pillars/pillars-list/PillarsList";
import TagList from "pages/tags/TagList";
import * as React from "react";
import styles from "features/content/css/Configurations.module.scss";
import { useTranslation } from "react-i18next";
import MapperList from "features/mapper/mapper-list/MapperList";
interface ConfigurationsProps {}

const Configurations: React.FunctionComponent<ConfigurationsProps> = (
  props,
) => {
  const { t } = useTranslation();
  const tabs = [
    {
      label: t("configurations.pillars"),
      tab: <PillarList />,
    },
    {
      label: t("configurations.categories"),
      tab: <CategoriesList />,
    },
    {
      label: t("configurations.mapper"),
      tab: <MapperList />,
    },
    {
      label: t("configurations.tags"),
      tab: <TagList />,
    },
  ];
  return (
    <DashboardLayout openKey={["Content"]}>
      <div className={styles["config"]}>
        <CustomTabs tabs={tabs} />
      </div>
    </DashboardLayout>
  );
};

export default Configurations;
