import styles from "features/assessment/css/AddLanguage.module.scss";
import {
  CarousalInput,
  useLazyGetAdminAssessmentQuestionsQuery,
  useLazyGetAssessmentCarousalQuery,
} from "graphql/_generated/graphql";

import { Form, Space, Spin, Typography } from "antd";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import CustomButton from "components/custom-button/CustomButton";
import AssessmentQuestionHeading from "features/assessment/pillar/AssessmentPillerHeading";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";

import CheckinQuestion from "features/assessment/questions/CheckinQuestion";
import generateUniqueId from "helpers/generateUniqueId";
import { cloneDeep } from "lodash";
import { useLocation, useNavigate } from "react-router";

import { customNotification } from "components/custom-notification/CustomNotification";
import { StringMap } from "components/interfaces";
import CollapseAble from "features/assessment/questions/collapseables";
import EnumAssessmentTypes from "features/assessment/types/EnumAssessmentTypes";
import ICheckIn from "features/assessment/types/ICheckIn";
import ICheckInHash from "features/assessment/types/ICheckInHash";
import ICheckInSettings from "features/assessment/types/ICheckInSettings";
import IInitialObjectProps from "features/assessment/types/IInitialObjectProps";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useTranslation } from "react-i18next";
import AssessmentRoutes from "routes/RouteCollections/AssessmentRoutes";
import { useToggleTranslation } from "../hooks/useToggleTranslation";
import { useUpdateAssessment } from "../hooks/useUpdateAssessment";

const checkInId = generateUniqueId();

const CheckInInitialObject: ICheckIn = {
  image: "",
  description: "",
  translations: {
    description: "",
  },
};

const initialCheckIns: ICheckInHash = {
  [checkInId]: CheckInInitialObject,
};

const settingInitialValues: ICheckInSettings = {
  name: "",
  enableWearableData: true,
  color: "#7573E1",
  notifications: false,
  integrationType: "",
  emoji: null,
};

const initialObject: IInitialObjectProps = {
  description: "",
};

/**
 * Create CheckIn component.
 *
 * @returns JSX
 */
const CheckInLanguage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const params = location.state;

  const [getQuestionsFunc, { isLoading }] =
    useLazyGetAdminAssessmentQuestionsQuery();
  const [getAssessmentCarousalFunc] = useLazyGetAssessmentCarousalQuery();
  const { handleUpdateAssessment } = useUpdateAssessment();
  const { handleToggleTranslation } = useToggleTranslation();

  const [checkInQuestions, setCheckInQuestions] = useState<StringMap>({});
  const [checkInCarousals, setCheckInCarousals] = useState<any>();
  const [assessmentId, setAssessmentId] = useState<string | null>(
    params.assessmentId,
  );
  const formRef = useRef<any>();

  const [visible, setVisible] = useState<boolean>(false);

  const [settings, updateSettings] =
    useState<ICheckInSettings>(settingInitialValues);

  const [checkIns, setCheckIns] = useState<ICheckInHash>(initialCheckIns);

  const [initialValues, setInitialValues] = useState<any>(initialObject);

  useEffect(() => {
    if (!assessmentId) return;
    getCarousals();
    getQuestions();
  }, [assessmentId]);

  const getQuestions = async () => {
    const { data } = await getQuestionsFunc({
      assessmentId: assessmentId!,
      language: params?.language,
    });

    setInitialValues(data?.getAdminAssessmentQuestions?.settings!);
    setCheckInQuestions(data?.getAdminAssessmentQuestions?.questions);
  };

  const getCarousals = async () => {
    const { getAssessmentCarousal } = await getAssessmentCarousalFunc({
      assessmentId: assessmentId!,
      language: params?.language,
    }).unwrap();
    if (getAssessmentCarousal?.length) {
      const carousalData = getAssessmentCarousal.map(
        (item: Omit<CarousalInput, "assessment">) => ({
          ...item,
          description: item?.translations?.description,
          mainText: item?.description,
        }),
      );
      setCheckInCarousals(carousalData);
    }
  };

  const saveSettings = (payload: ICheckInSettings) => {
    toggleSettings();
    updateSettings(payload);
  };

  /**
   * Add Checkin.
   */
  const addCheckIn = () => {
    const newCheckIn = {
      ...CheckInInitialObject,
      // id: generateUniqueId(),
      // SN: setCheckInCarousals.length + 1,
    };

    setCheckInCarousals((oldCheckIns: any) => [...oldCheckIns, newCheckIn]);
  };

  /**
   * Delete CheckIn
   *
   * @param id number
   */
  const deleteCheckIn = (id: string) => {
    const checkInsArr = cloneDeep(checkIns);
    setCheckIns(checkInsArr);
  };

  /**
   * Toggle settings modal.
   */
  const toggleSettings = () => {
    setVisible(!visible);
    setAssessmentId(params.assessmentId);
  };

  /**
   * Handle form submission.
   *
   * @param values any
   * @param resetForm any
   */
  const publishTranslation = async () => {
    const payload = {
      assessmentId,
      language: params?.language,
      isActive: true,
    };

    try {
      await handleToggleTranslation(payload);

      navigate(AssessmentRoutes.AssessmentListRoute.path);
      customNotification(
        "success",
        "Assessment translation published successfully",
      );
    } catch (error: any) {
      handleError(error as string | object);
    }
  };

  /**
   * CheckIn Question Panel Title mapping
   */
  const checkInPanelTitle: { [key: string]: string } = {
    [EnumAssessmentTypes.FULFILLMENT_CHECK_IN]: "Fulfillment ",
    [EnumAssessmentTypes.STRESS_CHECK_IN]: "Stress ",
    [EnumAssessmentTypes.MOOD_CHECK_IN]: "Mood ",
    [EnumAssessmentTypes.SLEEP_CHECK_IN]: "Sleep ",
  };

  return (
    <DashboardLayout className={styles["assessment-create"]}>
      <div className={styles["assessment-create-header"]}>
        <Breadcrumbs
          data={[
            {
              label: t("assessments"),
            },
            {
              label: t("assessment-list"),
            },
            {
              label: `${params?.name} - ${params?.language}`,
            },
          ]}
        />

        <Space>
          <CustomButton
            className="light-btn"
            onClick={() =>
              navigate(`${AssessmentRoutes.AssessmentListRoute.path}#persist`)
            }
          >
            {t("cancel")}
          </CustomButton>
          {/* <CustomButton className="light-btn">Preview</CustomButton> */}
          {/* <CustomButton className="light-btn icon" onClick={toggleSettings}>
        <Icon component={ConfigSvg} />
        Settings
      </CustomButton> */}
          {/* <CustomButton className="light-btn" onClick={handleSubmission}>
        Save As Draft
      </CustomButton> */}
          <CustomButton
            className="primary-btn"
            onClick={() => publishTranslation()}
          >
            {t("save-publish")}
          </CustomButton>
        </Space>
      </div>

      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        initialValues={initialValues}
        innerRef={formRef}
        // validationSchema={assessmentSchema(checkEnabledThings)}
        onSubmit={async (values) => {
          try {
            handleUpdateAssessment({
              id: values.id,
              update: {
                description: values.description,
              },
            });
            customNotification("success", t("assessment-updated-success"));
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ values, setFieldValue, errors, validateOnChange }) => {
          // console.log("validation error", errors);
          return (
            <Form className={styles["assessment-create-form"]}>
              <div className={styles["pillars"]}>
                {/* INFO SECTION */}
                {/* <CollapseAble
              defaultActiveKey={["1"]}
              headerKey={"infoSection"}
              header={<ContainerHeading text="Description" />}
              hideExtra={true}
              expandIconPosition="end"
            >
              <CustomTextArea
                rows={2}
                name="description"
                placeholder="Type description here"
              />
            </CollapseAble> */}

                {/* QUESTIONS SECTION */}
                {/* <CollapseAble
              // key={index}
              defaultActiveKey={["1"]}
              expandIconPosition="end"
              className="pillar"
              hideExtra={true}
              headerKey="1"
              header={
                <AssessmentQuestionHeading
                  assessmentType={initialValues?.assessmentTypeName}
                  title={
                    (checkInPanelTitle[initialValues?.assessmentTypeName] ||
                      "") + " Onboarding"
                  }
                />
              }
            >
              {checkInCarousals?.map((carousal: any, index: number) => (
                <OnBoarding
                  key={index}
                  carousal={carousal}
                  onBoardingId={carousal.id}
                  getCarousals={getCarousals}
                  isAddLanguage={true}
                />
              ))}
            </CollapseAble> */}

                <CollapseAble
                  // key={index}
                  className="pillar"
                  hideExtra={true}
                  expandIconPosition="end"
                  defaultActiveKey={["1"]}
                  headerKey="1"
                  header={
                    <AssessmentQuestionHeading
                      assessmentType={initialValues?.assessmentTypeName}
                      title={
                        (checkInPanelTitle[initialValues?.assessmentTypeName] ||
                          "") + " Question"
                      }
                    />
                  }
                >
                  {/* <div>Only Single choice &amp; slider questions here.</div> */}
                  {Object.keys(checkInQuestions)?.length ? (
                    <CheckinQuestion
                      question={
                        checkInQuestions?.[Object.keys(checkInQuestions)[0]]
                      }
                      isAddLanguage={true}
                      language={params?.language}
                    />
                  ) : isLoading ? (
                    <Spin spinning={isLoading} />
                  ) : (
                    <Typography>{t("no-question-found")}</Typography>
                  )}
                  {/* {panel.content} */}
                </CollapseAble>
              </div>
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
};

export default CheckInLanguage;
