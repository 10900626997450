import {
  PWD_VALIDATION_RULES,
  getCombinedNewPasswordError,
  toLowerCase,
  toUpperCaseFirstWord,
} from "./validationHelpers";
import { getOldDate } from "helpers/DatetimeUtils";
import { Regex } from "helpers/regex";
import * as Yup from "yup";
import { PhoneNumberUtil } from "google-libphonenumber";

const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const phoneUtil = PhoneNumberUtil.getInstance();
export const phoneNumber = (fieldKey: string, countryCodeField?: string) =>
  Yup.mixed().test(
    fieldKey,
    `error:invalid,${toLowerCase(fieldKey)}`,
    (value, context) => {
      let countryCode;
      if (countryCodeField !== undefined) {
        countryCode = context.parent[countryCodeField];
      }
      if (!value) {
        return true;
      }
      try {
        const parsedNumber = phoneUtil.parseAndKeepRawInput(value, countryCode);
        return phoneUtil.isValidNumber(parsedNumber);
      } catch (error) {
        return false;
      }
    },
  );

// Required input validation function extending the optional one
export const requiredPhoneNumber = (
  fieldKey: string,
  countryCodeField?: string,
) =>
  phoneNumber(fieldKey, countryCodeField).required(
    `error:required,${toUpperCaseFirstWord(fieldKey)}`,
  );

export const email = Yup.string()
  .email(`error:invalid,${toLowerCase("email")}`)
  .required(`error:required,${toUpperCaseFirstWord("email")}`);

export const required = (fieldKey: string) =>
  Yup.mixed().required(`error:required,${toUpperCaseFirstWord(fieldKey)}`);

export const requiredFieldNumber = (fieldKey: string) =>
  Yup.number().required(`error:required,${toUpperCaseFirstWord(fieldKey)}`);

export const requiredFieldPositiveNumber = (fieldKey: string) =>
  Yup.number()
    .typeError("error:positive-integer")
    .positive("error:positive-integer")
    .required(`error:required,${toUpperCaseFirstWord(fieldKey)}`);

export const requiredAlphaFieldWithoutSpaces = (fieldKey: string) => {
  return Yup.string()
    .required(`error:required,${toUpperCaseFirstWord(fieldKey)}`)
    .nullable()
    .matches(/^\s*\S[\s\S]*$/, "error:blank-space")
    .matches(/^[aA-zZ\s]+$/, "error:alpha");
};

export const requiredFieldWithoutSpaces = (fieldKey: string) => {
  return Yup.string()
    .required(`error:required,${toUpperCaseFirstWord(fieldKey)}`)
    .nullable()
    .matches(/^\s*\S[\s\S]*$/, "error:blank-space");
};

export const requiredBooleanValidation = (fieldKey: string) => {
  return Yup.boolean()
    .nullable()
    .required(`error:required,${toUpperCaseFirstWord(fieldKey)}`);
};
// error:required,_AU__firstname

export const requiredArrayOfStringValidation = (fieldKey: string) => {
  return Yup.array()
    .nullable()
    .min(1, `error:required,${toUpperCaseFirstWord(fieldKey)}`)
    .test(
      "is-array",
      `error:required,${toUpperCaseFirstWord(fieldKey)}`,
      (value) => Array.isArray(value),
    )
    .required(`error:required,${toUpperCaseFirstWord(fieldKey)}`);
};

export const newPassword = Yup.string()
  .required(`error:required,${toUpperCaseFirstWord("password")}`)
  .matches(
    Regex.upperCase,
    `error:at-least, ${toLowerCase("error:upper-case")}`,
  )
  .matches(
    Regex.lowerCase,
    `error:at-least, ${toLowerCase("error:lower-case")}`,
  )
  .matches(Regex.digits, `error:at-least, ${toLowerCase("error:digit")}`)
  .matches(
    Regex.specialChar,
    `error:at-least, ${toLowerCase("error:special-char")}`,
  )
  .min(8, "error:min-password-length");

export const confirmPassword = (pwdKey: string) =>
  Yup.string()
    .required(`error:required,${toUpperCaseFirstWord("confirm-password")}`)
    .oneOf([Yup.ref(pwdKey)], "error:passwordMismatch");

// Required URL validation
export const requiredUrlValidation = (
  fieldKey: string,
  urlMsg: string = "error:invalid-url",
) => {
  return Yup.string()
    .required(`error:required,${toUpperCaseFirstWord(fieldKey)}`)
    .url(urlMsg);
};

export const phone = (fieldKey: string) =>
  Yup.string()
    .required(`error:required,${toUpperCaseFirstWord(fieldKey)}`)
    .matches(phoneRegExp, `error:invalid,${toLowerCase(fieldKey)}`);

export const image = (fieldKey: string) =>
  Yup.mixed()
    .required(`error:required,${toUpperCaseFirstWord(fieldKey)}`)
    .test("image-size-validation", "error:imageSizeInvalid", (value) => {
      if (value) {
        // return isImageSizeValid(value.size);
        return true;
      }
      return false;
    });

export const dob = (fieldKey: string) =>
  Yup.mixed()
    .required(`error:required,${toUpperCaseFirstWord(fieldKey)}`)
    .test("dob-validation", `error:ageTooSmall`, (value) => {
      return !!value && new Date(value).getTime() < getOldDate(18).getTime();
    });

export const passwordValidations = Yup.string().test(
  "passwordRulesValidation",
  "Password is invalid",
  function (value) {
    const errors = [];
    const currValue = value ?? "";
    if (currValue.length === 0) {
      errors.push(PWD_VALIDATION_RULES.REQ);
    }
    if (!Regex.length.test(currValue)) {
      errors.push(PWD_VALIDATION_RULES.LEN);
    }
    if (!Regex.upperCase.test(currValue)) {
      errors.push(PWD_VALIDATION_RULES.UC);
    }
    if (!Regex.lowerCase.test(currValue)) {
      errors.push(PWD_VALIDATION_RULES.LC);
    }
    if (!Regex.digits.test(currValue)) {
      errors.push(PWD_VALIDATION_RULES.NUM);
    }
    if (!Regex.specialChar.test(currValue)) {
      errors.push(PWD_VALIDATION_RULES.SYM);
    }

    if (errors.length) {
      return this.createError({
        message: getCombinedNewPasswordError(errors) ?? undefined,
      });
    }

    return true;
  },
);
