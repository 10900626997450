import { PERMS } from "helpers";
import Companies from "pages/company/Companies";
import CollectionDetail from "pages/content/CollectionDetail";
import Collection from "pages/content/Collection";
import Configurations from "pages/content/Configurations";
import AddContent from "pages/content/AddContent";
import Content from "pages/content/Content";
import Departments from "pages/department/Departments";
import Experts from "pages/experts/Experts";
import Positions from "pages/positions/Positions";

import OffersList from "pages/offers/OffersList";
import AddOffer from "pages/offers/UpdateOffer";
import Metric from "pages/reports/Metric";
import Ranges from "pages/reports/Ranges";
import Permissions from "pages/roles/Permissions";
import RolesList from "pages/roles/RolesList";
import Specialist from "pages/specialist/Specialist";
import TagList from "pages/tags/TagList";
import UsersList from "pages/users/users";

import ContentDetail from "pages/content/ContentDetail";
import Units from "pages/units/Units";
import Badges from "pages/badges/Badges";
import Admins from "pages/admin/Admins";
import EAPs from "pages/employee-assistance/EAPs";
import Activity from "pages/challenges";
import ChallengeRewards from "pages/challenge-rewards";
import AvatarList from "pages/avatars/AvatarList";
import Categories from "pages/categories/Categories";
import EditCompany from "pages/company/EditCompany";
import AddActivity from "pages/challenges/AddChallenge";
import PasswordChanged from "pages/auth/PasswordChanged";
import ActivityDetail from "pages/challenges/ChallengeDetail";

import AssessmentRoutes from "./RouteCollections/AssessmentRoutes";
import Buildings from "pages/buildings/Buildings";
import OnBoardingList from "pages/onboarding/OnBoardingList";
import Documentation from "pages/documentation/Documentation";
import FAQsList from "pages/faqs/FAQsList";
import DeleteRequests from "pages/users/DeleteRequests";
import DeletedAccounts from "pages/users/DeletedAccounts";
import OtpVerify from "pages/auth/OtpVerify";
import LogoutUser from "components/results/logout-user/LogoutUser";
import WellbeingCalendar from "pages/wellbeing-calendar/WellbeingCalendar";
import RewardCategories from "pages/rewards/Categories";
import Partners from "pages/rewards/Partners";
import Tiers from "pages/rewards/Tiers";
import PartnerDetails from "pages/rewards/PartnerDetails";
import TierDetails from "pages/rewards/TierDetails";


const LoggedInRoutes = [
  AssessmentRoutes.AssessmentTypesRoute,
  AssessmentRoutes.AssessmentListRoute,
  AssessmentRoutes.AssessmentCreateRoute,
  AssessmentRoutes.AssessmentCreateCheckInRoute,
  AssessmentRoutes.AssessmentLanguageRoute,
  AssessmentRoutes.AssessmentCheckInLanguageRoute,

  {
    path: "/buildings",
    element: <Buildings />,
  },
  {
    path: "/password-changed",
    element: <PasswordChanged />,
  },
  {
    path: "/company",
    element: <Companies />,
  },
  {
    path: "/edit-company",
    element: <EditCompany />,
  },
  {
    path: "/categories",
    element: <Categories />,
    permission: PERMS.GET_ALL_CATEGORIES,
  },
  {
    path: "/employees",
    element: <UsersList />,
    permission: PERMS.GET_ALL_EMPLOYEES,
  },
  {
    path: "/deleted-accounts",
    element: <DeletedAccounts />,
    permission: PERMS.GET_ALL_EMPLOYEES,
  },
  {
    path: "/delete-requests",
    element: <DeleteRequests />,
    permission: PERMS.GET_ALL_EMPLOYEES,
  },
  {
    path: "/tags",
    element: <TagList />,
    permission: PERMS.GET_ALL_TAGS,
  },
  {
    path: "/configurations",
    element: <Configurations />,
    permission: PERMS.GET_ALL_TAGS,
  },
  {
    path: "/wellbeing",
    element: <WellbeingCalendar />,
    permission: PERMS.GET_WELLBEING_CALENDER_LIST,
  },
  {
    path: "/content",
    element: <Content />,
    permission: PERMS.GET_ALL_CONTENTS,
  },
  {
    path: "/add-content",
    element: <AddContent />,
    permission: PERMS.GET_ALL_CONTENTS,
  },
  {
    path: "/content-detail",
    element: <ContentDetail />,
    permission: PERMS.GET_CONTENT,
  },
  {
    path: "/experts",
    element: <Experts />,
    permission: PERMS.GET_ALL_EXPERTS,
  },
  {
    path: "/roles",
    element: <RolesList />,
    permission: PERMS.GET_ALL_ROLES,
  },
  {
    path: "/permissions",
    element: <Permissions />,
    permission: PERMS.GET_ALL_PERMISSIONS,
  },
  {
    path: "/Positions",
    element: <Positions />,
    permission: PERMS.GET_ALL_POSITIONS,
  },
  {
    path: "/departments",
    element: <Departments />,
    permission: PERMS.GET_ALL_DEPARTMENTS,
  },
  {
    path: "/specialist",
    element: <Specialist />,
    permission: PERMS.GET_ALL_SPECIALIST,
  },
  {
    path: "/programs",
    element: <Collection />,
    permission: PERMS.GET_ALL_COLLECTION,
  },
  {
    path: "/program-detail/:id",
    element: <CollectionDetail />,
    permission: PERMS.GET_COLLECTION,
  },
  {
    path: "/challenges",
    element: <Activity />,
    permission: PERMS.GET_ALL_CHALLENGES,
  },
  {
    path: "/challenge-rewards",
    element: <ChallengeRewards />,
    permission: PERMS.GET_ALL_CONTENTS,
  },
  {
    path: "/add-challenge",
    element: <AddActivity />,
  },
  {
    path: "/challenges/challenge-detail",
    element: <ActivityDetail />,
    permission: PERMS.GET_SINGLE_CHALLENGE,
  },
  {
    path: "/offers",
    element: <OffersList />,
    permission: PERMS.GET_ALL_REWARDS,
  },
  {
    path: "/add-offer",
    element: <AddOffer />,
  },
  {
    path: "/badges",
    element: <Badges />,
    permission: PERMS.GET_ALL_ACHIEVEMENTS,
  },
  {
    path: "/admins",
    element: <Admins />,
  },
  {
    path: "/metrics",
    element: <Metric />,
    permission: PERMS.GET_ALL_METRICS,
  },
  {
    path: "/report-ranges",
    element: <Ranges />,
    permission: PERMS.GET_ALL_RANGES,
  },
  {
    path: "/units",
    element: <Units />,
    permission: PERMS.GET_ALL_MEASUREMENTS,
  },
  {
    path: "/avatars",
    element: <AvatarList />,
    permission: PERMS.GET_ALL_AVATARS,
  },
  {
    path: "/eap",
    element: <EAPs />,
    permission: PERMS.GET_EAP_LIST,
  },
  {
    path: "/onboardings",
    element: <OnBoardingList />,
  },
  {
    path: "/faqs",
    element: <FAQsList />,
    permission: PERMS.GET_ALL_AVATARS,
  },
  {
    path: "/documentation",
    element: <Documentation />,
  },
  {
    path: "/signup/:userId/:otp",
    element: <OtpVerify />,
  },
  {
    path: "/logout-to-proceed/:userId",
    element: <LogoutUser />,
  },
  {
    path:'/reward-categories',
    element: <RewardCategories/>,
    permission: PERMS.GET_REWARD_CATEGORY_LIST,
  },
  {
    path:'/partners',
    element: <Partners/>,
    permission: PERMS.GET_PARTNER_LIST,
  },
  {
    path:'/tiers',
    element: <Tiers/>,
    permission: PERMS.GET_TIER_LIST,
  },
  {
    path:'/partner-details',
    element: <PartnerDetails/>,
    permission: PERMS.GET_PARTNER_DETAILS
  },
  {
    path:'/tier-details/:id/:langCode',
    element: <TierDetails/>,
    permission: PERMS.GET_TIER_DETAILS
  }
];

export default LoggedInRoutes;
