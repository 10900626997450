import { useState, useEffect } from "react";
import CustomTable from "components/custom-table/CustomTable";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomButton from "components/custom-button/CustomButton";
import { MoreOutlined } from "@ant-design/icons";
import { DatePicker, Select, Space, Switch, Tag, Modal } from "antd";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import CustomLanguageSelector from "components/form/custom-language-selector/CustomLanguageSelector";
import { useLanguages } from "hooks";
import {
  RewardCategoryList,
  useGetRewardCategoryListQuery,
  useLazyGetRewardCategoryListQuery,
  useLazyGetRewardsCountByCategoryQuery,
} from "graphql/_generated/graphql";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { PERMS, getLanguage } from "helpers";
import moment from "moment";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import customNotification from "components/custom-notification/CustomNotification";
import { useTier } from "../hooks/useTier";
import AddRewardCategory from "./AddRewardCategory";
import CustomConfirmModal from "components/custom-confirm-modal/CustomConfirmModal";
import { getLangDir } from "helpers/getLangDir";
import { useErrorHandler } from "hooks/useErrorHandler";

interface CategoryListProps {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CategoryList: React.FunctionComponent<CategoryListProps> = (
  props,
) => {
  const { t } = useTranslation();
  const { languages } = useLanguages();

  const [langVisibility, setLangVisibility] = useState(false);
  const [langCode, setLangCode] = useState<string>();
  const [isAddLanguage, setIsAddLanguage] = useState(false);
  const [isAddCategory, setIsAddCategory] = useState(false);
  const [editId, setEditId] = useState<string | null>(null);
  const [isDelete, setIsDelete] = useState(false);
  const [categoriesData, setCategoriesData] = useState<MappedCategoryList>();
  const [rewardCount, setRewardCount] = useState<number>();
  const [isActivated, setIsActivated] = useState(false);

  const [columnsData, setColumnsData] = useState<RewardCategoryList>();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { isFetching } = useGetRewardCategoryListQuery();
  const [getRewardCategoryListFun] = useLazyGetRewardCategoryListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const [getRewardCountFun] = useLazyGetRewardsCountByCategoryQuery();
  const { handleError } = useErrorHandler();

  const {
    handleToggleRewardCategory,
    handleDeleteRewardCategory,
    isLoading: isLoadingTier,
  } = useTier();

  useEffect(() => {
    fetchDataFromApi(
      getRewardCategoryListFun,
      setColumnsData,
      filtersData,
    ).catch((e) => handleError(e));
  }, [isFetching]);

  const submitDelete = async () => {
    try {
      await handleDeleteRewardCategory(editId ?? "");
      customNotification("success", t("deleted-reward-category-successfully"));
      setIsDelete(false);
      setEditId(null);
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const getRewardCount = async (id: string) => {
    try {
      const res = await getRewardCountFun({ categoryId: id }).unwrap();
      return res.getRewardsCountByCategory;
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenu = ({ key, id, record }: any) => {
    if (key === "add-new-language") {
      setCategoriesData(record);
      setLangVisibility(true);
      setEditId(id);
      setIsAddLanguage(true);
    }
    if (key === "edit-reward-category") {
      setIsAddLanguage(false);
      setEditId(id);
      setIsAddCategory(true);
      setLangCode(undefined);
    }
    if (key === "edit-language") {
      setLangCode(record.language);
      setIsAddLanguage(true);
      setEditId(id);
      setIsAddCategory(true);
    }
    if (key === "delete-reward-category") {
      const res = getRewardCount(id);
      res.then((data) => {
        setRewardCount(data);
      });
      setIsDelete(true);
      setEditId(id);
    }
  };

  const handleSubmit = (values: any, actions: any) => {
    setLangCode(values?.language);
    setLangVisibility(false);
    setIsAddCategory(true);
    actions.resetForm();
  };

  const mappedColumnsData = columnsData?.data.map((item) => {
    return (
      {
        ...item,
        hasChildren: true,
        children:
          item?.translations && Object.keys?.(item?.translations)
            ? Object.keys?.(item?.translations)?.map((record) => {
                return {
                  ...item,
                  name: item?.translations?.[record]?.name,
                  language: record,
                };
              })
            : null,
      } ?? []
    );
  });

  type MappedCategoryList = NonNullable<typeof mappedColumnsData>[0];

  const handleToggleCategory = (record: MappedCategoryList) => {
    setIsActivated(true);
    setCategoriesData(record);
  };

  const handleToggleCategoryActivation = async () => {
    try {
      const isCategoryActive = await handleToggleRewardCategory(
        categoriesData?.id ?? "",
      );
      customNotification(
        "success",
        isCategoryActive
          ? t("reward-category-activation-successfully")
          : t("reward-category-deactivate-successfully"),
      );
      setIsActivated(false);
      setCategoriesData(undefined);
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const columns: ColumnsType<MappedCategoryList> = [
    {
      title: `${t("id")}`,
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, index) =>
        record.hasChildren ? (currentPage - 1) * pageSize + index + 1 : null,
    },
    {
      title: `${t("category-title")}`,
      key: "name",
      dataIndex: "name",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => {
        return (
          <div className={record?.hasChildren ? "parent-row" : "child-row"}>
            <Space>
              <CustomAvatar shape="square" imgSrc={record?.image ?? ""} />
              <span className={`cut-text-${getLangDir(record.name)}`}>
                {record.name}
              </span>
            </Space>
          </div>
        );
      },
    },
    {
      title: `${t("language")}`,
      key: "language",
      dataIndex: "language",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => getLanguage(record.language),
    },
    {
      title: `${t("creation-date")}`,
      key: "createdAt",
      dataIndex: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) => moment(createdAt).format("MMMM Do YYYY"),
    },
    {
      title: `${t("updated-at")}`,
      key: "updatedAt",
      dataIndex: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) => moment(updatedAt).format("MMMM Do YYYY"),
    },
    {
      title: t("status-label"),
      dataIndex: "isActive",
      key: "isActive",
      align: "center" as "center",

      render: (isActive: boolean) =>
        isActive ? (
          <Tag className="active-tag"> &bull; {`${t("active")}`}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      align: "center" as "center",
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      render: (_text, record, _index) => (
        <>
          <CheckPermissions
            permission={PERMS.TOGGLE_REWARD_CATEGORY_ACTIVATION}
          >
            <Switch
              className="custom-switch"
              defaultChecked
              checked={record.isActive}
              onClick={() => handleToggleCategory(record)}
            />
          </CheckPermissions>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "Actions",
      align: "center",
      render: (id: string, record) => {
        const mainActions = [
          {
            key: "edit-reward-category",
            label: t("edit-category"),
          },
          {
            key: "add-new-language",
            label: t("add-new-language"),
          },
          {
            key: "delete-reward-category",
            label: t("delete-category"),
          },
        ];
        const langActions = [{ key: "edit-language", label: "Edit Language" }];
        return (
          <CustomDropDown
            menu={{
              items: record?.hasChildren ? mainActions : langActions,
              onClick: ({ domEvent, key, keyPath }) =>
                handleMenu({ domEvent, key, keyPath, id, record }),
            }}
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </CustomDropDown>
        );
      },
    },
  ];

  return (
    <>
      <CustomTable
        permission={PERMS.ADD_REWARD_CATEGORY}
        onClick={() => props?.setVisible(true)}
        headerTitle={t("reward-categories")}
        btnText={t("add-reward-category")}
        expandIconColumnIndex={1}
        dataSource={mappedColumnsData}
        columns={columns}
        filters={{
          handleCallback: async (filtersData: any) => {
            await fetchDataFromApi(
              getRewardCategoryListFun,
              setColumnsData,
              filtersData,
            );
            setPageSize(filtersData?.pagination?.offset?.pageSize);
            setCurrentPage(filtersData?.pagination?.offset?.page);
            setFiltersData(filtersData);
            setFiltersData(filtersData);
          },
          search: true,
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
              placeholder: t("creation"),
            },
            {
              applyAs: "and",
              key: "updatedAt",
              comparison: "Range",
              placeholder: t("modified"),
            },
          ],
          manualFilters: [
            {
              key: "language",
              value: "language",
              comparison: "Equal",
              applyAs: "and",
              render: (onChangeCallback) => (
                <Select
                  options={languages}
                  fieldNames={{ label: "label", value: "value" }}
                  className="filter-select"
                  onChange={onChangeCallback}
                  allowClear
                  placeholder={t("language-filter")}
                />
              ),
            },
          ],
        }}
        rowKey={(record) =>
          record.hasChildren ? record.id : record.id + record.name
        }
        totalItems={columnsData?.metadata?.total}
        loading={isLoading}
        className="custom-table"
      />
      {langVisibility && (
        <CustomLanguageSelector
          visible={langVisibility}
          setVisible={setLangVisibility}
          onSubmit={handleSubmit}
          data={categoriesData}
          label={t("category")}
        />
      )}
      {isAddCategory && (
        <AddRewardCategory
          visible={isAddCategory}
          setVisible={setIsAddCategory}
          editId={editId}
          setEditId={setEditId}
          langCode={langCode}
          isAddLanguage={isAddLanguage}
        />
      )}
      {isDelete && (
        <CustomConfirmModal
          visible={isDelete}
          setVisible={setIsDelete}
          desc={t("sure-to-delete-reward-category", { rewardCount })}
          onSubmit={submitDelete}
          loading={isLoadingTier}
        />
      )}
      {isActivated && (
        <CustomConfirmModal
          visible={isActivated}
          setVisible={setIsActivated}
          desc={
            categoriesData?.isActive
              ? t("confirm-deactivate-reward-category")
              : t("confirm-activate-reward-category")
          }
          loading={isLoadingTier}
          onSubmit={handleToggleCategoryActivation}
        />
      )}
    </>
  );
};
