import { useState, useEffect } from "react";
import { Space, Switch, TreeNodeProps } from "antd";
import { Form, Formik } from "formik";
import { useGetCategoryListQuery } from "graphql/_generated/graphql";
import { useTranslation } from "react-i18next";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import { errorFormat } from "helpers";
import { useCategory } from "../useCategory";
import TranslationFieldArr from "components/translation-field-array/TranslationFieldArr";
import { categorySchema } from "validations/schema/categorySchema";
import styles from "./AddCategory.module.scss";
import { useErrorHandler } from "hooks/useErrorHandler";

type TreeNode = {
  key: string;
  id: string;
  title: string;
  value: string;
};

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
  categoriesData: Array<TreeNode>;
  reFetchCategory: () => void;
};

const AddCategory = ({
  setVisible,
  visible,
  editId,
  setEditId,
  categoriesData,
  reFetchCategory,
}: Props) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const [child, setChild] = useState<boolean>(false);
  const { refetch } = useGetCategoryListQuery();

  const {
    isLoading,
    handleAddCategory,
    handleFetchEditCategoryData,
    handleUpdateCategory,
  } = useCategory();

  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    icon: "",
    parent: null,
    translations: [],
    hasChildren: null,
  });

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
    setChild(false);
  };

  const handleChange = (checked: boolean) => {
    setChild(checked);
  };

  //setting the previous values to initial values object when click on the edit button
  useEffect(() => {
    if (editId) {
      handleFetchEditCategoryData(editId).then((data: any) => {
        setInitialValues({
          name: data.name,
          icon: data.icon,
          parent: data.parent,
          translations: data.translations,
        });
        if (data.parent) {
          setChild(true);
        }
      });
    } else {
      setInitialValues({
        name: "",
        icon: null,
        parent: null,
        translations: [],
      });
    }
  }, [editId]);

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={t(editId ? "edit-category" : "add-category")}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={categorySchema}
        onSubmit={async (values, { resetForm }) => {
          if (!child) {
            values.parent = null;
          }

          if (!editId) {
            try {
              await handleAddCategory({ create: values });
              refetch();
              reFetchCategory();
              resetForm();
              setVisible(false);
              setChild(false);
              customNotification("success", t("category-added-successfully"));
            } catch (error) {
              handleError(error as string | object);
            }
          } else {
            try {
              await handleUpdateCategory({ id: editId, updateObj: values });
              customNotification("success", t("category-updated-successfully"));
              refetch();
              reFetchCategory();
              resetForm();
              setVisible(false);
              setEditId(null);
              setChild(false);
            } catch (error) {
              handleError(error as string | object);
            }
          }
        }}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            <CustomUploadFile
              name="icon"
              setFieldValue={setFieldValue}
              imgUrl={values.icon}
              accept="image/*"
            />
            <CustomTextField
              label={t("category-name")}
              name="name"
              placeholder={t("category-name-placeholder")}
            />
            <div className={styles["form-control"]}>
              <Space>
                <Switch
                  id="parent"
                  className="custom-switch"
                  onChange={handleChange}
                  checked={child}
                />
                <label htmlFor="parent">{t("is-child")}</label>
              </Space>
            </div>
            <div className={styles["form-control"]}>
              {child && (
                <CustomTreeSelect
                  showArrow
                  treeNodeFilterProp="name"
                  treeLine={true && { showLeafIcon: false }}
                  value={values.parent}
                  label={t("select-category")}
                  fieldNames={{
                    label: "name",
                    value: "id",
                  }}
                  className="form-select"
                  name="parent"
                  placeholder={t("select-category-placeholder")}
                  treeData={categoriesData}
                  onChange={(values) => setFieldValue("parent", values)}
                />
              )}
            </div>
            <TranslationFieldArr values={values} attribute="name" />
            <ModalFooter
              saveText={t("save-category")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddCategory;
