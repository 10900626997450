import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { useState } from "react";
import styles from "features/rewards//css/TiersList.module.scss";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import { PERMS } from "helpers/constants";
import CustomButton from "components/custom-button/CustomButton";
import { useTranslation } from "react-i18next";
import { PlusCircleOutlined } from "@ant-design/icons";
import TiersList from "features/rewards/tiers/TiersList";
import AddTier from "features/rewards/tiers/AddTier";
import CustomLanguageSelector from "components/form/custom-language-selector/CustomLanguageSelector";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";

interface TiersProps {}

const Tiers: React.FunctionComponent<TiersProps> = (props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [isAddTier, setIsAddTier] = useState(false);
  const [langCode, setLangCode] = useState<string>();

  const handleSubmit = (values: any, actions: any) => {
    actions.resetForm();
    setLangCode(values.language);
    setVisible(false);
    setIsAddTier(true);
  };

  return (
    <DashboardLayout openKey={["Rewards"]}>
      <Breadcrumbs
        data={[
          {
            label: t("rewards"),
          },
          {
            label: t("tiers"),
          },
        ]}
      />
      <TiersList setVisible={setVisible} />
      {visible && (
        <CustomLanguageSelector
          visible={visible}
          setVisible={setVisible}
          onSubmit={handleSubmit}
          title={`${t("tier")} ${t("main-language")}`}
          label={t("tier")}
          saveText={t("add-tier")}
        />
      )}
      {isAddTier && (
        <AddTier
          visible={isAddTier}
          setVisible={setIsAddTier}
          langCode={langCode ?? ""}
        />
      )}
    </DashboardLayout>
  );
};

export default Tiers;
