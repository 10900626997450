import { useState } from "react";
import styles from "features/rewards/css/UserStats.module.scss";
import { useTranslation } from "react-i18next";
import { List } from "antd";
import { useGetUsersListByTierQuery } from "graphql/_generated/graphql";
import UserItem from "./UserItem";
import UserStatsDetails from "./UserStatsDetails";
interface UserStatsProps {
  tierId: string;
}

const UserStats: React.FunctionComponent<UserStatsProps> = ({ tierId }) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const { data, isLoading } = useGetUsersListByTierQuery({
    tierId: tierId,
    pagination: {
      limit: 6,
    },
  });

  return (
    <div>
      <div className={styles["header"]}>
        <div className={styles["title"]}>{`${t("users")} (${
          data?.getUsersListByTier.metadata.total ?? 0
        })`}</div>
        <div onClick={() => setVisible(true)} className={styles["see-btn"]}>
          {t("see-more")}
        </div>
      </div>
      <div className={styles["desc-container"]}>
        <List
          dataSource={data?.getUsersListByTier.data}
          loading={isLoading}
          renderItem={(item, index) => (
            <UserItem
              id={index + 1}
              image={item.image ?? ""}
              name={item.userName}
              points={item.points}
              companyName={item.companyName}
            />
          )}
        />
      </div>
      {visible && (
        <UserStatsDetails
          tierId={tierId}
          visible={visible}
          setVisible={setVisible}
        />
      )}
    </div>
  );
};

export default UserStats;
