import { Spin } from "antd";
import CustomCard from "components/custom-card/CustomCard";
import {
  AssessmentTypes,
  UserFilters,
  useGetConfigPillarsQuery,
} from "graphql/_generated/graphql";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../CompanyDashboard.module.scss";
import { BarChart } from "../../charts/bar/BarChart";
import { useGetPilarStats } from "../../hooks/useGetPilarStats";
import { InfoTextPopup } from "../InfoTextPopup";
import { NoData } from "../NoData";
import { InfoTextProps } from "../organization-metrices/types";

interface OrganizationScoresProps extends InfoTextProps {
  userFilters: UserFilters;
  visible: boolean;
}

interface PillarStatsDataProps {
  name: string;
  value: number;
}

interface PillarsDataContentProps {
  pillars: PillarStatsDataProps[] | [];
  isDataConcealed: boolean;
}

export const OrganizationScores: FunctionComponent<OrganizationScoresProps> = ({
  userFilters,
  visible,
  selectedInfoText,
  setSelectedInfoText,
}) => {
  const { t } = useTranslation();

  const [pillarStatsData, setPillarStatsData] =
    useState<PillarsDataContentProps>({
      isDataConcealed: false,
      pillars: [],
    });
  const { handleGetPillarStats, isLoading } = useGetPilarStats();
  const { refetch: fetchPillars } = useGetConfigPillarsQuery();

  useEffect(() => {
    getPillarStats();
  }, [userFilters]);

  const getPillarStats = async () => {
    const pillarsData = await fetchPillars();

    handleGetPillarStats({
      userFilters,
    }).then((data) => {
      const mappedPillars = data?.pillars?.length
        ? data?.pillars.map((item) => {
            const pillarName =
              pillarsData?.data?.getConfigPillars?.options?.find(
                (pillar) => pillar.id === item.pillarId,
              )?.label || "";
            return {
              name: pillarName,
              value: parseInt(
                ((item.answersScore / item.questionsScore) * 100).toFixed(0),
              ),
              color: organizationScoreBarColors[pillarName] || "#fff",
            };
          })
        : [];
      const sortedPillars = mappedPillars?.sort(
        (a: PillarStatsDataProps, b: PillarStatsDataProps) => {
          return a?.value - b?.value;
        },
      );
      setPillarStatsData({ ...data, pillars: sortedPillars });
    });
  };

  const handleRefresh = () => {
    getPillarStats();
  };

  const organizationScoreBarColors: { [key: string]: string } = {
    Physical: "#FFC278",
    Emotional: "#7573E1",
    Nutritional: "#00B9B9",
    Social: "#F45A7C",
    Financial: "#16A0F0",
    Professional: "#FF7D69",
  };

  return (
    <CustomCard
      bordered={false}
      className={`${styles["section-card"]} ${styles["card-height-for-data"]}`}
    >
      <div className={styles["assessment-container"]}>
        <div className={styles["section-item-container"]}>
          <div className={styles["item-header"]}>
            {t("org-viwell-assessment-score")}
          </div>
          <div>
            <InfoTextPopup
              items={[
                t("gain-organisation-insight"),
                t("prioritise-pillar-scores"),
                t("enhance-strategic-decisions"),
              ]}
              visible={
                visible ||
                selectedInfoText !== AssessmentTypes.OnBoardingAssessment
              }
              handleSelectedInfoText={(reset?: boolean) =>
                setSelectedInfoText(
                  reset ? "" : AssessmentTypes.OnBoardingAssessment,
                )
              }
            />
          </div>
        </div>
        {!isLoading &&
        !pillarStatsData?.isDataConcealed &&
        pillarStatsData?.pillars.length ? (
          <>
            <div className={styles["score-barchart-container"]}>
              <BarChart
                barChartConfig={{
                  barSize: 32,
                  data: pillarStatsData.pillars,
                  isBarHasDifferentColors: true,
                  xData: {
                    key: "name",
                    isEmojiBar: false,
                    width: 80,
                    customXTick: 40,
                  },
                  yData: {
                    key: "value",
                  },
                  customBarBG: { x: 57, height: 185 },
                }}
              />
            </div>
            <div className={styles["org-low-score-insight"]}>
              {`${t("organisation-lowest-score")} ${
                pillarStatsData?.pillars?.[0].name
              } ${t("pillar")}`}
            </div>
          </>
        ) : isLoading ? (
          <div className={styles["loading-container"]}>
            <Spin spinning={isLoading} />
          </div>
        ) : (
          <NoData
            handleClick={handleRefresh}
            btnText={t("refresh")}
            title={
              pillarStatsData?.isDataConcealed
                ? t("data-concealed-for-privacy")
                : t("no-enough-data")
            }
          />
        )}
      </div>
    </CustomCard>
  );
};
