import {
  MutationAddNewMeasurementArgs,
  useAddNewMeasurementMutation,
 
} from "graphql/_generated/graphql";

export const useAddMeasurement = () => {
  const [addNewMeasurementFun, { isLoading }] = useAddNewMeasurementMutation();

  //this function is responsible for adding the Unit
  const handleAddNewMeasurement = async (
    values: MutationAddNewMeasurementArgs
  ) => {
    try {
      await addNewMeasurementFun({ create: values.create }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading,
    handleAddNewMeasurement,
  };
};
