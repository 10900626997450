import { useEffect, useState } from "react";

type useLanguages = () => {
  languages: { label: string; value: string }[];
  languagesWithOutEn: { label: string; value: string }[];
};

export const useLanguages: useLanguages = () => {
  const [languages, setLanguages] = useState([]);
  const [languagesWithOutEn, setLanguagesWithOutEn] = useState([]);

  const getAppLanguages = async () => {
    const res = await fetch(`${process.env.PUBLIC_URL}/locales/languages.json`);
    // const data = await res.json();
    const data = await res.json();
    const [direction, ...rest] = data;

    const withOutEnData = data.filter((item: { value: string }) => {
      return item.value !== "en";
    });

    setLanguages(data);
    setLanguagesWithOutEn(withOutEnData);
  };

  useEffect(() => {
    getAppLanguages();
  }, []);

  return { languages, languagesWithOutEn };
};
