import { Space } from "antd";
import { customNotification } from "components/custom-notification/CustomNotification";
import { errorFormat, focusOnErrorField } from "helpers";

import CustomButton from "components/custom-button/CustomButton";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addChallengeSchema } from "validations/schema";
import styles from "features/challenge/css/AddActivity.module.scss";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import ChallengeDescription from "features/challenge/add-challenge/ChallengeDescription";
import ChallengeOptions from "features/challenge/add-challenge/ChallengeOptions";
import ChallengeValidations from "features/challenge/add-challenge/ChallengeValidations";
import RewardDetails from "features/challenge/add-challenge/RewardDetails";
import ChallengeImage from "features/challenge/add-challenge/ChallengeImage";
import { useChallenge } from "features/challenge/hooks/useChallenge";
import { GoalFilterType, Trackable } from "graphql/_generated/graphql";
import { useTranslation } from "react-i18next";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { useErrorHandler } from "hooks/useErrorHandler";

const AddChallenge = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const [oldRecord, setOldRecord] = useState<any>(null);

  const [isViwellChallenge, setIsViwellChallenge] = useState(true);
  const { isSuperAdmin, isViwellAdmin, isClient } = useLoggedInUser();

  const [enableValidations, setEnableValidations] = useState({
    isMinAge: false,
    isMaxAge: false,
    isGender: false,
    isDepartment: false,
    isPosition: false,
    isChildCompany: false,
  });

  const {
    isLoading,
    handleAddNewChallenge,
    handleFetchChallengeData,
    handleUpdateChallenge,
  } = useChallenge();

  const { editId, language, isAddLanguage, languageKey, isCloned, isViewOnly } =
    location.state;
  const [initialValues, setInitialValues] = useState<any>({
    validations: {
      age: {
        max: undefined,
        min: undefined,
      },
      gender: undefined,
      departmentIds: undefined,
      positionIds: undefined,
    },
    companies: undefined,
    categoryId: null,
    completePoints: undefined,
    description: null,
    duration: null,
    expiryDate: undefined,
    startDate: undefined,
    goal: null,
    goalType: null,
    image: null,
    pillarId: null,
    rankPoints: [],
    rewardDetails: null,
    rules: null,
    tagIds: null,
    title: null,
    type: null,
    duration_type: "1",
    translations: undefined,
  });

  //this function is getting the data from api and show in the form
  const showPrevData = async () => {
    if (editId) {
      handleFetchChallengeData(editId).then(async (data: any) => {
        try {
          const translationData = data?.translations?.[languageKey];
          setOldRecord(data);
          setIsViwellChallenge(data?.isViwellChallenge);
          setEnableValidations({
            isMinAge: data?.validations?.age?.min ? true : false,
            isMaxAge: data?.validations?.age?.max ? true : false,
            isGender: data?.validations?.gender ? true : false,
            isDepartment: data?.validations?.departmentIds?.length
              ? true
              : false,
            isPosition: data?.validations?.positionIds?.length ? true : false,
            isChildCompany:
              isClient && data?.companies?.slice(1).length ? true : false,
          });
          let goalType = null;
          if (
            [Trackable.Collection, Trackable.Content].includes(data?.goalType)
          ) {
            if (data?.goalFilter?.value) {
              goalType = `${data?.goalType}-${data?.goalFilter?.value}`;
            } else {
              goalType = `${data?.goalType}-All`;
            }
          } else {
            goalType = data?.goalType;
          }
          setInitialValues({
            validations: data?.validations,
            companies: isClient ? data?.companies.slice(1) : data?.companies,
            categoryId: data?.category?.id,
            completePoints: data?.completePoints,
            description: isAddLanguage
              ? translationData?.description
              : data?.description,
            duration: data?.duration,
            expiryDate: isCloned ? undefined : data?.expiryDate,
            startDate: isCloned ? undefined : data?.startDate,
            goal: data?.goal,
            goalType,
            image: data?.image,
            pillarId: data?.pillar?.id,
            rankPoints: data?.rankPoints,
            rewardDetails: isAddLanguage
              ? translationData?.rewardDetails
              : data?.rewardDetails,
            rules: isAddLanguage ? translationData?.rules : data?.rules,
            tagIds: data?.tags?.map((item: any) => item.id),
            title: isAddLanguage ? translationData?.title : data?.title,
            type: data?.type,
            duration_type: "1",
            translations: data?.translations,
          });
        } catch (error) {
          console.log(error);
        }
      });
    }
  };

  useEffect(() => {
    //this function will fetch the prev data and show in the form if edit id exists
    showPrevData();
  }, []);

  return (
    <DashboardLayout openKey={["Activity"]}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={addChallengeSchema({
          isSuperAdmin,
          isViwellAdmin,
          isAddLanguage,
          isViwellChallenge,
          translations: t,
          language,
        })}
        onSubmit={async (values, { resetForm }) => {
          let challengeValidations: any = {
            age: undefined,
            gender: undefined,
            departmentIds: undefined,
            positionIds: undefined,
          };
          let companies = undefined;
          let goalType = values?.goalType,
            goalFilter = undefined;
          if (enableValidations?.isMinAge || enableValidations?.isMaxAge) {
            challengeValidations.age = {
              min: enableValidations?.isMinAge
                ? values?.validations?.age?.min
                : undefined,
              max: enableValidations?.isMaxAge
                ? values?.validations?.age?.max
                : undefined,
            };
          }
          if (enableValidations?.isGender) {
            challengeValidations.gender = values?.validations?.gender;
          }
          if (enableValidations?.isDepartment) {
            challengeValidations.departmentIds =
              values?.validations?.departmentIds;
          }
          if (enableValidations?.isPosition) {
            challengeValidations.positionIds = values?.validations?.positionIds;
          }
          if (values?.goalType?.includes(Trackable.Collection)) {
            const goalValue = values?.goalType.split("-")[1];
            if (goalValue !== "All") {
              goalFilter = {
                type: GoalFilterType.CollectionType,
                value: goalValue,
              };
            }
            goalType = Trackable.Collection;
          } else if (values?.goalType?.includes(Trackable.Content)) {
            const goalValue = values?.goalType.split("-")[1];
            if (goalValue !== "All") {
              goalFilter = {
                type: GoalFilterType.ContentType,
                value: goalValue,
              };
            }
            goalType = Trackable.Content;
          } else {
            goalFilter = null;
          }
          if (
            (!isViwellChallenge && !isClient) ||
            (isClient && enableValidations?.isChildCompany)
          ) {
            companies = values?.companies;
          }
          const requestData = {
            duration: values.duration * values.duration_type,
            duration_type: undefined,
            companies,
            validations: challengeValidations,
            goalType,
            goalFilter,
          };
          if (!editId || isCloned) {
            try {
              await handleAddNewChallenge({
                create: {
                  ...values,
                  ...requestData,
                  language,
                },
              });
              resetForm();
              customNotification("success", t("challenge-added-successfully"));
              navigate("/challenges");
            } catch (error) {
              handleError(error as string | object);
            }
          } else {
            try {
              if (isAddLanguage) {
                await handleUpdateChallenge(editId, {
                  translations: {
                    [languageKey]: {
                      title: values.title,
                      description: values.description,
                      rules: values.rules,
                      rewardDetails: values.rewardDetails,
                    },
                  },
                });
                customNotification(
                  "success",
                  t("translation-added-updated-successfully"),
                );
              } else {
                await handleUpdateChallenge(editId, {
                  ...values,
                  ...requestData,
                });
                customNotification(
                  "success",
                  t("challenge-updated-successfully"),
                );
              }
              resetForm();
              navigate("/challenges");
            } catch (error) {
              handleError(error as string | object);
            }
          }
        }}
      >
        {({ values, setFieldValue, errors, isSubmitting, isValid }) => {
          focusOnErrorField({ errors, isSubmitting, isValid });
          return (
            <Form>
              <Breadcrumbs
                data={[
                  {
                    label: t("activities"),
                  },
                  {
                    label: t("challenges"),
                  },
                  ...(isViewOnly
                    ? [
                        {
                          label: `Challenge Details - ${language}`,
                        },
                        {
                          label: `View Challenge - ${language}`,
                        },
                      ]
                    : [
                        {
                          label: `${
                            editId ? "Edit" : "Add New"
                          } Challenge - ${language}`,
                        },
                      ]),
                ]}
              />
              <div className={styles["btn-div"]}>
                <h6 className="header__row--xtitle">{`${
                  isViewOnly ? "View" : editId ? "Edit" : "Add New"
                } Challenge - ${language}`}</h6>

                <Space>
                  <CustomButton
                    className="assessment-create-header-cancel link-text-btn"
                    onClick={() => navigate("/challenges#persist")}
                  >
                    {isViewOnly ? "Close" : t("common.cancel")}
                  </CustomButton>

                  {isViewOnly ? null : (
                    <CustomButton
                      loading={isLoading}
                      className="primary-btn"
                      htmlType="submit"
                    >
                      <span>{`Save ${isAddLanguage ? "" : "Challenge"}`}</span>
                    </CustomButton>
                  )}
                </Space>
              </div>
              <ChallengeDescription
                disabled={isViewOnly}
                isAddLanguage={isAddLanguage}
                setFieldValue={setFieldValue}
                oldRecord={oldRecord}
              />
              <ChallengeOptions
                disabled={isAddLanguage || isViewOnly}
                values={values}
                setFieldValue={setFieldValue}
                isViwellChallenge={isViwellChallenge}
                setIsViwellChallenge={setIsViwellChallenge}
              />
              <ChallengeValidations
                disabled={isAddLanguage || isViewOnly}
                setFieldValue={setFieldValue}
                values={values}
                enableValidations={enableValidations}
                setEnableValidations={setEnableValidations}
              />
              <RewardDetails
                disabled={isViewOnly || isAddLanguage}
                isAddLanguage={isAddLanguage}
                values={values}
                setFieldValue={setFieldValue}
              />
              <ChallengeImage
                disabled={isAddLanguage || isViewOnly}
                setFieldValue={setFieldValue}
                values={values}
              />
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
};
export default AddChallenge;
