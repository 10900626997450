// navigationSlice.ts

import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { useLocation } from "react-router-dom";
import { resetStore } from "./globalActions";

interface NavigationState {
  pagination: {
    [key: string]: {
      offset: {
        page: number;
        pageSize: number;
      };
    };
  };
  filters: any;
  search: {
    [key: string]: {} | undefined;
  };
}

const initialState: NavigationState = {
  pagination: {},
  filters: {},
  search: {},
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<any>) => {
      const { filters, location } = action.payload;
      const routeState = state.filters[location.pathname] || {};

      state.filters[location.pathname] = {
        ...routeState,
        ...filters,
      };
    },
    setSearch: (state, action: PayloadAction<any>) => {
      const { search, location } = action.payload;
      const routeState = state.search[location.pathname] || {};

      state.search[location.pathname] = {
        ...routeState,
        ...{
          search,
        },
      };
    },
    setPagination: (
      state,
      action: PayloadAction<{
        current: number;
        pageSize: number;
        location: any;
      }>,
    ) => {
      const { current, pageSize, location } = action.payload;
      const routeState = state.pagination[location.pathname];

      state.pagination[location.pathname] = {
        ...routeState,
        offset: { page: current, pageSize },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore, () => {
      return initialState;
    });
  },
});

export const { setPagination, setFilters, setSearch } = navigationSlice.actions;

export default navigationSlice.reducer;
