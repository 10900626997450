import {
  InviteEmployeeInBulkMutationVariables,
  useInviteEmployeeInBulkMutation,
} from "graphql/_generated/graphql";
import moment from "moment";

//function types
export type TypeInviteMultipleEmployees = (
  values: InviteEmployeeInBulkMutationVariables,
) => Promise<any>;

//types
type TypeUseInviteMultipleUsers = () => {
  isLoading: boolean;
  inviteMultipleEmployees: TypeInviteMultipleEmployees;
};

const removeUnnecessaryExtraSpaces = (text: string) =>
  text?.replace("\\s+", " ").trim();

export const mapUsersContents = (users: any) => {
  return users?.map((item: any) => {
    const {
      Address,
      Building_Name,
      City,
      Country,
      Date_of_Birth,
      Department_Name,
      Email_address,
      Employee_Position,
      First_Name,
      Gender,
      Last_Name,
      Mobile_number,
    } = item;
    return {
      address: {
        address: removeUnnecessaryExtraSpaces(Address),
        city: removeUnnecessaryExtraSpaces(City),
        country: removeUnnecessaryExtraSpaces(Country),
      },
      building: removeUnnecessaryExtraSpaces(Building_Name),
      department: removeUnnecessaryExtraSpaces(Department_Name),
      email: removeUnnecessaryExtraSpaces(Email_address),
      firstName: removeUnnecessaryExtraSpaces(First_Name),
      gender: Gender,
      lastName: removeUnnecessaryExtraSpaces(Last_Name),
      phoneNumber: Mobile_number,
      position: removeUnnecessaryExtraSpaces(Employee_Position),
      dob: moment(Date_of_Birth, "DD/MM/YYYY").format("YYYY/MM/DD"),
    };
  });
};

export const showBulkUploadPreviewDetails = (users: any) => {
  const userslists = mapUsersContents(users);
  return userslists.reduce(
    (uniqueKeys: any, item: any) => {
      const filterUniqueKeys = { ...uniqueKeys };
      if (
        !filterUniqueKeys?.departments?.some(
          (e: any) => e.department == item.department,
        )
      ) {
        filterUniqueKeys?.departments?.push(item);
      }
      if (
        !filterUniqueKeys?.cities?.some(
          (e: any) => e.address.city == item.address.city,
        )
      ) {
        filterUniqueKeys?.cities?.push(item);
      }
      if (
        !filterUniqueKeys?.countries?.some(
          (e: any) => e.address.country == item.address.country,
        )
      ) {
        filterUniqueKeys?.countries?.push(item);
      }
      if (
        !filterUniqueKeys?.addresses?.some(
          (e: any) => e.address.address == item.address.address,
        )
      ) {
        filterUniqueKeys?.addresses?.push(item);
      }
      if (
        !filterUniqueKeys?.positions?.some(
          (e: any) =>
            e.position == item.position && e.department == item.department,
        )
      ) {
        filterUniqueKeys?.positions?.push(item);
      }
      if (
        !filterUniqueKeys?.buildings?.some(
          (e: any) =>
            e.address.address == item.address.address &&
            e.address.country == item.address.country &&
            e.address.city == item.address.city &&
            e.building == item.building,
        )
      ) {
        filterUniqueKeys?.buildings?.push(item);
      }
      return filterUniqueKeys;
    },
    {
      employees: userslists,
      departments: [],
      positions: [],
      buildings: [],
      cities: [],
      countries: [],
      addresses: [],
    },
  );
};

export const useInviteMultipleUsers: TypeUseInviteMultipleUsers = () => {
  const [inviteMultipleEmployeesFun, { isLoading }] =
    useInviteEmployeeInBulkMutation();

  //this function is responsible for inviting employees in bulk
  const inviteMultipleEmployees = async (values: any) => {
    try {
      const res: any = await inviteMultipleEmployeesFun(values).unwrap();
      return res;
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading,
    inviteMultipleEmployees,
  };
};
