import { MoreOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import CustomButton from "components/custom-button/CustomButton";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomMenu from "components/custom-menu/CustomMenu";
import { LineChart } from "features/company-dashboard/charts/line/LineChart";
import { useGetOrgMetric } from "features/company-dashboard/hooks/useGetOrgMetric";
import AddEditOrgMetric from "features/company-dashboard/modals/AddEditOrgMetric";
import ShowOrgMetricValues from "features/company-dashboard/modals/ShowOrgMetricValues";
import { WidgetExtraProps } from "features/company-dashboard/types";
import {
  formatMetricData,
  getValueUnit,
} from "features/company-dashboard/utils";
import {
  OrgMetricDetails,
  OrgMetricGraphDetails,
} from "graphql/_generated/graphql";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomCard from "../../../../components/custom-card/CustomCard";
import styles from "../../CompanyDashboard.module.scss";
import { InfoTextPopup } from "../InfoTextPopup";
import { NoData } from "../NoData";
import { IMetricValuesProps, MetricProps, SelectedMetricProps } from "./types";

export const Metric: FC<MetricProps & WidgetExtraProps> = ({
  item,
  timeFilter,
  advanceFilterVisible,
  setSelectedInfoText,
  selectedInfoText,
  companyRegisteredDate,
}) => {
  const { t } = useTranslation();
  const [metricData, setMetricData] = useState<Array<IMetricValuesProps> | []>(
    [],
  );
  const [selectedYear, setSelectedYear] = useState<string>(
    moment().format("YYYY"),
  );

  const { handleGetOrgMetric, isLoading } = useGetOrgMetric();

  const [visible, setVisible] = useState<boolean>(false);
  const [showValuesVisible, setShowValuesVisible] = useState<boolean>(false);
  const [selectedMetric, setSelectedMetric] = useState<
    SelectedMetricProps | undefined
  >(undefined);
  const [insightData, setInsightData] = useState<string>("");

  useEffect(() => {
    getOrgMetricData();
  }, []);

  const insightsData: { [key: string]: string } = {
    monthly: `${t("monthly")}: ${t("jan")} . ${selectedYear} - ${t(
      "dec",
    )} . ${selectedYear}`,
    quarterly: `${t("quarterly")}: ${t("quarter")}1 . ${selectedYear} - ${t(
      "quarter",
    )}4 . ${selectedYear}`,
    yearly: `${t("yearly")}: ${metricData?.[0]?.name} - ${selectedYear}`,
  };

  useEffect(() => {
    setInsightData(insightsData[timeFilter]);
  }, [metricData]);

  useEffect(() => {
    getOrgMetricData();
  }, [timeFilter]);

  const getOrgMetricData = () => {
    handleGetOrgMetric({
      metricType: item.type,
      filterBy: timeFilter,
      year: selectedYear,
    }).then((data: OrgMetricGraphDetails[]) => {
      const metricData: IMetricValuesProps[] = formatMetricData(
        data,
        timeFilter,
      );
      setMetricData(metricData);
    });
  };

  const handleAddValue = () => {
    setSelectedMetric({ ...item, selectedYear, timeFilter });
    setVisible(true);
  };

  const handleMenu = ({ key }: { key: string }) => {
    if (key === "edit-value") {
      setSelectedMetric({ ...item, selectedYear, timeFilter });
      setShowValuesVisible(true);
    } else if (key === "add-value") {
      handleAddValue();
    }
  };

  return (
    <>
      <CustomCard
        bordered={false}
        className={`${styles["section-card"]} org-metrics-card`}
      >
        <div className={styles["org-metric-container"]}>
          <div className={styles["section-item-container"]}>
            <div className={styles["item-header"]}>{`${
              item.title
            } (${getValueUnit(item.unit)})`}</div>
            <div className={styles["org-menu-container"]}>
              <InfoTextPopup
                items={item?.infoTexts}
                visible={advanceFilterVisible || selectedInfoText !== item.type}
                handleSelectedInfoText={(reset?: boolean) =>
                  setSelectedInfoText(reset ? "" : item.type)
                }
              />
              <CustomDropDown
                overlay={
                  <CustomMenu
                    items={[
                      { label: t("edit-value"), key: "edit-value" },
                      { label: t("add-new-value"), key: "add-value" },
                    ]}
                    onClick={({ key }) => handleMenu({ key })}
                  />
                }
                className={styles["org-menu"]}
              >
                <CustomButton type="link" className="link-btn">
                  <MoreOutlined />
                </CustomButton>
              </CustomDropDown>
            </div>
          </div>
          {metricData?.length && !isLoading ? (
            <>
              <div className={styles["metrices-linechart-container"]}>
                <LineChart
                  lineChartConfig={{
                    barSize: 32,
                    data: metricData,
                    lineColor: "#8884d8",
                    xData: {
                      key: "name",
                      isEmojiBar: false,
                      width: 80,
                      interval: 0,
                      customXTick: 40,
                      className: `${styles["metrices-linechart-label"]} ${
                        timeFilter === "monthly" ? styles["monthly-label"] : ""
                      }`,
                    },
                    yData: {
                      key: "value",
                      unit: item.unit,
                      domain: item.domain,
                    },
                  }}
                />
              </div>
              <div className={styles["org-metric-insight"]}>{insightData}</div>
            </>
          ) : isLoading ? (
            <Spin spinning={isLoading} />
          ) : (
            <NoData
              handleClick={handleAddValue}
              btnText={t("add-value")}
              title={t("add-value-to-view-data")}
            />
          )}
        </div>
      </CustomCard>
      {visible ? (
        <AddEditOrgMetric
          {...{
            orgMetric: selectedMetric,
            setVisible,
            visible,
            getOrgMetricData,
            companyRegisteredDate: moment(companyRegisteredDate).format(
              "MM-YYYY",
            ),
          }}
        />
      ) : null}
      {showValuesVisible && selectedMetric ? (
        <ShowOrgMetricValues
          setVisible={setShowValuesVisible}
          visible={showValuesVisible}
          orgMetric={selectedMetric}
          metricData={metricData}
          handleEditCurrentValue={(value: undefined | OrgMetricDetails) => {
            setSelectedMetric({ ...item, ...value, selectedYear, timeFilter });
            setVisible(true);
          }}
        />
      ) : null}
    </>
  );
};
