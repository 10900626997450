import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import uploadImgPlaceholder from "assets/images/imgPlaceHolder.svg";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import { CustomCheckBoxGroup } from "components/form/custom-checkbox-group/CustomCheckBoxGroup";
import { Col, Row } from "antd";
import { CustomDatePicker } from "components/form/custom-date-picker/CustomDatePicker";
import moment from "moment";
import {
  PartnerRewardCreate,
  RewardVisibility,
  useGetClientsTreeQuery,
  useGetConfigProvidersQuery,
  useGetConfigRewardCategoriesQuery,
  useGetConfigRewardTypesQuery,
  useGetConfigTiersQuery,
} from "graphql/_generated/graphql";
import { useReward } from "../hooks/useReward";
import customNotification from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers/errorFormat";
import { addPartnerRewardSchema } from "validations/schema/addPartnerRewardSchema";
import { useErrorHandler } from "hooks/useErrorHandler";

interface AddPartnerRewardProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
  langCode?: string;
  isAddLanguage?: boolean;
  partnerId: string;
}

const AddPartnerReward: React.FunctionComponent<AddPartnerRewardProps> = ({
  visible,
  setVisible,
  editId,
  setEditId,
  langCode,
  isAddLanguage,
  partnerId,
}) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { data: tiersData } = useGetConfigTiersQuery();
  const { data: clientsData } = useGetClientsTreeQuery();
  const { data: rewardCategoriesData } = useGetConfigRewardCategoriesQuery();
  const { data: providerData } = useGetConfigProvidersQuery();
  const { data: rewardTypesData } = useGetConfigRewardTypesQuery();

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  const {
    handleAddPartnerReward,
    handleGetPartnerReward,
    handleUpdateReward,
    rewardVisibility,
    isLoading,
  } = useReward();

  const [initialValues, setInitialValues] = useState<PartnerRewardCreate>({
    partnerId: partnerId,
    name: "",
    language: langCode,
    isDateEnable: false,
    startDate: undefined,
    endDate: undefined,
  });

  useEffect(() => {
    if (editId) {
      handleGetPartnerReward(editId).then((data) => {
        const translationData = langCode && data?.translations?.[langCode];
        const companies =
          data.companiesList && data.companiesList.map((obj) => obj.id);
        setInitialValues({
          name: isAddLanguage ? translationData?.name : data?.name,
          description: isAddLanguage
            ? translationData?.description
            : data?.description,
          language: langCode,
          image: data.image,
          companyIds: companies,
          partnerId: data.partner?.id,
          rewardCategoryId: data.rewardCategory?.id,
          rewardTypeId: data.rewardType?.id,
          rewardProviderId: data.rewardProvider?.id,
          tierId: data.tier?.id,
          isDateEnable: data.isDateEnable,
          companyVisibility: data.companyVisibility,
          startDate: data.startDate,
          endDate: data.endDate,
        });
      });
    }
  }, [editId]);

  return (
    <CustomModal
      visible={visible}
      onCancel={handleCancel}
      width={654}
      footer={false}
      title={
        editId && !isAddLanguage
          ? t("edit-reward")
          : `${t("add-reward")} ${isAddLanguage ? t("language") : ""}`
      }
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={addPartnerRewardSchema}
        onSubmit={async (values, { resetForm }) => {
          if (!editId) {
            try {
              await handleAddPartnerReward({
                create: {
                  ...values,
                  language: langCode,
                  partnerId: partnerId,
                },
              });
              customNotification(
                "success",
                t("partner-reward-added-successfully"),
              );
              resetForm();
              setVisible(false);
            } catch (error) {
              handleError(error as string | object);
            }
          } else if (isAddLanguage && editId && langCode) {
            try {
              await handleUpdateReward({
                update: {
                  translations: {
                    [langCode]: {
                      name: values.name,
                      description: values.description,
                    },
                  },
                },
                id: editId,
              });
              setEditId(null);
              customNotification(
                "success",
                t("partner-language-added-successfully"),
              );
              resetForm();
              setVisible(false);
            } catch (error) {
              handleError(error as string | object);
            }
          } else {
            try {
              await handleUpdateReward({ id: editId, update: values });
              customNotification(
                "success",
                t("partner-reward-updated-successfully"),
              );
              setEditId(null);
              setVisible(false);
              resetForm();
            } catch (error) {
              handleError(error as string | object);
            }
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="challenge-detail-container reward-image-container">
              {!isAddLanguage && (
                <CustomUploadFile
                  infoText={t("reward-image")}
                  label={t("reward-image")}
                  setFieldValue={setFieldValue}
                  name="image"
                  accept="image/png, .jpg, .jpeg"
                  customUploadText={
                    <span>
                      {t("drop-image")} {t("or")} <br />
                      <b>{t("select-to-browse")}</b>
                    </span>
                  }
                  customUploadImgSrc={uploadImgPlaceholder}
                  showFullPreview={true}
                  imgUrl={values.image}
                />
              )}
            </div>
            <CustomTextField
              name="name"
              label={t("name")}
              placeholder={t("enter-name")}
            />
            <CustomTextEditor
              name="description"
              label={t("description")}
              placeholder={t("enter-desc")}
              isRestrictAccess={true}
            />
            {!isAddLanguage && (
              <>
                <CustomSelectField
                  name="rewardTypeId"
                  label={t("reward-type")}
                  placeholder={t("select-reward-type")}
                  options={rewardTypesData?.getConfigRewardTypes.options}
                  fieldNames={{ label: "label", value: "id" }}
                />
                <CustomSelectField
                  name="tierId"
                  label={t("tier")}
                  placeholder={t("select-tier")}
                  options={tiersData?.getConfigTiers.options}
                  fieldNames={{ label: "label", value: "id" }}
                />
                <CustomSelectField
                  name="rewardProviderId"
                  label={t("provider")}
                  placeholder={t("select-provider")}
                  options={providerData?.getConfigProviders.options}
                  fieldNames={{ label: "label", value: "id" }}
                />
                <CustomSelectField
                  name="rewardCategoryId"
                  label={t("reward-category")}
                  placeholder={t("select-reward-category")}
                  options={
                    rewardCategoriesData?.getConfigRewardCategories.options
                  }
                  fieldNames={{ label: "label", value: "id" }}
                />
                <CustomSelectField
                  name="companyVisibility"
                  placeholder={t("select-visibility")}
                  label={t("company-visibility")}
                  options={rewardVisibility}
                />
                {values.companyVisibility === RewardVisibility.Company && (
                  <CustomTreeSelect
                    treeData={clientsData?.getClientsTree}
                    allowClear
                    showArrow
                    multiple={true}
                    showSearch={true}
                    treeNodeFilterProp="companiesList"
                    treeLine={true && { showLeafIcon: false }}
                    label={t("companies")}
                    className="form-select"
                    name="companyIds"
                    placeholder={t("select-companies")}
                    fieldNames={{ label: "companyName", value: "id" }}
                  />
                )}

                <div className="form-control">
                  <CustomCheckBoxGroup name="isDateEnable" options={[]}>
                    {t("start-end-date")}
                  </CustomCheckBoxGroup>
                </div>
                {values.isDateEnable && (
                  <Row gutter={[12, 0]}>
                    <Col span={12}>
                      <CustomDatePicker
                        name="startDate"
                        label={t("start-date")}
                        placeholder={t("select-start-date")}
                        disabledDate={(current) => {
                          return (
                            moment().add(-1, "days") >= current ||
                            current >=
                              moment(values.endDate, "YYYY-MM-DD").add(
                                "days",
                                1,
                              )
                          );
                        }}
                      />
                    </Col>
                    <Col span={12}>
                      <CustomDatePicker
                        name="endDate"
                        label={t("end-date")}
                        placeholder={t("select-end-date")}
                        disabledDate={(current) => {
                          return (
                            moment().add(-1, "days") >= current ||
                            current < moment(values.startDate, "YYYY-MM-DD")
                          );
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
            <ModalFooter
              saveText={t("offers.save-reward")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddPartnerReward;
