import * as Yup from "yup";
import * as Validations from "validations/schema/common/validations";
// import { ageSchema } from "./InviteMultipleUsersSchema";

const ageSchema = Yup.date()
  .typeError("Please enter a valid date")
  .test("age", "Age must be 18 years or older", function (value) {
    const cutoff = new Date();
    cutoff.setFullYear(cutoff.getFullYear() - 18);
    const cutoffUpper = new Date();
    cutoffUpper.setFullYear(cutoffUpper.getFullYear() - 100);
    return value! <= cutoff && value! > cutoffUpper;
  });

const commonValidations = Yup.object().shape({
  departmentId: Validations.required("department"),
  positionId: Validations.required("position"),
  buildingId: Validations.required("building"),
  firstName: Validations.requiredAlphaFieldWithoutSpaces("firstName"),
  lastName: Validations.requiredAlphaFieldWithoutSpaces("lastName"),
  dob: ageSchema,
  phoneNumber: Validations.phoneNumber("phoneNumber"),
});

export const inviteSingleUserSchemaForSuperAdmin = Yup.object().shape({
  employerId: Validations.required("company"),
  employeeCreateDto: Yup.object().shape({
    email: Validations.email,
    ...commonValidations.fields,
  }),
});

export const inviteSingleUserSchemaForClient = Yup.object().shape({
  employeeCreateDto: Yup.object().shape({
    email: Validations.email,
    ...commonValidations.fields,
  }),
});

export const editUserSchema = Yup.object().shape({
  // employerId: Validations.required("company"),
  employeeCreateDto: Yup.object().shape({
    // email: Validations.email,
    ...commonValidations.fields,
  }),
});
