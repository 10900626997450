import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomTextArea } from "components/form/custom-text-area/CustomTextArea";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { errorFormat } from "helpers";
import { Form, Formik } from "formik";
import {
  GetMeasurementQuery,
  MeasurementCreate,
  useGetMeasurementListQuery,
} from "graphql/_generated/graphql";
import { useState, useEffect } from "react";
import { unitsSchema } from "validations/schema/unitsSchema";
import { useMeasurement } from "./hooks/useMeasurement";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "hooks/useErrorHandler";

interface AddUnitProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
}

const AddUnit: React.FunctionComponent<AddUnitProps> = ({
  setVisible,
  visible,
  editId,
  setEditId,
}) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  const {
    handleAddNewMeasurement,
    handleUpdateMeasurement,
    handleFetchMeasurementData,
    isLoading,
  } = useMeasurement();

  const { refetch } = useGetMeasurementListQuery();

  const [initialValues, setInitialValues] = useState<MeasurementCreate>({
    unit: "",
    description: "",
  });

  useEffect(() => {
    if (editId) {
      handleFetchMeasurementData(editId).then((data) => {
        setInitialValues({
          description: data.description,
          unit: data.unit,
        });
      });
    }
  }, [editId]);

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={editId ? t("edit-unit") : t("add-new-unit")}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={unitsSchema}
        onSubmit={async (values, { resetForm }) => {
          if (!editId) {
            try {
              await handleAddNewMeasurement({ create: values });
              customNotification("success", t("metric-added-successfully"));
              setVisible(false);
              resetForm();
              refetch();
            } catch (error) {
              handleError(error as string | object);
            }
          } else {
            try {
              await handleUpdateMeasurement({ updateObj: values, id: editId });
              customNotification("success", t("unit-updated-successfully"));
              setVisible(false);
              resetForm();
              setEditId(null);
              refetch();
            } catch (error) {
              handleError(error as string | object);
            }
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <CustomTextField
              name="unit"
              label={t("unit")}
              placeholder={t("enter-unit")}
            />
            <CustomTextArea
              name="description"
              placeholder={t("enter-description")}
              label={t("description")}
              rows={6}
            />
            <ModalFooter
              saveText={t("save-unit")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddUnit;
