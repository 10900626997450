import { useCategoriesTreeQuery } from "graphql/_generated/graphql";
import { useEffect, useState } from "react";

export const useCategoriesIdAndName = (pillarId?: string) => {
  const { data } = useCategoriesTreeQuery({ pillarId: pillarId });
  const [categoriesData, setCategoriesData] = useState([]);

  useEffect(() => {
    let dummyArr: any = [];
    data?.getCategoriesTree.map((item: any) => {
      if (item.children) {
        let children: { label: any; value: any }[] = [];
        let a = item.children || [];
        item.children.map((childItem: { name: any; id: any }) => {
          children.push({
            label: childItem.name,
            value: childItem.id,
          });
        });
        dummyArr.push({
          label: item.name,
          value: item.id,
          children,
        });
      } else {
        dummyArr.push({ label: item.name, value: item.id });
      }
    });
    setCategoriesData(dummyArr);
  }, [data?.getCategoriesTree]);

  return { categoriesData };
};
