import { DeleteFilled, QuestionCircleFilled } from "@ant-design/icons";
import { Button, Col, Divider, Row, Select, Tooltip } from "antd";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { CustomUploadFile } from "components/form/custom-upload-file/CustomUploadFile";
import { CustomVideoUpload } from "components/form/custom-video-upload/CustomVideoUpload";
import { cloneObject, contentTypes, errorFormat } from "helpers";
import { useLanguages, usePillarIdAndName } from "hooks";

import CustomButton from "components/custom-button/CustomButton";
import CustomCard from "components/custom-card/CustomCard";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import { useGetContentStatus } from "hooks/useGetContentStatus";
import { useSpecialistConfigList } from "hooks/useSpecialistConfig";
import DashboardLayout from "features/dashboard/dashboard-layout/DashboardLayout";
import { FieldArray, Form, Formik } from "formik";
import {
  ContentType,
  useActivateSubtitleMutation,
  useGetCategoriesTreeQuery,
  useGetConfigTagsQuery,
  useGetContentConfigQuery,
  useGetContentListQuery,
  useLazyGetVideoDetailsQuery,
} from "graphql/_generated/graphql";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { contentSchema } from "validations/schema";
import { useContent } from "features/content/content/useContent";
import styles from "features/content/css/AddContent.module.scss";
import {
  checkTypeAudio,
  getContentType,
  getLabelFromValue,
} from "../../features/content/utils";
import { CustomTextArea } from "components/form/custom-text-area/CustomTextArea";
import ContentUpload from "../../features/content/content-upload/ContentUpload";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import { useErrorHandler } from "hooks/useErrorHandler";
import { CategoryWithChildren } from "features/content/collection/add-collection/AddCollection";

const AddContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleError } = useErrorHandler();
  const { languages } = useLanguages();
  const { pillarsData } = usePillarIdAndName();
  const { getSpecialistConfigData } = useSpecialistConfigList();
  const { data: tagsData } = useGetConfigTagsQuery();
  const { data: contentData, refetch: contentRefetch } =
    useGetContentConfigQuery(
      { type: ContentType.Recipe },
      {
        refetchOnMountOrArgChange: true,
      },
    );
  const { refetch: contentConfigRefetch } = useGetContentConfigQuery();
  const [activeSubtitleFun] = useActivateSubtitleMutation();
  const [getVideoDetailsFun] = useLazyGetVideoDetailsQuery();
  // const { contentStatus } = useGetContentStatus();
  // const [pillarId, setPillarId] = useState("");
  const { data: categoriesData } = useGetCategoriesTreeQuery(
    {
      pillarId: null,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const [value, setValue] = useState(null);
  const localizedContent = (values: any) => {
    const { contentType } = values;
    const dynamicContentType = getContentType(contentType);

    return {
      setSpecialist: () => {
        values[dynamicContentType].forEach((element: any) => {
          element.specialist = getSpecialistConfigData?.find(
            (o) => o.value === element.specialist,
          )?.extra;
        });

        return values;
      },
      resetSpecialist: () => {
        const valuesClone = cloneObject(values);
        valuesClone[dynamicContentType]?.forEach((element: any) => {
          element.specialist = element.specialist?.id;
        });

        return valuesClone;
      },
    };
  };

  const {
    handleAddContent,
    handleFetchEditContentData,
    handleUpdateContent,
    handleDeleteSubTitle,
    isLoading,
  } = useContent();
  const { refetch } = useGetContentListQuery();
  const editId: any = location.state;
  let myArray: [];
  const [initialValues, setInitialValues] = useState<any>({
    shortDescription: "",
    title: "",
    description: "",
    translations: [],
    creatorId: "",
    language: "",
    spokenLanguage: null,
    thumbnailImage: "",
    tagIds: [],
    pillarIds: [],
    categoryIds: [],
    videoUrl: "",
    audioUrl: "",
    fileUrl: "",
    text: "",
    duration: "",
    points: {
      start: null,
      end: null,
    },
    // status: "",
    localizedAudios: [],
    localizedVideos: [],
    localizedFiles: [],
    localizedTexts: [],
    type: "",
    originalVideoSubtitle: [{ language: "", url: "" }],
    subtitles: [],
    contentType: "",
    gallery: [
      {
        image: "",
      },
    ],
    relatedRecipes: [],
    timeRequired: undefined,
    serving: undefined,
  });

  const [contentTypeItems, setContentTypeItems] = useState<
    {
      key: number;
      label: string;
      value: string;
    }[]
  >([]);

  //this function is getting the data from api and show in the form
  const showPrevData = async () => {
    if (editId) {
      handleFetchEditContentData(editId).then(async (data: any) => {
        try {
          let subtitles: any = [];
          // setPillarId(data.pillar.id);
          const RelatedRecipes: string[] = [];
          try {
            data = localizedContent(data).resetSpecialist();
            //this block of code is fetching the subtitles info from the backend side and show in the ui
            if (data.videoUrl) {
              const videoUri = data.videoUrl.split("/").pop().split(".")[0];
              const res = await getVideoDetailsFun({ videoUri });
              const subtitlesArr: any = [];
              res.data?.getVideoDetails.subtitles.map((subtitle: any) => {
                subtitlesArr.push({
                  language: subtitle.language,
                  url: subtitle.uri,
                  checked: subtitle.isActive,
                });
              });
              subtitles = subtitlesArr;
            }
            const activeSubtitleValue = subtitles.filter(
              (item: { checked: boolean }) => item.checked === true,
            )[0]?.url;
            setValue(activeSubtitleValue);

            if (data.relatedRecipes) {
              data?.relatedRecipes
                .filter((item: any) => item.isActive === true)
                .map((item: any) => {
                  return RelatedRecipes.push(item.id);
                });
            }
            if (data.gallery) {
              myArray = data.gallery.map((str: string, index: number) => ({
                image: str,
              }));
            }

            setInitialValues({
              subtitles,
              language: data.language,
              spokenLanguage: data.spokenLanguage,
              thumbnailImage: data.thumbnailImage,
              translations: data.translations,
              title: data.title,
              description: data.description,
              shortDescription: data?.shortDescription,
              tagIds: data?.tags,
              categoryIds: data.categories
                .filter((category: any) => category?.isActive)
                .map(({ id }: { id: string }) => id),
              pillarIds: data?.pillars.map((x: any) => x.id),
              creatorId: data.creator.id,
              points: data?.points,
              // status: data.status,
              coverImage: data?.coverImage,
              duration: data?.duration ? data?.duration : "",
              videoUrl: data?.videoUrl ? data?.videoUrl : "",
              audioUrl: data?.audioUrl ? data?.audioUrl : "",
              fileUrl: data?.fileUrl ? data?.fileUrl : "",
              text: data?.text ? data?.text : "",
              serving: data?.serving ? data?.serving : undefined,
              gallery: data?.gallery ? myArray : "",
              relatedRecipes: data?.relatedRecipes ? RelatedRecipes : "",
              timeRequired: data?.timeRequired ? data?.timeRequired : "",

              localizedVideos: data?.localizedVideos
                ? data?.localizedVideos
                : [{ language: "", url: "" }],
              localizedAudios: data?.localizedAudios
                ? data?.localizedAudios
                : [{ language: "", url: "" }],
              localizedTexts: data?.localizedTexts
                ? data?.localizedTexts
                : [{ language: "", url: "" }],
              localizedFiles: data?.localizedFiles
                ? data?.localizedFiles
                : [{ language: "", text: "" }],
              contentType: data.contentType ?? null,
            });
          } catch (error) {
            console.log(error);
          }
        } catch (e) {
          console.log(e);
        }
      });
    }
  };

  console.log(initialValues.tagIds, "tagIds");
  // this useEffect created the options array for content type select
  useEffect(() => {
    setContentTypeItems(contentTypes());

    //this function will fetch the prev data and show in the form if edit id exists
    showPrevData();
  }, []);

  return (
    <DashboardLayout openKey={["Content"]}>
      <Breadcrumbs
        data={[
          {
            label: "Content",
          },
          {
            label: editId ? "Edit Content" : "Create New Content",
          },
        ]}
      />
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={contentSchema}
        onSubmit={async (values, { resetForm }) => {
          if (!editId) {
            try {
              await handleAddContent({
                create: {
                  ...values,
                  ...localizedContent(values).setSpecialist(),
                },
              });
              resetForm();
              refetch();
              contentConfigRefetch();
              contentRefetch();
              customNotification("success", "Content added successfully");
              navigate("/content");
            } catch (error) {
              handleError(error as string | object);
            }
          } else {
            try {
              await handleUpdateContent(editId, {
                updateObj: {
                  ...values,
                  ...localizedContent(values).setSpecialist(),
                },
              });
              resetForm();
              refetch();
              contentConfigRefetch();
              contentRefetch();
              customNotification("success", "Content updated successfully");
              navigate("/content#persist");
            } catch (error) {
              handleError(error as string | object);
            }
          }
        }}
      >
        {({ values, setFieldValue }) => {
          const isTypeAudio = checkTypeAudio(values);
          return (
            <Form>
              <div className={styles["add-content-header"]}>
                <h1 className={styles["title"]}>
                  {editId ? "Edit Content" : "Create New Content"}
                </h1>
              </div>
              <div className={styles["content-card"]}>
                <CustomCard bordered={false} className="content-card">
                  <Row gutter={[24, 0]}>
                    <Col sm={24} md={12}>
                      <CustomSelectField
                        label={t("text-language")}
                        required={true}
                        value={getLabelFromValue(languages, values)}
                        name="language"
                        placeholder={t("select-language")}
                        options={languages}
                        defaultValue={getLabelFromValue(languages, values)}
                        className="form-select"
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomTextField
                        label={t("content-title")}
                        name="title"
                        placeholder={t("enter-content-title")}
                      />
                    </Col>
                  </Row>

                  <CustomTextEditor
                    name="description"
                    label={t("description")}
                  />
                  <CustomTextArea
                    name="shortDescription"
                    label={t("short-description")}
                    rows={4}
                  />
                  {/*this field array is dynamically add the title and description */}
                  <FieldArray
                    name="translations"
                    render={(arrayHelpers) => (
                      <div>
                        {values.translations &&
                        values.translations.length > 0 ? (
                          values.translations.map(
                            (translation: any, index: number) => (
                              <div key={index}>
                                <Divider />
                                <div className={styles["multi-lang-box"]}>
                                  <div className="-control">
                                    <CustomSelectField
                                      marginBtm={false}
                                      label={t("other-text-language")}
                                      required={true}
                                      value={getLabelFromValue(
                                        languages,
                                        values,
                                        index,
                                      )}
                                      name={`translations.${index}.lang`}
                                      placeholder={t("select-language")}
                                      options={languages}
                                      defaultValue={getLabelFromValue(
                                        languages,
                                        values,
                                        index,
                                      )}
                                      className="form-select"
                                    />
                                  </div>
                                  <CustomTextField
                                    label={t("content-title")}
                                    name={`translations.${index}.title`}
                                    placeholder={t(
                                      "enter-title-in-selected-language",
                                    )}
                                  />
                                </div>
                                <CustomTextEditor
                                  label={t(
                                    "write-description-in-selected-language",
                                  )}
                                  name={`translations.${index}.description`}
                                  placeholder={t(
                                    "enter-description-in-selected-language",
                                  )}
                                />
                                <CustomTextArea
                                  name={`translations.${index}.shortDescription`}
                                  label={t("short-description")}
                                  rows={4}
                                />
                                <div className={styles["flex-div"]}>
                                  {values.translations.length + 1 <
                                  languages.length ? (
                                    <span
                                      className={styles["add-btn"]}
                                      onClick={() =>
                                        arrayHelpers.push({
                                          lang: "",
                                          title: "",
                                          description: "",
                                          shortDescription: "",
                                        })
                                      }
                                    >
                                      + {t("add-new-language")}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  <CustomButton
                                    type="link"
                                    className="remove-btn"
                                    onClick={() => arrayHelpers.remove(index)} // remove a language from the list
                                  >
                                    <DeleteFilled />
                                  </CustomButton>
                                </div>
                              </div>
                            ),
                          )
                        ) : (
                          <div className={styles["form-control"]}>
                            <span
                              className={styles["add-btn"]}
                              onClick={() =>
                                arrayHelpers.push({
                                  lang: "",
                                  title: "",
                                  description: "",
                                  shortDescription: "",
                                })
                              }
                            >
                              + {t("add-new-language")}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  />
                </CustomCard>
              </div>

              <div className={styles["content-card"]}>
                <CustomCard bordered={false} className="content-card">
                  <div className={styles["name-desc"]}>
                    {t("content-detail")}
                  </div>
                  <Row gutter={[24, 0]}>
                    <Col sm={24} md={12}>
                      <CustomSelectField
                        disabled={editId ? true : false}
                        label="Content Type"
                        className="form-select"
                        name="contentType"
                        placeholder={t("select-content-type")}
                        options={contentTypeItems}
                      />
                    </Col>
                    {/* <Col sm={24} md={12}>
                      <CustomTextField
                        type="number"
                        label="Points"
                        name="points.end"
                        placeholder="Enter end points"
                        onChange={(e) =>
                          setFieldValue(
                            "points.end",
                            e.target.value.length === 0
                              ? null
                              : Number(e.target.value),
                          )
                        }
                      />
                    </Col> */}
                    <Col sm={24} md={12}>
                      <CustomSelectField
                        // onChange={(value) => {
                        //   setPillarId(value);
                        //   setFieldValue("categoryId", "");
                        // }}
                        label={t("pillars")}
                        className="form-select"
                        name="pillarIds"
                        placeholder={t("select-pillar")}
                        options={pillarsData}
                        mode="multiple"
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomTreeSelect
                        showArrow
                        treeNodeFilterProp="name"
                        treeLine={true && { showLeafIcon: false }}
                        value={values.categoryIds}
                        label={t("categories")}
                        fieldNames={{
                          label: "name",
                          value: "id",
                        }}
                        className="form-select"
                        name="categoryIds"
                        placeholder={t("select-category")}
                        multiple
                        treeData={categoriesData?.getCategoriesTree.map(
                          (node: CategoryWithChildren) => ({
                            ...node,
                            disableCheckbox: !node.children,
                          }),
                        )}
                        treeCheckable
                        showCheckedStrategy="SHOW_CHILD"
                      />
                    </Col>
                    <Col sm={24} md={12}>
                      <CustomSelectField
                        label="Tags"
                        name="tagIds"
                        mode="multiple"
                        placeholder={t("select-tags")}
                        className="form-select"
                        options={tagsData?.getConfigTags.options}
                        fieldNames={{ label: "label", value: "id" }}
                      />
                    </Col>
                    {/* <Col sm={24} md={12}>
                      <CustomSelectField
                        label={t("select-status")}
                        name="status"
                        placeholder={t("select-status")}
                        className="form-select"
                        options={contentStatus}
                      />
                    </Col> */}
                    {/* <Col sm={24} md={12}>
                      <CustomTextField
                        type="number"
                        label="Start points"
                        name="points.start"
                        placeholder="Enter start points"
                        onChange={(e) =>
                          setFieldValue(
                            "points.start",
                            e.target.value.length === 0
                              ? null
                              : Number(e.target.value),
                          )
                        }
                      />
                    </Col> */}
                  </Row>
                </CustomCard>
              </div>
              <div className={styles["content-card"]}>
                <CustomCard bordered={false} className="content-card">
                  <div className={styles["name-desc"]}>
                    {t("thumbnail-detail")}
                  </div>
                  <Row gutter={[24, 0]}>
                    <Col sm={24} md={12}>
                      <CustomUploadFile
                        label={t("upload-content-thumbnail-image")}
                        name="thumbnailImage"
                        setFieldValue={setFieldValue}
                        imgUrl={values.thumbnailImage}
                        accept="image/*"
                      />
                    </Col>
                    {isTypeAudio && (
                      <Col sm={24} md={12}>
                        <CustomUploadFile
                          label={<BackgroundImageLabel />}
                          name="coverImage"
                          setFieldValue={setFieldValue}
                          imgUrl={values.coverImage}
                          accept="image/*"
                        />
                      </Col>
                    )}
                  </Row>
                </CustomCard>
              </div>
              <div className={styles["content-card"]}>
                {values.contentType.length > 0 && (
                  <CustomCard bordered={false} className="content-card">
                    <div className={styles["name-desc"]}>
                      {`${values.contentType} Files`}
                    </div>
                    <ContentUpload
                      {...{
                        editId,
                        setFieldValue,
                        values,
                        value,
                        languages,
                        handleDeleteSubTitle,
                      }}
                    />
                  </CustomCard>
                )}
              </div>
              <div className={styles["btn-div"]}>
                <Button
                  className="light-btn"
                  htmlType="reset"
                  onClick={() => navigate("/content#persist")}
                >
                  {t("cancel")}
                </Button>
                <Button
                  loading={isLoading}
                  htmlType="submit"
                  className="primary-btn"
                >
                  {t("save-content")}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
};
export default AddContent;

const BackgroundImageLabel = () => {
  return (
    <div className={styles["label-with-help"]}>
      <label className="label" htmlFor={"Background Image"}>
        Background Image
      </label>
      <Tooltip
        color="#494949"
        trigger="click"
        title={
          <div className={styles["help-text"]}>
            <h4>Instructions to upload Audio Background</h4>
            <p>Image Orientation : Portrait</p>
            <p>Aspect Ratio: 13:6</p>
            <p>Max Image Size: 1290 x 2796</p>
            <p>Min Image Size: 600 x 1300</p>
          </div>
        }
      >
        <QuestionCircleFilled />
      </Tooltip>
    </div>
  );
};
