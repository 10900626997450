import { convertTranslationArrToObj } from "helpers";
import {
  MutationAddNewPositionArgs,
  useAddNewPositionMutation,
} from "graphql/_generated/graphql";




export const useAddPosition = () => {
  
  const [addPositionFun, { isLoading }] = useAddNewPositionMutation();

  //this function is responsible for adding the position data
  const handleAddPosition = async (values: MutationAddNewPositionArgs) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.create.translations
      );
      let params = { create: { ...values.create, translations } };
      await addPositionFun(params).unwrap();
    } catch (error: any) {
      throw error;
    }
  };

  return {
    isLoading,
    handleAddPosition,
  };
};
