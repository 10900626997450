import {
  useDeletePartnerRewardMutation,
  useGetPartnerRewardListQuery,
} from "graphql/_generated/graphql";

export const useDeletePartnerReward = () => {
  const [deleteRewardFun, { isLoading }] = useDeletePartnerRewardMutation();
  const { refetch } = useGetPartnerRewardListQuery();

  const handleDeleteReward = async (id: string) => {
    try {
      await deleteRewardFun({ deletePartnerRewardId: id }).unwrap();
      refetch();
    } catch (error) {
      throw error;
    }
  };
  return {
    handleDeleteReward,
    isLoading,
  };
};
