import { Editor } from "@tinymce/tinymce-react";
import { useImageUpload } from "hooks";
import { ErrorMessage, useField } from "formik";
import * as React from "react";
import { Editor as TinyMCEEditor } from "tinymce";
import { CONFIG } from "helpers/constants";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import "./CustomTextEditor.module.scss";
import { getLangDir } from "helpers/getLangDir";

interface CustomTextEditorProps {
  label?: string;
  name: string;
  placeholder?: string | any;
  isRestrictAccess?: boolean;
  disabled?: boolean;
}

const apiKey =
  process.env.REACT_APP_TINY_MCE_API_KEY || CONFIG.TINY_MCE_API_KEY;

const CustomTextEditor: React.FunctionComponent<CustomTextEditorProps> = (
  props,
) => {
  const { errorT } = useErrorTranslation();
  const { loading, handleImageUpload } = useImageUpload();
  const useDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const { label, name } = props;
  const [field, meta] = useField(name);
  const type = "text";
  const handleEditorChange = (value: string, editor: TinyMCEEditor) => {
    field.onChange({ target: { type, name, value } });
    const direction = getLangDir(editor.getContent({ format: "text" }));
    console.log(direction, "direction");
    if (direction === "rtl") {
      editor.getBody().style.direction = "rtl";
      editor.getBody().style.fontFamily = "Almarai";
    } else {
      editor.getBody().style.direction = "ltr";
      editor.getBody().style.fontFamily =
        "-apple-system, BlinkMacSystemFont, sans-serif";
    }
  };

  const handleBlur = (e: unknown, editor: TinyMCEEditor) => {
    field.onBlur({ target: { name } });
  };

  return (
    <div className="text-editor">
      {label && (
        <label className="label" htmlFor={name}>
          {label}
        </label>
      )}
      <Editor
        value={field.value}
        onEditorChange={handleEditorChange}
        onBlur={handleBlur}
        disabled={props?.disabled}
        textareaName={props.name}
        apiKey={apiKey}
        init={{
          setup: (editor) => {
            editor.on("init", () => {
              const direction = getLangDir(
                editor.getContent({ format: "text" }),
              );
              if (direction === "rtl") {
                editor.getBody().style.direction = "rtl";
                editor.getBody().style.fontFamily = "Almarai";
              } else {
                editor.getBody().style.direction = "ltr";
                editor.getBody().style.fontFamily =
                  "-apple-system, BlinkMacSystemFont, sans-serif";
              }
            });
          },
          plugins:
            "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons",
          editimage_cors_hosts: ["picsum.photos"],
          menubar: props?.isRestrictAccess
            ? ""
            : "file edit view insert format tools table help",
          toolbar: props?.isRestrictAccess
            ? "bold italic underline blocks alignleft aligncenter alignright numlist bullist | undo redo "
            : "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
          toolbar_sticky: false,
          autosave_ask_before_unload: true,
          autosave_interval: "30s",
          autosave_prefix: "{path}{query}-{id}-",
          autosave_restore_when_empty: false,
          autosave_retention: "2m",
          image_advtab: true,
          link_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_class_list: [
            { title: "None", value: "" },
            { title: "Some class", value: "class-name" },
          ],
          importcss_append: true,
          file_picker_callback: (callback, value, meta) => {
            /* Provide file and text for the link dialog */
            if (meta.filetype === "file") {
              callback("https://youtu.be/KzBa4ZKTVjE", {
                text: "My text",
              });
            }

            /* Provide image and alt text for the image dialog */
            if (meta.filetype === "image") {
              var input = document.createElement("input");
              input.setAttribute("type", "file");
              input.setAttribute("accept", "image/*");
              input.addEventListener("change", () => {
                if (input.files != null && input.files[0] != null) {
                  // create instance of FileReader()
                  let reader = new FileReader();
                  // create event triggered after successful reading operation
                  reader.onload = (e: any) => {
                    // insert content in TinyMCE
                    callback(e.target.result, {
                      text: "My text",
                    });
                    input.value = "";
                  };
                  reader.readAsDataURL(input.files[0]);
                }
              });
              input.click();
            }

            /* Provide alternative source and posted for the media dialog */
            if (meta.filetype === "media") {
              callback("movie.mp4", {
                source2: "alt.ogg",
                poster: "https://www.google.com/logos/google.jpg",
              });
            }
          },
          //list of templates
          // templates: [{}],
          template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
          template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
          height: 350,
          image_caption: true,
          quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
          noneditable_class: "mceNonEditable",
          toolbar_mode: "sliding",
          contextmenu: "link image table",
          // skin: useDarkMode ? "oxide-dark" : "oxide",
          // content_css: useDarkMode ? "default" : "default",
          content_style:
            "body { font-family: -apple-system, BlinkMacSystemFont, sans-serif; font-size:14px; line-height:1.4; }",
          font_family_formats:
            "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; SF Pro Text=-apple-system, BlinkMacSystemFont, sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
          font_size_formats: "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
          elementpath: false,
          branding: false,
          file_picker_types: "file, image, media",
          browser_spellcheck: true,
          automatic_uploads: true,
          file_browser_callback_types: "file image media",
          paste_data_images: true,
          placeholder: props.placeholder,
          content_css: [
            "https://fonts.googleapis.com/css2?family=Almarai&display=swap",
          ],
          content_css_cors: true,
        }}
      />
      <ErrorMessage
        name={name}
        render={(msg) => <div className="text-danger">{errorT(msg)}</div>}
      />
    </div>
  );
};

export default CustomTextEditor;
