import { Avatar, Space, Switch, Tag } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import CustomButton from "components/custom-button/CustomButton";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomMenu from "components/custom-menu/CustomMenu";
import CustomTable from "components/custom-table/CustomTable";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import {
  useLazyGetContentListQuery,
  useGetContentListQuery,
  useGetContentConfigQuery,
  ContentStatus,
  ContentType,
  useGetCategoriesTreeQuery,
  Category,
} from "graphql/_generated/graphql";
import moment from "moment";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { customNotification } from "components/custom-notification/CustomNotification";
import { errorFormat, PERMS, contentTypes } from "helpers";
import { useToggleActivationContent } from "./useToggleActivationContent";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import { store } from "store/store";
import { api } from "store/store";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import { useLanguages } from "hooks";
import { getLabelFromValue } from "../../utils";
import { ReactI18NextChild, useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import { createRenderFunction } from "features/content/collection/add-collection/utils";
import { useErrorHandler } from "hooks/useErrorHandler";

type Props = {};
const renderTextLanguages = createRenderFunction("language", "translations");
const renderSpokenLanguages = createRenderFunction(
  "spokenLanguage",
  "localizedSpokenLanguages",
);

export default function ContentList({}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<any>();
  const [pageSize, setPageSize] = useState(10);
  const { languages } = useLanguages();
  const [currentPage, setCurrentPage] = useState(1);
  const [getAllContentDataFun] = useLazyGetContentListQuery();
  const { isFetching, refetch } = useGetContentListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleActivationContent } = useToggleActivationContent();
  const { handleError } = useErrorHandler();
  const { refetch: contentConfigRefetch } = useGetContentConfigQuery({
    type: "Recipe",
  });

  useEffect(() => {
    fetchDataFromApi(getAllContentDataFun, setColumnsData, filtersData).catch(
      (e) => handleError(e),
    );
  }, [isFetching]);

  const menuItems = [
    {
      label: t("edit-content"),
      key: "edit",
    },
    {
      label: t("view-detail"),
      key: "view-detail",
    },
  ];

  const handleMenu = ({ domEvent, key, keyPath, id }: any) => {
    const editId: string = id;

    if (key === "edit") {
      navigate("/add-content", {
        state: editId,
      });
    } else if (key === "view-detail") {
      navigate("/content-detail", {
        state: id,
      });
    }
  };

  const contentDataTypes = useMemo(() => {
    return contentTypes();
  }, []);

  const { data: categoriesData } = useGetCategoriesTreeQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const columns: ColumnsType<any> = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: t("content-name"),
      dataIndex: "title",
      key: "title",
      sorter: {
        multiple: 1,
      },
      render: (_text: any, record: any, index: number) => (
        <Space>
          <CustomAvatar shape="square" imgSrc={record?.thumbnailImage} />
          <span>{record.title}</span>
        </Space>
      ),
    },
    {
      title: t("content-type-title"),
      dataIndex: "contentType",
      key: "contentType",
    },
    {
      title: t("categories"),
      dataIndex: "categories",
      key: "categories",
      render: (data: any, record: any, index: number) => (
        <span>
          {data?.map((category: Category) => category.name).join(", ")}
        </span>
      ),
    },
    {
      title: t("views"),
      dataIndex: "views",
      key: "views",
      align: "center",
    },
    {
      title: t("text-languages"),
      dataIndex: "language",
      key: "language",
      render: (_text, record, _index) => (
        <Space>{renderTextLanguages(record, languages).join(", ")}</Space>
      ),
    },
    {
      title: t("spoken-languages"),
      dataIndex: "spokenLanguage",
      key: "spokenLanguage",
      render: (_text, record, _index) => (
        <Space>{renderSpokenLanguages(record, languages).join(", ")}</Space>
      ),
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) => moment(createdAt).format("MMMM Do YYYY"),
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: {
        multiple: 1,
      },
      responsive: ["xxl"],
      render: (updatedAt: string) => moment(updatedAt).format("MMMM Do YYYY"),
    },
    {
      title: t("status-label"),
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status: ContentStatus) => {
        if (status === ContentStatus.Published) {
          return <Tag className="active-tag">{t("published")}</Tag>;
        } else if (status === ContentStatus.Draft) {
          return <Tag className="de-active-tag">{t("draft")}</Tag>;
        } else if (status === ContentStatus.UnPublished) {
          return <Tag className="unpublished-tag">{t("unpublished")}</Tag>;
        }
      },
    },
    {
      title: t("activation"),
      dataIndex: "id",
      key: "activation",
      align: "center",
      render: (id: string, { isActive }: any) => (
        <CheckPermissions permission={PERMS.TOGGLE_CLIENT_ACTIVATION}>
          <CustomPopconfirm
            placement="left"
            onConfirm={async () => {
              try {
                const isContentActive = await handleActivationContent(id);
                customNotification(
                  "success",
                  isContentActive
                    ? t("content-activate-success")
                    : t("content-deactivate-success"),
                );
                refetch();
              } catch (error) {
                handleError(error as string | object);
              }
            }}
            title={
              isActive
                ? t("content-deactivate-confirm")
                : t("content-activate-confirm")
            }
          >
            <Switch
              className="custom-switch"
              defaultChecked
              checked={isActive}
            />
          </CustomPopconfirm>
        </CheckPermissions>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "Actions",
      align: "center",
      render: (id: string) => (
        <>
          <CustomDropDown
            overlay={
              <CustomMenu
                items={menuItems}
                onClick={({ domEvent, key, keyPath }) =>
                  handleMenu({ domEvent, key, keyPath, id })
                }
              />
            }
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </CustomDropDown>
        </>
      ),
    },
  ];

  return (
    <CustomTable
      filters={{
        handleCallback: async (filtersData: any) => {
          await fetchDataFromApi(
            getAllContentDataFun,
            setColumnsData,
            filtersData,
          );
          setPageSize(filtersData?.pagination?.offset?.pageSize);
          setCurrentPage(filtersData?.pagination?.offset?.page);
          setFiltersData(filtersData);
        },
        date: [
          {
            applyAs: "and",
            key: "createdAt",
            comparison: "Range",
          },
        ],
        status: {
          applyAs: "and",
          key: "isActive",
          comparison: "Equal",
        },
        manualFilters: [
          {
            key: "contentType",
            comparison: "Equal",
            applyAs: "and",
            render: (onChangeCallback, value) => {
              return (
                <Formik initialValues={{ company: null }} onSubmit={() => {}}>
                  {({ setFieldValue, values }) => (
                    <Form>
                      <CustomSelectField
                        name="contentType"
                        className="filter-select"
                        placeholder={t("select-content-type")}
                        options={contentDataTypes}
                        onChange={onChangeCallback}
                        marginBtm={false}
                        value={value}
                        allowClear
                      />
                    </Form>
                  )}
                </Formik>
              );
            },
          },
          {
            key: "category.id",
            comparison: "Equal",
            applyAs: "and",
            render: (onChangeCallback, value) => {
              return (
                <Formik initialValues={{ company: null }} onSubmit={() => {}}>
                  {({ setFieldValue, values }) => (
                    <Form>
                      <CustomTreeSelect
                        noMargin
                        allowClear
                        showArrow
                        treeNodeFilterProp="name"
                        treeLine={true && { showLeafIcon: false }}
                        fieldNames={{
                          label: "name",
                          value: "id",
                        }}
                        className="filter-select"
                        name="categoriesIds"
                        placeholder={t("select-category")}
                        treeData={categoriesData?.getCategoriesTree}
                        onChange={onChangeCallback}
                        value={value}
                      />
                    </Form>
                  )}
                </Formik>
              );
            },
          },
        ],
      }}
      columns={columns}
      totalItems={columnsData?.metadata?.total}
      loading={isLoading}
      permission={
        PERMS.ADD_NEW_VIDEO_CONTENT ||
        PERMS.ADD_NEW_AUDIO_CONTENT ||
        PERMS.ADD_NEW_TEXT_CONTENT ||
        PERMS.ADD_NEW_RECIPE_CONTENT ||
        PERMS.ADD_NEW_FILE_CONTENT
      }
      onClick={() => navigate("/add-content", { replace: true })}
      btnText={t("content.add-content")}
      dataSource={columnsData?.data}
      rowKey="id"
    />
  );
}
