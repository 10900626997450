import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Modal, Space, Switch, Tag } from "antd";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomButton from "components/custom-button/CustomButton";
import CustomMenu from "components/custom-menu/CustomMenu";
import { customNotification } from "components/custom-notification/CustomNotification";
import CustomTable from "components/custom-table/CustomTable";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import { cloneObject, errorFormat, getTranslatedValue, PERMS } from "helpers";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import {
  Comparison,
  useCategoriesTreeQuery,
  useGetCategoryListQuery,
  useLazyGetCategoryListQuery,
} from "graphql/_generated/graphql";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "store/store";
import { store } from "store/store";
import AddCategory from "../add-category/AddCategory";
import { useCategory } from "../useCategory";
import styles from "./CategoryList.module.scss";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import { CONFIRM_MODAL_CONFIG } from "helpers/constants";
import { useDeleteCategory } from "./useDeleteCategory";
import { useErrorHandler } from "hooks/useErrorHandler";

const parentFilter = {
  key: "parent",
  value: null,
  comparison: Comparison.Equal,
};

const CategoriesList = () => {
  const { t } = useTranslation();
  const [columnsData, setColumnsData] = useState<any>();
  const [getAllCategoriesDataFun] = useLazyGetCategoryListQuery();
  const { isFetching, refetch } = useGetCategoryListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const [visible, setVisible] = useState<boolean>(false);
  const [editId, setEditId] = useState<string | null>(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { handleDeActivationCategory } = useCategory();
  const [filtersData, setFiltersData] = useState<any>(null);
  const { handleDeleteCategory } = useDeleteCategory();
  const { handleError } = useErrorHandler();
  const { data: categoriesData, refetch: reFetchCategory } =
    useCategoriesTreeQuery(
      {
        pillarId: null,
      },
      { refetchOnMountOrArgChange: true },
    );

  const handleMenu = ({ key, id }: any) => {
    if (key === "edit-category") {
      handleEdit(id);
    } else if (key === "delete-category") {
      Modal.confirm({
        ...CONFIRM_MODAL_CONFIG,
        title: t("warning"),
        content: (
          <>
            <div>{t("category.delete-warning")}</div>
          </>
        ),
        onOk: async () => {
          try {
            await handleDeleteCategory(id);
            refetch();
          } catch (error) {
            handleError(error as string | object);
          }
        },
      });
    }
  };

  const handleEdit = (id: string) => {
    setEditId(id);
    setVisible(true);
    reFetchCategory();
  };

  const fetchData = () => {
    if (!filtersData?.filters) return;

    fetchDataFromApi(
      getAllCategoriesDataFun,
      setColumnsData,
      filtersData,
    ).catch((e) => handleError(e));
  };

  useEffect(() => {
    setFiltersData({
      filters: {
        and: [parentFilter],
      },
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [isFetching, filtersData]);

  useEffect(() => {
    if (!filtersData) {
      fetchDataFromApi(getAllCategoriesDataFun, setColumnsData, {
        filters: {
          and: [{ key: "parent", value: null, comparison: Comparison.Equal }],
        },
      }).catch((e) => handleError(e));
    } else {
      fetchDataFromApi(
        getAllCategoriesDataFun,
        setColumnsData,
        filtersData,
      ).catch((e) => handleError(e));
    }
  }, [isFetching]);

  const columns = [
    {
      width: 50,
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text: any, _record: any, index: number) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      width: "50%",
      title: t("name"),
      dataIndex: "name",
      key: "name",
      sorter: {
        multiple: 1,
      },
      render: (name: string, item: any) => (
        <Space>
          <CustomAvatar imgSrc={item.icon} />
          <div>{getTranslatedValue(item, "name")}</div>
        </Space>
      ),
    },
    {
      width: "20%",
      title: t("creation-date"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: any) =>
        moment(createdAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      width: "15%",
      title: t("status"),
      dataIndex: "isActive",
      key: "isActive",
      align: "center" as "center",
      render: (isActive: boolean) =>
        isActive ? (
          <Tag className="active-tag">{t("active")}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      width: "15%",
      align: "center" as "center",
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      render: (id: string, { isActive }: any) => (
        <CheckPermissions permission={PERMS.TOGGLE_CATEGORY_ACTIVATION}>
          <CustomPopconfirm
            onConfirm={async () => {
              try {
                const isActiveCategory = await handleDeActivationCategory(id);
                customNotification(
                  "success",
                  isActiveCategory
                    ? t("category-activated-successfully")
                    : t("category-deactivated-successfully"),
                );
                refetch();
              } catch (error) {
                handleError(error as string | object);
              }
            }}
            title={
              isActive
                ? t("confirm-deactivate-category")
                : t("confirm-activate-category")
            }
          >
            <Switch
              className="custom-switch"
              defaultChecked
              checked={isActive}
            />
          </CustomPopconfirm>
        </CheckPermissions>
      ),
    },
    {
      width: 50,
      align: "center" as "center",
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id: string) => (
        <>
          <Dropdown
            overlay={
              <CheckPermissions permission={PERMS.UPDATE_CATEGORY}>
                <CustomMenu
                  items={[
                    { label: t("edit-category"), key: "edit-category" },
                    { label: t("delete-category"), key: "delete-category" },
                  ]}
                  onClick={({ domEvent, key, keyPath }) =>
                    handleMenu({ domEvent, key, keyPath, id })
                  }
                />
              </CheckPermissions>
            }
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </Dropdown>
        </>
      ),
    },
  ];

  const ExpandedRowRender = ({ id }: any) => {
    const {
      data,
      isLoading: subLoading,
      refetch,
    } = useGetCategoryListQuery({
      filters: {
        and: [{ key: "parent", value: id, comparison: Comparison.Equal }],
      },
      pagination: {
        limit: 1000,
      },
    });

    useEffect(() => {
      refetch();
    }, []);

    return (
      <CustomTable
        isNestedTable={true}
        showSearch={false}
        loading={subLoading}
        columns={columns}
        dataSource={data?.getCategoryList?.data}
        pagination={false}
        showHeader={false}
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => <ExpandedRowRender id={record.id} />,
          rowExpandable: (record) => record.hasChildren,
        }}
      />
    );
  };

  return (
    <div>
      <CustomTable
        filters={{
          handleCallback: async (filtersData: any) => {
            let mergedFilters = cloneObject(filtersData);
            const {
              filters: { or, and = [], search } = {
                or: undefined,
                and: [],
                search: undefined,
              },
            } = mergedFilters;

            mergedFilters.filters = {
              and: [...[parentFilter], ...and],
              or,
              search,
            };

            setPageSize(mergedFilters?.pagination?.offset?.pageSize);
            setCurrentPage(mergedFilters?.pagination?.offset?.page);
            setFiltersData(mergedFilters);
            // fetchDataFromApi(
            //   getAllCategoriesDataFun,
            //   setColumnsData,
            //   filtersData,
            // );
          },
          manualFilter: {
            applyAs: "and",
            comparison: "Equal",
            key: "parent",
            value: null,
          },
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],

          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
        }}
        totalItems={columnsData?.metadata?.total}
        loading={isLoading}
        columns={columns}
        permission={PERMS.ADD_NEW_CATEGORY}
        onClick={() => {
          setVisible(true);
          reFetchCategory();
        }}
        btnText={t("category.add-category")}
        dataSource={columnsData?.data}
        expandable={{
          expandedRowRender: (record) => <ExpandedRowRender id={record.id} />,
          rowExpandable: (record) => record.hasChildren,
        }}
        rowKey="id"
      />
      <AddCategory
        visible={visible}
        setVisible={setVisible}
        editId={editId}
        setEditId={setEditId}
        reFetchCategory={reFetchCategory}
        categoriesData={categoriesData?.getCategoriesTree}
      />
    </div>
  );
};

export default CategoriesList;
