import { Pagination, Space, Input, Select, Form, DatePicker } from "antd";
import Search from "antd/lib/input/Search";
import CustomLoader from "components/custom-loader/CustomLoader";
import CustomModal from "components/custom-modal/CustomModal";
import {
  ProgressStatus,
  useGetClientsTreeQuery,
  useLazyGetParticipatingCompaniesQuery,
} from "graphql/_generated/graphql";
import React, { useEffect, useState } from "react";
import ChallengeDataTable from "./ChallengeDataTable";
import styles from "features/challenge/css/ActivityDetail.module.scss";
import Icon from "@ant-design/icons";
import { SearchIcon } from "assets/icons/SearchIcon";
import { debounce } from "lodash";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import { Formik } from "formik";
import { useLoggedInUser } from "hooks/useLoggedInUser";
const { Option } = Select;
const { RangePicker } = DatePicker;

const DEBOUNCE_TIME = 500;

type Props = {
  handleSearchInput: any;
  setSelectedCompany?: any;
  isCompanyFilter?: boolean;
  handleStatus?: any;
  isStatusFilter?: boolean;
  handleRangeFilter?: any;
  isDateFilter?: boolean;
};

const DataTableFilters: React.FC<Props> = ({
  handleSearchInput,
  setSelectedCompany,
  isCompanyFilter = true,
  handleStatus,
  isStatusFilter = false,
  handleRangeFilter,
  isDateFilter = false,
}: any) => {
  const { isClient } = useLoggedInUser();

  const { data: companiesData } = useGetClientsTreeQuery(
    { clientId: null },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const statuses = Object.keys(ProgressStatus);

  const handleSearch = debounce((e) => {
    handleSearchInput(e.target.value);
  }, DEBOUNCE_TIME);

  return (
    <div className={styles["data-header"]}>
      <Input
        className={styles["search-input"]}
        style={{
          borderStyle: "solid",
          borderRadius: 10,
          borderColor: "#ADADAD",
          width: 270,
        }}
        onChange={handleSearch}
        placeholder={"Search by Name"}
        addonAfter={<SearchIcon />}
      />
      <div className={styles["filters-container"]}>
        {isCompanyFilter && !isClient ? (
          <Formik initialValues={{ companies: null }} onSubmit={() => {}}>
            {({ setFieldValue, values }) => (
              <Form>
                <CustomTreeSelect
                  allowClear
                  showArrow
                  noMargin
                  treeNodeFilterProp="companyName"
                  treeLine={true && { showLeafIcon: false }}
                  className={`filter-select-dark ${styles["filter-padding"]}`}
                  name="companies"
                  treeData={companiesData?.getClientsTree}
                  placeholder={"All Companies"}
                  fieldNames={{ label: "companyName", value: "id" }}
                  onChange={(values) => {
                    setFieldValue("companies", values);
                    setSelectedCompany?.(values);
                  }}
                />
              </Form>
            )}
          </Formik>
        ) : null}
        {isDateFilter ? (
          <RangePicker
            className="filter-date-picker-dark"
            onChange={(dates, dateStrings) =>
              handleRangeFilter(dates, dateStrings)
            }
          />
        ) : null}

        {isStatusFilter ? (
          <Select
            allowClear
            placeholder="All Status"
            className="filter-select-dark"
            onChange={handleStatus}
            onClear={() => {}}
          >
            {statuses?.map((item: string) => (
              <Option value={item}>
                {item === ProgressStatus.InProgress ? "In Progress" : item}
              </Option>
            ))}
          </Select>
        ) : null}
      </div>
    </div>
  );
};

export default DataTableFilters;
