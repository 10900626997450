import { MoreOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Space } from "antd";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import CustomButton from "components/custom-button/CustomButton";
import CustomMenu from "components/custom-menu/CustomMenu";
import CustomTable from "components/custom-table/CustomTable";
import { getTranslatedValue, PERMS } from "helpers";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import {
  useGetPillarListQuery,
  useLazyGetPillarListQuery,
} from "graphql/_generated/graphql";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddPillar from "../add-pillar/AddPillar";
import styles from "./PillarList.module.scss";
import { useErrorHandler } from "hooks/useErrorHandler";

export default function PillarList() {
  const { t } = useTranslation();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<any>();
  const [visible, setVisible] = useState<boolean>(false);
  const [editId, setEditId] = useState<string | null>(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { isFetching, refetch } = useGetPillarListQuery();
  const [getAllPillarsDataFun] = useLazyGetPillarListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleError } = useErrorHandler();

  const handleMenu = ({ key, id }: any) => {
    if (key === "edit-pillar") {
      handleEdit(id);
    }
  };

  const handleEdit = (id: string) => {
    setEditId(id);
    setVisible(true);
  };

  useEffect(() => {
    fetchDataFromApi(getAllPillarsDataFun, setColumnsData, filtersData).catch(
      (e) => handleError(e),
    );
  }, [isFetching]);

  //preparing the column names which is same as the api data name
  const columns = [
    {
      title: t("id"),
      key: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text: any, record: any, index: number) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: t("name"),
      dataIndex: "title",
      key: "title",
      sorter: { multiple: 1 },
      render: (title: string, item: any) => (
        <Space>
          <CustomAvatar imgSrc={item?.icon} />
          <div className="cut-text">{getTranslatedValue(item, "title")}</div>
        </Space>
      ),
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: { multiple: 1 },
      render: (createdAt: any) =>
        moment(createdAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      align: "center" as "center",
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id: string) => (
        <>
          <Dropdown
            overlay={
              <CheckPermissions permission={PERMS.UPDATE_PILLAR}>
                <CustomMenu
                  items={[{ label: t("edit-pillar"), key: "edit-pillar" }]}
                  onClick={({ domEvent, key, keyPath }) =>
                    handleMenu({ domEvent, key, keyPath, id })
                  }
                />
              </CheckPermissions>
            }
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </Dropdown>
        </>
      ),
    },
  ];

  return (
    <div>
      {/* <div className={styles["pillars"]}>
        <CheckPermissions permission={PERMS.ADD_NEW_PILLAR}>
          <CustomButton
            className="primary-btn"
            onClick={() => {
              setVisible(true);
              document.getElementsByTagName("form")[0].reset();
            }}
          >
            {t("pillar.add-pillar")}
          </CustomButton>
        </CheckPermissions>
      </div> */}
      <CustomTable
        filters={{
          handleCallback: async (filtersData: any) => {
            setPageSize(filtersData?.pagination?.offset?.pageSize);
            setCurrentPage(filtersData?.pagination?.offset?.page);
            fetchDataFromApi(getAllPillarsDataFun, setColumnsData, filtersData);
            setFiltersData(filtersData);
          },

          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],
        }}
        totalItems={columnsData?.metadata?.total}
        loading={isLoading}
        columns={columns}
        dataSource={columnsData?.data}
      />
      <AddPillar
        visible={visible}
        setVisible={setVisible}
        editId={editId}
        setEditId={setEditId}
      />
    </div>
  );
}
