import { Button, Popover, Space } from "antd";
import { ErrorMessage } from "formik";
import { useState } from "react";
import {
  HexColorInput,
  HexColorPicker,
  HslaStringColorPicker,
} from "react-colorful";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import { CustomFastField } from "../custom-fast-field/CustomFastField";
import styles from "./CustomColorPicker.module.scss";
import "./CustomColorPicker.module.scss";
import Icon, { CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";

type Props = {
  name: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  label?: string;
  color: string;
};

const ViwellColorPicker = (props: Props) => {
  const { errorT } = useErrorTranslation();
  const [isOpen, toggle] = useState<boolean>(false);

  const handleChange = (e: string) => {
    props.setFieldValue(props.name, e);
    // toggle(false);
    return e;
  };

  return (
    <div className={styles["form-control"]}>
      <div className="custom-viwell-picker">
        <CustomFastField
          placeholder="Select Color"
          parentClassName="custom-viwell-picker--fastField"
          {...props}
        />
        <Popover
          placement="bottomRight"
          open={isOpen}
          showArrow={false}
          content={
            <Content
              color={props?.color}
              handleChange={handleChange}
              toggle={toggle}
            />
          }
          trigger="click"
        >
          <div
            className={"custom-viwell-picker--swatch"}
            style={{ backgroundColor: props?.color }}
            onClick={() => toggle(true)}
          />
        </Popover>
      </div>
    </div>
  );
};

export default ViwellColorPicker;

interface contentProps {
  color: string;
  handleChange: (e: string) => string;
  toggle: any;
}
export const Content = (props: contentProps) => {
  return (
    <div className={"popover"}>
      <Space className="popover--header">
        <label className="label">Color Picker</label>
        <CloseOutlined onClick={() => props.toggle(false)} />
      </Space>
      <HexColorPicker
        color={props?.color}
        onChange={props?.handleChange}
        className={"popover--hexColorPicker"}
      />
      <Space className="popover--form">
        <div
          className={"custom-viwell-picker--swatch"}
          style={{ backgroundColor: props?.color }}
        />
        <CustomFastField name="color" />
      </Space>
    </div>
  );
};
