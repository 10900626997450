import { Tabs } from "antd";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import "./CustomTabs.module.scss";
interface CustomTabsProps {
  tabs: {
    label: string;
    tab?: React.ReactNode;
  }[];
}

const CustomTabs: React.FunctionComponent<CustomTabsProps> = ({ tabs }) => {
  const { TabPane } = Tabs;
  const navigate = useNavigate();
  return (
    <Tabs className="custom-tabs" onChange={() => navigate("#tabs")}>
      {tabs.map((item) => (
        <TabPane tab={item.label} key={item.label}>
          {item.tab}
        </TabPane>
      ))}
    </Tabs>
  );
};

export default CustomTabs;
