import { MoreOutlined } from "@ant-design/icons";
import { Space, TableColumnsType, Tag, Form, Typography, Tree } from "antd";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import CustomButton from "components/custom-button/CustomButton";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomMenu from "components/custom-menu/CustomMenu";
import { customNotification } from "components/custom-notification/CustomNotification";
import CustomTable from "components/custom-table/CustomTable";
import { errorFormat, getLanguage, PERMS } from "helpers";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import {
  useGetCategoriesTreeQuery,
  useGetChallengeListQuery,
  useGetClientsTreeQuery,
  useLazyGetChallengeListQuery,
} from "graphql/_generated/graphql";
import type { DataNode } from "antd/es/tree";

import moment from "moment";
import { DefaultRecordType } from "rc-table/lib/interface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useChallenge } from "../hooks/useChallenge";
import AddLanguage from "../language/AddLanguage";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import { Formik } from "formik";
import { usePillarIdAndName } from "hooks";
import styles from "features/challenge/css/Activity.module.scss";
import DownOutlined from "@ant-design/icons/DownOutlined";

import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import CustomConfirmModal from "../../../components/custom-confirm-modal/CustomConfirmModal";
import { ChallengeTypes } from "../data/ChallengeTypes";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { useErrorHandler } from "hooks/useErrorHandler";
interface ActivityListProps {}

const ChallengesList: React.FunctionComponent<ActivityListProps> = (props) => {
  const { t } = useTranslation();
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<any>();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [languageVisible, setLanguageVisible] = useState<boolean>(false);
  const [deactivateVisible, setDeactivateVisible] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [challengeData, setChallengeData] = useState<any>(null);
  const { data: companiesData } = useGetClientsTreeQuery(
    { clientId: null },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { pillarsData } = usePillarIdAndName();
  const { isSuperAdmin, isViwellAdmin } = useLoggedInUser();

  const { data: categoriesData } = useGetCategoriesTreeQuery(
    {
      pillarId: null,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { isFetching, refetch } = useGetChallengeListQuery();
  const [getChallengeListDataFun] = useLazyGetChallengeListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleActivationChallenge, handleDeleteChallenge } = useChallenge();
  const { handleError } = useErrorHandler();
  const navigate = useNavigate();

  useEffect(() => {
    fetchDataFromApi(
      getChallengeListDataFun,
      setColumnsData,
      filtersData,
    ).catch((e) => handleError(e));
  }, [isFetching]);
  const handleMenu = ({ domEvent, key, keyPath, id, record }: any) => {
    if (key === "challenge-detail") {
      navigate("/challenges/challenge-detail", {
        state: {
          id,
          language: record.languageText,
        },
      });
    } else if (key === "add-language") {
      setChallengeData(record);
      setLanguageVisible(true);
    } else if (key === "delete-challenge") {
      setChallengeData(record);
      setDeleteVisible(true);
    } else if (["activate-challenge", "deactivate-challenge"].includes(key)) {
      setChallengeData(record);
      setDeactivateVisible(true);
    } else if (key === "make-a-copy") {
      navigate("/add-challenge", {
        state: {
          editId: record.id,
          language: record.language,
          isCloned: true,
        },
      });
    } else if (key === "edit-challenge") {
      navigate("/add-challenge", {
        state: {
          editId: record.id,
          language: record.language,
        },
      });
    } else if (key === "edit-language") {
      navigate("/add-challenge", {
        state: {
          editId: record.key,
          isAddLanguage: true,
          languageKey: record.language,
          language: record.languageText,
        },
      });
    }
  };

  const handleDeactivateChallenge = async () => {
    try {
      const isActivityActive = await handleActivationChallenge(
        challengeData?.id,
      );
      setDeactivateVisible(false);
      refetch();
      customNotification(
        "success",
        isActivityActive
          ? "Challenge activated successfully"
          : "Challenge deactivated successfully",
      );
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const handleChallengeDelete = async () => {
    try {
      await handleDeleteChallenge(challengeData?.id);
      setDeleteVisible(false);
      refetch();
      customNotification("success", "Challenge deleted successfully");
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const getChallengeStatuses = (record: any) => {
    const { startDate, expiryDate } = record;
    const challengeStartDate = moment(startDate);
    const challengeExpriyDate = moment(expiryDate);
    const todayDate = moment();

    const isExpired = challengeExpriyDate < todayDate;
    const isInProgress =
      challengeStartDate <= todayDate && challengeExpriyDate >= todayDate;
    const isPlanned = challengeStartDate > todayDate;
    const isActive = record.isActive;
    return { isExpired, isInProgress, isPlanned, isActive };
  };

  const columns: TableColumnsType<DefaultRecordType> = [
    {
      title: `${t("id")}`,
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, _record, index) => (
        <Typography className={styles["list-id-content"]}>
          {_record.hasChildren
            ? (currentPage - 1) * pageSize + index + 1
            : null}
        </Typography>
      ),
    },
    {
      title: `Challenge Title`,
      key: "title",
      dataIndex: "title",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => {
        const treeData: DataNode[] = [
          {
            title: record?.title,
            key: "tree-0",
          },
        ];
        return record?.hasChildren ? (
          <Space>
            <CustomAvatar shape="square" imgSrc={record?.image} />
            <span>{record.title}</span>
          </Space>
        ) : (
          <Tree
            showLine
            switcherIcon={<DownOutlined />}
            defaultExpandedKeys={["tree-0"]}
            treeData={treeData}
          />
        );
      },
    },
    {
      title: "Language",
      key: "languageText",
      dataIndex: "languageText",
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "type",
      render: (type) => {
        const data = ChallengeTypes.find((item) => item.id === type);
        if (data) {
          return data.title.split(" ")[0];
        }
      },
    },
    {
      title: "Pillar",
      key: "pillar.title",
      dataIndex: "pillar",
      render: (_text: any, record: any, index: number) => (
        <span>{record?.pillar?.title}</span>
      ),
    },
    {
      title: `Starting Date`,
      dataIndex: "startDate",
      key: "startDate",
      sorter: {
        multiple: 1,
      },
      render: (startDate: string) => moment(startDate).format("MMMM Do YYYY"),
    },
    {
      title: `Expiry Date`,
      dataIndex: "expiryDate",
      key: "expiryDate",
      sorter: {
        multiple: 1,
      },
      render: (expiryDate: string) => moment(expiryDate).format("MMMM Do YYYY"),
    },
    {
      title: `Modified Date`,
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) => moment(updatedAt).format("MMMM Do YYYY"),
    },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      render: (isActive: boolean, record) => {
        if (isActive) {
          const { isPlanned, isInProgress, isExpired } =
            getChallengeStatuses(record);
          if (isPlanned) {
            return (
              <Tag className={`${styles["status-circle-div"]} planned-tag`}>
                <div className={styles["planned-circle"]}></div>
                PLANNED
              </Tag>
            );
          } else if (isInProgress) {
            return (
              <Tag className={`${styles["status-circle-div"]} inprogress-tag`}>
                <div className={styles["inprogress-circle"]}></div>
                IN PROGRESS
              </Tag>
            );
          } else if (isExpired) {
            return <Tag className="de-active-tag">EXPIRED</Tag>;
          }
        } else return <Tag className="de-active-tag">DEACTIVATED</Tag>;
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "Actions",
      align: "center",
      render: (id: string, record: any) => {
        const langActions = [{ key: "edit-language", label: "Edit Language" }];

        const { isPlanned, isInProgress, isExpired, isActive } =
          getChallengeStatuses(record);

        const mainActions = [
          { label: "Challenge Details", key: "challenge-detail" },
          {
            label: "Edit Challenge",
            key: "edit-challenge",
            disabled: !isActive || !isPlanned,
          },
          { label: "Add New Language", key: "add-language" },
          {
            label: "Make a Copy",
            key: "make-a-copy",
            disabled: (isActive && isInProgress) || !isActive,
          },
          {
            label: `${isActive ? "Deactivate" : "Activate"} Challenge`,
            key: `${isActive ? "deactivate" : "activate"}-challenge`,
            disabled: isActive && !(isInProgress || isPlanned),
          },
          {
            label: "Delete Challenge",
            key: "delete-challenge",
            disabled: isActive && isInProgress,
          },
        ];
        return (
          <>
            <CustomDropDown
              overlay={
                <CheckPermissions permission={PERMS.UPDATE_CHALLENGE}>
                  <CustomMenu
                    items={record?.hasChildren ? mainActions : langActions}
                    onClick={({ domEvent, key, keyPath }) =>
                      handleMenu({ domEvent, key, keyPath, id, record })
                    }
                  />
                </CheckPermissions>
              }
            >
              <CustomButton type="link" className="link-btn">
                <MoreOutlined />
              </CustomButton>
            </CustomDropDown>
          </>
        );
      },
    },
  ];
  return (
    <>
      <CustomTable
        columns={columns}
        filters={{
          handleCallback: async (filtersData: any) => {
            await fetchDataFromApi(
              getChallengeListDataFun,
              setColumnsData,
              filtersData,
            );
            setPageSize(filtersData?.pagination?.offset?.pageSize);
            setCurrentPage(filtersData?.pagination?.offset?.page);
            setFiltersData(filtersData);
          },
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
              placeholder: "",
            },
          ],
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
          manualFilters: [
            {
              key: "pillar.id",
              value: "d",
              comparison: "Equal",
              applyAs: "and",
              render: (onChangeCallback, value) => (
                <Formik initialValues={{ pillar: null }} onSubmit={() => {}}>
                  {({ setFieldValue, values }) => (
                    <Form>
                      <CustomSelectField
                        onChange={onChangeCallback}
                        allowClear
                        marginBtm={false}
                        className="filter-select"
                        name="pillar"
                        placeholder="Pillar Type: All"
                        options={pillarsData}
                        value={value}
                      />
                    </Form>
                  )}
                </Formik>
              ),
            },
            {
              key: "type",
              value: "d",
              comparison: "Equal",
              applyAs: "and",
              render: (onChangeCallback, value) => (
                <Formik initialValues={{ type: null }} onSubmit={() => {}}>
                  {({ setFieldValue, values }) => (
                    <Form>
                      <CustomSelectField
                        name="type"
                        className="filter-select"
                        allowClear
                        placeholder="Type"
                        marginBtm={false}
                        options={ChallengeTypes}
                        onChange={onChangeCallback}
                        fieldNames={{ label: "title", value: "id" }}
                        value={value}
                      />
                    </Form>
                  )}
                </Formik>
              ),
            },
            ...(isSuperAdmin || isViwellAdmin
              ? [
                  {
                    key: "companies",
                    value: "d",
                    comparison: "Equal",
                    applyAs: "and",
                    render: (onChangeCallback: any, value: string) => (
                      <Formik
                        initialValues={{ company: null }}
                        onSubmit={() => {}}
                      >
                        {({ setFieldValue, values }) => (
                          <Form>
                            <CustomTreeSelect
                              allowClear
                              showArrow
                              noMargin
                              treeNodeFilterProp="companyName"
                              treeLine={true && { showLeafIcon: false }}
                              className="filter-select"
                              name="company"
                              treeData={companiesData?.getClientsTree}
                              placeholder={"Company: All"}
                              fieldNames={{ label: "companyName", value: "id" }}
                              onChange={onChangeCallback}
                              value={value}
                            />
                          </Form>
                        )}
                      </Formik>
                    ),
                  },
                ]
              : []),
          ],
        }}
        totalItems={columnsData?.metadata?.total}
        loading={isLoading}
        permission={PERMS.ADD_NEW_CHALLENGE}
        onClick={() => setLanguageVisible(true)}
        headerTitle="Challenges"
        btnText="Add New Challenge"
        dataSource={
          columnsData?.data.map((item: any) => {
            return {
              ...item,
              languageText: item.language,
              hasChildren: true,
              children:
                item?.translations && Object.keys?.(item?.translations)
                  ? Object.keys?.(item?.translations)?.map((record, index) => {
                      return {
                        ...item,
                        id: `${item?.id}-${index}`,
                        key: item?.id,
                        language: record,
                        title: item?.translations[record].title,
                        languageText: getLanguage(record),
                      };
                    })
                  : null,
            };
          }) ?? []
        }
      />
      {languageVisible && (
        <AddLanguage
          visible={languageVisible}
          setVisible={setLanguageVisible}
          data={challengeData}
        />
      )}
      {deactivateVisible && (
        <CustomConfirmModal
          title={`${challengeData?.isActive ? "Dea" : "A"}ctivate Challenge`}
          visible={deactivateVisible}
          setVisible={setDeactivateVisible}
          onSubmit={handleDeactivateChallenge}
          desc={`Are you sure you want to ${
            challengeData?.isActive ? "deactivate" : "activate"
          } this challenge ${challengeData?.title} ${
            challengeData?.isActive ? "and clear all users progress" : ""
          }?`}
        />
      )}
      {deleteVisible && (
        <CustomConfirmModal
          title={`Delete Challenge`}
          visible={deleteVisible}
          setVisible={setDeleteVisible}
          onSubmit={handleChallengeDelete}
          cancelText={"Cancel"}
          saveText={"Yes, Delete"}
          desc={`Are you sure you want to delete the challenge ? All associated data including user progress, leaderboard, and rankings will be lost associated with this challenge`}
        />
      )}
    </>
  );
};

export default ChallengesList;
