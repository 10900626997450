import { List, Pagination } from "antd";
import CustomModal from "components/custom-modal/CustomModal";
import DataTableFilters from "features/challenge/challenge-detail/DataTableFilters";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "features/rewards/css/CompanyStats.module.scss";
import {
  TierCompanyList,
  useLazyGetCompaniesListByTierQuery,
} from "graphql/_generated/graphql";
import CompanyItem from "./CompanyItem";

interface CompanyStatsDetailsProps {
  tierId: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompanyStatsDetails: React.FunctionComponent<
  CompanyStatsDetailsProps
> = ({ visible, setVisible, tierId }) => {
  const { t } = useTranslation();

  const [data, setData] = useState<TierCompanyList>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");
  const [pagination, setPagination] = useState<any>({
    limit: 10,
    offset: 0,
  });

  const [getCompanyListFun, { isFetching }] =
    useLazyGetCompaniesListByTierQuery();

  useEffect(() => {
    fetchCompaniesData();
  }, [currentPage, pagination, searchText]);

  const fetchCompaniesData = async () => {
    try {
      const { getCompaniesListByTier } = await getCompanyListFun({
        tierId: tierId,
        pagination,
        search: searchText,
      }).unwrap();
      setData(getCompaniesListByTier);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchInput = (value: string) => {
    setSearchText(value);
    setPagination({
      limit: 10,
      offset: 0,
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <CustomModal
      title={`${t("companies")} (${data?.metadata.total ?? 0})`}
      onCancel={handleCancel}
      visible={visible}
      footer={null}
      width={720}
    >
      <DataTableFilters
        isCompanyFilter={false}
        handleSearchInput={handleSearchInput}
      />
      <div className={styles["desc-container"]}>
        <List
          dataSource={data?.data}
          loading={isFetching}
          renderItem={(item, index) => (
            <CompanyItem
              id={index + 1 + pagination?.offset}
              title={item.companyName}
              image={item.image ?? ""}
              users={item.usersCount}
            />
          )}
        />
      </div>
      <div className={"pagination-margin"}>
        <Pagination
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            setPagination({
              offset: (page - 1) * pageSize,
              limit: pageSize,
            });
          }}
          current={currentPage}
          pageSize={pagination?.limit}
          showTotal={(total, range) => (
            <span>
              Showing&nbsp;
              <b>
                {range[0]}-{range[1]}
              </b>
              &nbsp;of <b>{total}</b>&nbsp; items
            </span>
          )}
          total={data?.metadata?.total || 0}
        />
      </div>
    </CustomModal>
  );
};

export default CompanyStatsDetails;
