import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setPagination } from "store/navigationSlice";
import { usePersistedState } from "./usePersistedState";

export const defaultPagination = {
  offset: { page: 1, pageSize: 10 },
};

const usePagination = (paginationType: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const pagination = usePersistedState(
    "pagination",
    location,
    defaultPagination,
  );

  const handlePagination = (currentPagination: any) => {
    navigate("#persist");
    dispatch(
      setPagination({
        location,
        ...currentPagination,
      }),
    );
  };

  const handleCursorPaginationValue = (type: string) => {
    setPagination({
      ...pagination,
      cursor: {
        type,
      },
    });
  };

  return { pagination, handlePagination, handleCursorPaginationValue };
};

export default usePagination;
