import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik, FormikValues } from "formik";
import CustomModal from "components/custom-modal/CustomModal";
import ModalFooter from "components/custom-modal/ModalFooter";
import { customNotification } from "components/custom-notification/CustomNotification";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import { errorFormat } from "helpers";
import { useGetEapListQuery, EapCreate } from "graphql/_generated/graphql";
import { useEAP } from "../useEAP";
import { eapSchema } from "validations/schema";
import countriesData from "assets/data/countries.json";
import CustomPhoneInput from "components/form/custom-phone-input/CustomPhoneInput";
import { useErrorHandler } from "hooks/useErrorHandler";

interface AddEAPProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editId?: string | null;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
  data?: EapCreate | null;
}

const AddEAP: React.FunctionComponent<AddEAPProps> = ({
  visible,
  editId,
  setEditId,
  data,
  setVisible,
}) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { handleAddNewEAP, handleUpdateEAP, isLoading } = useEAP();
  const { refetch } = useGetEapListQuery();

  // Default to an empty object if data is null or undefined
  const initialValues: Partial<EapCreate> = {
    countryCode: undefined,
    number: "",
    isGlobal: false,
    ...(editId ? data : {}),
  };

  const handleCancel = () => {
    setVisible(false);
    setEditId(null);
  };

  return (
    <CustomModal
      width={600}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      title={editId ? t("edit-eap") : t("add-new-eap")}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={eapSchema}
        onSubmit={async (
          values: FormikValues,
          { resetForm }: { resetForm: () => void },
        ): Promise<void> => {
          try {
            if (!editId) {
              await handleAddNewEAP({ create: values as EapCreate });
              customNotification("success", t("eap-added-successfully"));
            } else {
              await handleUpdateEAP({
                updateObj: values as EapCreate,
                id: editId,
              });
              customNotification("success", t("eap-updated-successfully"));
              setEditId(null);
            }
            setVisible(false);
            resetForm();
            refetch();
          } catch (error) {
            handleError(error as string | object);
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            {!values.countryCode && (
              <CustomSelectField
                label={t("is-global")}
                name="isGlobal"
                placeholder={t("is-global")}
                options={[
                  { value: true, label: t("yes") },
                  { value: false, label: t("no") },
                ]}
              />
            )}
            {!values.isGlobal && (
              <CustomSelectField
                label={t("select-country")}
                className="form-select"
                name="countryCode"
                placeholder={t("select-country")}
                options={countriesData}
                fieldNames={{ label: "name", value: "code" }}
              />
            )}
            <CustomPhoneInput
              value={values.number}
              setFieldValue={setFieldValue}
              name="number"
              label={t("phone-number")}
              placeholder={t("enter-phone-number")}
              countryCode={values.countryCode}
            />
            <ModalFooter
              saveText={t("save-eap")}
              onCancel={handleCancel}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddEAP;
