import { Col, Row } from "antd";
import { DaysSvg, DollarSvg, PercentageSvg } from "assets/icons";
import {
  FilterValuesProps,
  WidgetExtraProps,
} from "features/company-dashboard/types";
import { MetricType, MetricUnit } from "graphql/_generated/graphql";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../CompanyDashboard.module.scss";
import { SectionHeader } from "../SectionHeader";
import { Metric } from "./Metric";
import { InfoTextProps, OrgMetricsProps } from "./types";

export const OrganizationMetrices: FunctionComponent<
  InfoTextProps & WidgetExtraProps
> = ({ selectedInfoText, setSelectedInfoText, companyRegisteredDate }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<FilterValuesProps>({
    timeFilter: "monthly",
  });

  const renderInfoTexts = (metricName: string) => [
    `${t("monitor-dedicated-widgets")} ${metricName}`,
    t("input-corresponding-values"),
    t("metrics-evolve-in-organisation"),
    t("organisations-crucial-aspects"),
  ];

  const ORGANIZATION_METRICES: {
    [key: string]: OrgMetricsProps;
  } = {
    "staff-turnover": {
      title: t("staff-turnover"),
      icon: <PercentageSvg />,
      type: MetricType.StaffTurnover,
      unit: MetricUnit.Percentage,
      domain: [0, 100],
      infoTexts: renderInfoTexts(t("staff-turnover")),
    },
    "absenteeism-cost": {
      title: t("absenteeism-cost"),
      icon: <DollarSvg />,
      type: MetricType.AbsenteeismCost,
      unit: MetricUnit.Number,
      infoTexts: renderInfoTexts(t("absenteeism-cost")),
    },
    "healthcare-cost": {
      title: t("healthcare-cost"),
      icon: <DollarSvg />,
      type: MetricType.HealthCare,
      unit: MetricUnit.Number,
      infoTexts: renderInfoTexts(t("healthcare-cost")),
    },
    "average-tenure": {
      title: t("average-tenure"),
      icon: <DaysSvg />,
      type: MetricType.AverageTenure,
      unit: MetricUnit.Days,
      infoTexts: renderInfoTexts(t("average-tenure")),
    },
    "overtime-cost": {
      title: t("overtime-cost"),
      icon: <DollarSvg />,
      type: MetricType.OvertimeCost,
      unit: MetricUnit.Number,
      infoTexts: renderInfoTexts(t("overtime-cost")),
    },
    "training-cost": {
      title: t("training-cost"),
      icon: <DollarSvg />,
      type: MetricType.TrainingCost,
      unit: MetricUnit.Number,
      infoTexts: renderInfoTexts(t("training-cost")),
    },
  };

  return (
    <>
      <div className={styles["checkins-chart"]}>
        <SectionHeader
          visible={visible}
          setVisible={setVisible}
          sectionTitle={t("organisations-metrics")}
          isAdvanceFilters={false}
          initialValues={selectedValues}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
        />
        <div
          className={`${styles["checkins-list"]} ${styles["org-list-wrap"]}`}
        >
          <Row gutter={[16, 16]} className={styles["row-flex"]}>
            {Object.keys(ORGANIZATION_METRICES).map((item) => {
              return (
                <Col xs={24} lg={12} xl={8}>
                  <Metric
                    item={{
                      ...ORGANIZATION_METRICES[item],
                    }}
                    timeFilter={selectedValues.timeFilter || ""}
                    advanceFilterVisible={visible}
                    setSelectedInfoText={setSelectedInfoText}
                    selectedInfoText={selectedInfoText}
                    companyRegisteredDate={companyRegisteredDate}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </>
  );
};
