import { MoreOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import CustomButton from "components/custom-button/CustomButton";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomMenu from "components/custom-menu/CustomMenu";
import CustomTable from "components/custom-table/CustomTable";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import {
  EapCreate,
  EapList,
  useGetEapListQuery,
  useLazyGetEapListQuery,
} from "graphql/_generated/graphql";
import moment from "moment";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AddEAP from "../add-eap/AddEAP";
import CustomPopconfirm from "components/form/custom-pop-confirm/CustomPopConfirm";
import { Switch, Tag } from "antd";
import { useEAP } from "../useEAP";
import customNotification from "components/custom-notification/CustomNotification";
import { errorFormat } from "helpers";
import { useErrorHandler } from "hooks/useErrorHandler";

interface EAPsListProps {}

const EAPsList: React.FunctionComponent<EAPsListProps> = (props) => {
  //Initializing States
  const { t } = useTranslation();
  const [editId, setEditId] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] = useState<EapList>();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [eap, setEAP] = useState<EapCreate | null>(null);

  //Hooks
  const { isFetching, refetch } = useGetEapListQuery();
  const [getAllEAPsDataFun] = useLazyGetEapListQuery();
  const { fetchDataFromApi, isLoading } = useFetchDataFromApi();
  const { handleActivationEAP } = useEAP();
  const { handleError } = useErrorHandler();

  const handleMenu = ({ key, id, record }: any) => {
    if (key === "edit-eap") {
      setVisible(true);
      setEditId(id);
      setEAP(record);
    }
  };

  useEffect(() => {
    fetchDataFromApi(getAllEAPsDataFun, setColumnsData, filtersData).catch(
      (e) => handleError(e),
    );
  }, [isFetching]);

  const columns: ColumnsType<EapList["data"][0]> = [
    {
      title: t("id"),
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, _record, index) =>
        (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: t("country-code"),
      key: "countryCode",
      dataIndex: "countryCode",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, index) => (record.isGlobal ? t("global") : _text),
    },
    {
      title: t("phone-number"),
      key: "number",
      dataIndex: "number",
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 1,
      },
      render: (createdAt: string) => moment(createdAt).format("MMMM Do YYYY"),
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: {
        multiple: 1,
      },
      render: (updatedAt: string) => moment(updatedAt).format("MMMM Do YYYY"),
    },
    {
      title: t("status"),
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      render: (isActive: boolean) =>
        isActive ? (
          <Tag className="active-tag">{t("active")}</Tag>
        ) : (
          <Tag className="de-active-tag">{t("deactivated")}</Tag>
        ),
    },
    {
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (id: string, { isActive }: any) => (
        <CustomPopconfirm
          onConfirm={async () => {
            try {
              const isEAPActive = await handleActivationEAP(id);
              refetch();
              customNotification(
                "success",
                isEAPActive
                  ? t("eap-activated-successfully")
                  : t("eap-deactivated-successfully"),
              );
              refetch();
            } catch (error) {
              handleError(error as string | object);
            }
          }}
          title={
            isActive ? t("confirm-deactivate-eap") : t("confirm-activate-eap")
          }
        >
          <Switch className="custom-switch" defaultChecked checked={isActive} />
        </CustomPopconfirm>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "action",
      align: "center",
      width: "10%",
      render: (id: string, record) => (
        <>
          <CustomDropDown
            overlay={
              <CustomMenu
                items={[{ label: t("edit-eap"), key: "edit-eap" }]}
                onClick={({ domEvent, key, keyPath }) =>
                  handleMenu({ domEvent, key, keyPath, id, record })
                }
              />
            }
          >
            <CustomButton type="link" className="link-btn">
              <MoreOutlined />
            </CustomButton>
          </CustomDropDown>
        </>
      ),
    },
  ];

  return (
    <>
      <CustomTable
        filters={{
          handleCallback: async (filtersData: any) => {
            await fetchDataFromApi(
              getAllEAPsDataFun,
              setColumnsData,
              filtersData,
            );
            setPageSize(filtersData?.pagination?.offset?.pageSize);
            setCurrentPage(filtersData?.pagination?.offset?.page);
            setFiltersData(filtersData);
          },
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
            },
          ],
        }}
        rowKey="id"
        loading={isLoading}
        columns={columns}
        totalItems={columnsData?.metadata.total}
        onClick={() => setVisible(true)}
        headerTitle={t("eap-title")}
        btnText={t("eap-add-new")}
        dataSource={columnsData?.data}
      />
      <AddEAP
        visible={visible}
        setVisible={setVisible}
        editId={editId}
        setEditId={setEditId}
        data={eap}
      />
    </>
  );
};

export default EAPsList;
