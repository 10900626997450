import { convertTranslationArrToObj } from "helpers";
import {
  MutationAddNewBuildingArgs,
  useAddNewBuildingMutation,
  useGetBuildingListQuery,
} from "graphql/_generated/graphql";

export const useAddBuilding = () => {
  const [addBuildingFun, { isLoading }] = useAddNewBuildingMutation();
  const { refetch } = useGetBuildingListQuery();

  //this function is responsible for adding the building data
  const handleAddBuilding = async (values: MutationAddNewBuildingArgs) => {
    try {
      let translations = await convertTranslationArrToObj(
        values.create.translations,
      );

      let params = { create: { ...values.create, translations } };

      await addBuildingFun(params).unwrap();
      refetch();
    } catch (error: any) {
      throw error;
    }
  };

  return { isLoading, handleAddBuilding };
};
