import React from "react";
import Icon from "@ant-design/icons";
import "./ContainerHeading.module.scss";

interface HeadingProps {
  text: string;
  className?: string;
  icon?: () => JSX.Element;
}

const ContainerHeading: React.FC<HeadingProps> = (props) => {
  return (
    <h1 className={`viwell-containerHeading ${props?.className ?? ""}`}>
      {props.text}
      {props.icon && (
        <Icon
          component={props?.icon}
          className="viwell-containerHeading--icon"
        />
      )}
    </h1>
  );
};
export default ContainerHeading;
