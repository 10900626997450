import * as Yup from "yup";
import * as Validations from "validations/schema/common/validations";

export const addPartnerRewardSchema = Yup.object().shape({
  name: Validations.requiredFieldWithoutSpaces("name"),
  startDate: Yup.mixed().when("isDateEnable", {
    is: true,
    then: Validations.required("start-date"),
  }),
  endDate: Yup.mixed().when("isDateEnable", {
    is: true,
    then: Validations.required("end-date"),
  }),
});

export const ImportBulkRewardsSchema = Yup.object().shape({
  rewards: Yup.array().of(
    Yup.object().shape({
      ...addPartnerRewardSchema.fields,
      isDateEnable: Validations.requiredBooleanValidation("Enable Date"),
    }),
  ),
});
