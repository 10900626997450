export const BellSvg = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5146 13.9998L3.6946 12.8178C4.0726 12.4398 4.2806 11.9378 4.2806 11.4038V6.72683C4.2806 5.36983 4.8706 4.07283 5.9006 3.17083C6.9386 2.26083 8.2606 1.86083 9.6376 2.04183C11.9646 2.35083 13.7196 4.45483 13.7196 6.93683V11.4038C13.7196 11.9378 13.9276 12.4398 14.3046 12.8168L15.4856 13.9998H2.5146ZM10.9996 16.3408C10.9996 17.2398 10.0836 17.9998 8.9996 17.9998C7.9156 17.9998 6.9996 17.2398 6.9996 16.3408V15.9998H10.9996V16.3408ZM17.5206 13.2078L15.7196 11.4038V6.93683C15.7196 3.45583 13.2176 0.498833 9.8996 0.0598333C7.9776 -0.196167 6.0376 0.390833 4.5826 1.66683C3.1186 2.94883 2.2806 4.79283 2.2806 6.72683L2.2796 11.4038L0.478603 13.2078C0.00960291 13.6778 -0.129397 14.3768 0.124603 14.9898C0.379603 15.6038 0.972603 15.9998 1.6366 15.9998H4.9996V16.3408C4.9996 18.3588 6.7936 19.9998 8.9996 19.9998C11.2056 19.9998 12.9996 18.3588 12.9996 16.3408V15.9998H16.3626C17.0266 15.9998 17.6186 15.6038 17.8726 14.9908C18.1276 14.3768 17.9896 13.6768 17.5206 13.2078Z"
        fill="#262626"
      />
    </svg>
  );
};
