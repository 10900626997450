import { CloudDownloadOutlined } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import cloudUpload from "assets/images/cloud-upload.svg";
import CustomButton from "components/custom-button/CustomButton";
import ModalFooter from "components/custom-modal/ModalFooter";
import CustomTreeSelect from "components/form/custom-tree-select/CustomTreeSelect";
import styles from "features/users/css/InviteMultipleUsers.module.scss";
import { Form, Formik } from "formik";
import {
  useGetClientsTreeQuery,
  useGetEmployeeListQuery,
} from "graphql/_generated/graphql";
import { normalizeKeys } from "helpers";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  InviteMultipleUsersSchemaForClient,
  InviteMultipleUsersSchemaForSuperAdmin,
} from "validations/schema";
import * as XLSX from "xlsx";
import { showBulkUploadPreviewDetails } from "../hooks/useInviteMultipleUsers";
import BulkUsersPreview from "./preview/BulkUsersPreview";
type Props = {
  setVisible: (x: boolean) => void;
  employerId?: string | undefined;
};

const InviteMultipleUsers = ({ setVisible, employerId }: Props) => {
  const { t } = useTranslation();
  const { refetch } = useGetEmployeeListQuery();
  const [bulkUsersContent, setBulkUsersContent] = useState<any>();
  const [showPreview, setShowPreview] = useState<boolean>(false);

  // const { data: companiesData } = useGetClientsTreeQuery();
  const { data: companiesData } = useGetClientsTreeQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { isSuperAdmin, isClient } = useLoggedInUser();

  //initial values object
  const initialObj: any = {
    parentId: "",
    users: [
      {
        email: "",
        position: "",
        building: "",
        department: "",
      },
    ],
  };

  const [initialValues, setInitialValues] = useState(initialObj);
  const { handleError } = useErrorHandler();

  useEffect(() => {
    if (employerId) {
      setInitialValues({ ...initialValues, parentId: employerId });
    }
  }, [employerId]);

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleRefetchEmployees = () => {
    refetch();
    setShowPreview(false);
    setVisible(false);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={
          isSuperAdmin
            ? InviteMultipleUsersSchemaForSuperAdmin
            : InviteMultipleUsersSchemaForClient
        }
        onSubmit={async (values, { resetForm }) => {}}
      >
        {({ setFieldValue, errors, touched, values }) => {
          return (
            <Form>
              {!isClient && (
                <CustomTreeSelect
                  allowClear
                  showArrow
                  disabled={!!employerId}
                  treeNodeFilterProp="companyName"
                  treeLine={true && { showLeafIcon: false }}
                  label={t("company")}
                  className="form-select"
                  name="parentId"
                  treeData={companiesData?.getClientsTree}
                  placeholder={t("select-company")}
                  fieldNames={{ label: "companyName", value: "id" }}
                  onChange={(values) => {
                    setFieldValue("parentId", values);
                  }}
                  required
                />
              )}
              <div className={styles["form-control"]}>
                <Dragger
                  maxCount={1}
                  multiple={false}
                  accept=".xlsx,.xls"
                  customRequest={dummyRequest}
                  onChange={async (e: any) => {
                    try {
                      const file = e.file.originFileObj;

                      const data = await file.arrayBuffer();
                      const workbook = await XLSX.read(data);
                      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                      const jsonData = await XLSX.utils.sheet_to_json(
                        worksheet,
                      );
                      const bulkUploadList = normalizeKeys(jsonData);
                      const mappedBulkUsersContent =
                        showBulkUploadPreviewDetails(bulkUploadList);
                      setFieldValue("users", bulkUploadList);

                      setBulkUsersContent({
                        title: file.name.replace(/.xlsx|.xls/gi, ""),
                        ...mappedBulkUsersContent,
                      });
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                >
                  <div className={styles["center-div"]}>
                    <img src={cloudUpload} alt="Upload" />
                    <h2 className={styles["select-file"]}>
                      {t("select-file")}
                    </h2>
                    <div className={styles["file-size"]}>{t("file-size")}</div>
                    <CustomButton className="primary-btn">
                      {t("select-file")}
                    </CustomButton>
                  </div>
                </Dragger>
              </div>
              <a
                className={styles["downloadLink"]}
                href={
                  process.env.PUBLIC_URL + "/VIWELL Onboarding Template.xlsx"
                }
                download={"VIWELL Onboarding Template.xlsx"}
              >
                <CloudDownloadOutlined className={styles["downloadIcon"]} />
                {t("download-description")}
              </a>
              <ModalFooter
                saveText={t("preview-and-save")}
                onSubmit={() => {
                  if (values?.parentId) {
                    bulkUsersContent?.employees?.length && setShowPreview(true);
                  } else {
                    handleError(t("company-required"));
                  }
                }}
                onCancel={() => setVisible(false)}
              />
              {showPreview && bulkUsersContent?.employees?.length ? (
                <BulkUsersPreview
                  visible={showPreview}
                  setVisible={() => setShowPreview(false)}
                  users={bulkUsersContent}
                  errors={errors.users}
                  values={values}
                  refetch={handleRefetchEmployees}
                />
              ) : null}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default InviteMultipleUsers;
