import * as Yup from "yup";
import * as Validations from "validations/schema/common/validations";
import { createUniqueLanguagesTest } from "./common/validationHelpers";
import { ContentType } from "graphql/_generated/graphql";

const uniqueLanguagesOptions = {
  parentFieldName: "spokenLanguage",
  fieldName: "language",
};

export const contentSchema = Yup.object().shape({
  // status: Validations.required("status"),
  title: Validations.requiredFieldWithoutSpaces("title").max(
    300,
    "max-300-chars",
  ),
  description: Validations.requiredFieldWithoutSpaces("description"),
  shortDescription: Validations.requiredFieldWithoutSpaces("shortDescription"),
  pillarIds: Validations.requiredArrayOfStringValidation("pillar"),
  translations: Yup.array()
    .of(
      Yup.object().shape({
        lang: Validations.required("language"),
        title: Validations.requiredFieldWithoutSpaces("title").max(
          300,
          "max-300-chars",
        ),
        description: Validations.requiredFieldWithoutSpaces("description"),
        shortDescription:
          Validations.requiredFieldWithoutSpaces("shortDescription"),
      }),
    )
    .test(
      "unique-languages",
      "error:text-lang-unique",
      createUniqueLanguagesTest({
        parentFieldName: "language",
        errorMsg: "error:text-lang-unique",
      }),
    )
    .nullable(),
  categoryIds: Validations.requiredArrayOfStringValidation("category"),
  tagIds: Validations.requiredArrayOfStringValidation("tag"),
  creatorId: Validations.required("specialist"),
  thumbnailImage: Validations.image("thumbnail-image"),
  language: Validations.required("language"),
  spokenLanguage: Yup.mixed().when("contentType", {
    is: (value: ContentType) =>
      value === ContentType.Audio || value === ContentType.Video,
    then: Validations.required("spoken-language"),
  }),
  contentType: Validations.required("content-type"),
  audioUrl: Yup.mixed().when("contentType", {
    is: "Audio",
    then: Validations.required("audio-file"),
  }),

  videoUrl: Yup.mixed().when("contentType", {
    is: "Video",
    then: Validations.required("video-file"),
  }),

  fileUrl: Yup.mixed().when("contentType", {
    is: "File",
    then: Validations.required("file"),
  }),

  text: Yup.mixed().when("contentType", {
    is: "Text",
    then: Validations.required("content"),
  }),

  gallery: Yup.array().when("contentType", {
    is: "Recipe",
    then: Yup.array().of(
      Yup.object().shape({
        image: Validations.image("img"),
      }),
    ),
  }),

  timeRequired: Yup.number().when("contentType", {
    is: "Recipe",
    then: Validations.requiredFieldPositiveNumber("time")
      .min(0, "error:positive-integer")
      .nullable(),
  }),

  serving: Yup.number().when("contentType", {
    is: "Recipe",
    then: Validations.requiredFieldNumber("serving")
      .min(0, "error:positive-integer")
      .nullable(),
  }),

  localizedAudios: Yup.array().when("contentType", {
    is: "Audio",
    then: Yup.array()
      .of(
        Yup.object().shape({
          language: Validations.requiredFieldWithoutSpaces("language"),
          url: Validations.required("audio-file"),
        }),
      )
      .test(
        "unique-language",
        "error:spoken-lang-unique",
        createUniqueLanguagesTest({
          ...uniqueLanguagesOptions,
          arrayName: "localizedAudios",
          errorMsg: "error:spoken-lang-unique",
        }),
      )
      .nullable(),
  }),

  localizedVideos: Yup.array().when("contentType", {
    is: "Video",
    then: Yup.array()
      .of(
        Yup.object().shape({
          language: Validations.requiredFieldWithoutSpaces("language"),
          url: Validations.requiredFieldWithoutSpaces("video-file"),
        }),
      )
      .test(
        "unique-language",
        "error:spoken-lang-unique",
        createUniqueLanguagesTest({
          ...uniqueLanguagesOptions,
          arrayName: "localizedVideos",
          errorMsg: "error:spoken-lang-unique",
        }),
      )
      .nullable(),
  }),

  localizedFiles: Yup.array().when("contentType", {
    is: "File",
    then: Yup.array().of(
      Yup.object().shape({
        language: Validations.requiredFieldWithoutSpaces("language"),
        url: Validations.requiredFieldWithoutSpaces("file"),
      }),
    ),
  }),

  localizedTexts: Yup.array().when("contentType", {
    is: "Text",
    then: Yup.array().of(
      Yup.object().shape({
        language: Validations.requiredFieldWithoutSpaces("language"),
        text: Validations.requiredFieldWithoutSpaces("content"),
      }),
    ),
  }),
});
