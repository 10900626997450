import {
  GetAllOrgMetricsForGraphQueryVariables,
  GetAllOrgMetricsQueryVariables,
  useLazyGetAllOrgMetricsForGraphQuery,
  useLazyGetAllOrgMetricsQuery,
} from "graphql/_generated/graphql";

export const useGetOrgMetric = () => {
  const [getAllOrgMetricsFun, { isFetching }] =
    useLazyGetAllOrgMetricsForGraphQuery();
  const [getOrgMetricsValuesFun, { isFetching: isValuesFetching }] =
    useLazyGetAllOrgMetricsQuery();

  const handleGetOrgMetric = async (
    values: GetAllOrgMetricsForGraphQueryVariables,
  ) => {
    try {
      const res = await getAllOrgMetricsFun(values).unwrap();

      return res.getAllOrgMetricsForGraph;
    } catch (error) {
      throw error;
    }
  };

  const handleGetOrgMetricValues = async (
    values: GetAllOrgMetricsQueryVariables,
  ) => {
    try {
      const res = await getOrgMetricsValuesFun(values).unwrap();

      return res.getAllOrgMetrics;
    } catch (error) {
      throw error;
    }
  };

  return {
    handleGetOrgMetric,
    handleGetOrgMetricValues,
    isLoading: isFetching || isValuesFetching,
  };
};
