import { List, Pagination } from "antd";
import CustomModal from "components/custom-modal/CustomModal";
import DataTableFilters from "features/challenge/challenge-detail/DataTableFilters";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "features/rewards/css/UserStats.module.scss";
import {
  TierUsersList,
  useLazyGetUsersListByTierQuery,
} from "graphql/_generated/graphql";
import UserItem from "./UserItem";

interface UserStatsDetailsProps {
  tierId: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserStatsDetails: React.FunctionComponent<UserStatsDetailsProps> = ({
  visible,
  setVisible,
  tierId,
}) => {
  const { t } = useTranslation();

  const [data, setData] = useState<TierUsersList>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");
  const [companyId, setCompanyId] = useState("");
  const [pagination, setPagination] = useState<any>({
    limit: 10,
    offset: 0,
  });

  const [getUsersListFun, { isFetching }] = useLazyGetUsersListByTierQuery();

  const fetchUsersData = async () => {
    try {
      const { getUsersListByTier } = await getUsersListFun({
        tierId: tierId,
        pagination,
        search: searchText,
        companyId: companyId,
      }).unwrap();
      setData(getUsersListByTier);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, [currentPage, pagination, searchText, companyId]);

  const handleSearchInput = (value: string) => {
    setSearchText(value);
    setPagination({
      limit: 10,
      offset: 0,
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <CustomModal
      title={`${t("users")} (${data?.metadata.total ?? 0})`}
      onCancel={handleCancel}
      visible={visible}
      footer={null}
      width={720}
    >
      <DataTableFilters
        isCompanyFilter={true}
        setSelectedCompany={setCompanyId}
        handleSearchInput={handleSearchInput}
      />
      <div className={styles["desc-container"]}>
        <List
          dataSource={data?.data}
          loading={isFetching}
          renderItem={(item, index) => (
            <UserItem
              id={index + 1 + pagination?.offset}
              image={item.image ?? ""}
              name={item.userName}
              points={item.points}
              companyName={item.companyName}
              fullView={true}
            />
          )}
        />
      </div>
      <div className={"pagination-margin"}>
        <Pagination
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            setPagination({
              offset: (page - 1) * pageSize,
              limit: pageSize,
            });
          }}
          current={currentPage}
          pageSize={pagination?.limit}
          showTotal={(total, range) => (
            <div>
              Showing&nbsp;
              <b>
                {range[0]}-{range[1]}
              </b>
              &nbsp;of <b>{total}</b>&nbsp; items
            </div>
          )}
          total={data?.metadata?.total || 0}
          responsive
          showSizeChanger={false}
        />
      </div>
    </CustomModal>
  );
};

export default UserStatsDetails;
