import { Button } from "antd";
import * as React from "react";
import styles from "./CustomModal.module.scss";

interface ModalFooterProps {
  saveText: string;
  cancelText?: string;
  saveIcon?: React.ReactNode;
  onCancel: (e: React.MouseEvent<HTMLElement>) => void;
  onSubmit?: (e: React.MouseEvent<HTMLElement>) => void;
  loading?: boolean;
  className?: string;
}

const ModalFooter: React.FunctionComponent<ModalFooterProps> = ({
  saveText,
  saveIcon,
  onCancel,
  onSubmit,
  loading,
  className,
  cancelText,
}) => {
  let submitBtnProps: any = {
    htmlType: "submit",
  };
  if (onSubmit) {
    submitBtnProps = {
      onClick: onSubmit,
    };
  }

  return (
    <div className={`${styles["modal-footer"]} ${className ?? ""}`}>
      <Button htmlType="reset" className="btn-light" onClick={onCancel}>
        {cancelText || "Cancel"}
      </Button>
      <Button
        icon={saveIcon}
        className={`primary-btn ${styles["submit-btn"]}`}
        loading={loading}
        {...submitBtnProps}
      >
        {saveText}
      </Button>
    </div>
  );
};

export default ModalFooter;
