import { DeleteFilled } from "@ant-design/icons";
import { Col, Row } from "antd";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import CustomCard from "components/custom-card/CustomCard";
import CustomTextEditor from "components/form/custom-text-editor/CustomTextEditor";
import { FieldArray } from "formik";
import { FunctionComponent } from "react";
import styles from "features/challenge/css/AddActivity.module.scss";
import CollapseAble from "features/assessment/questions/collapseables";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import DeleteBtn from "components/custom-button/DeleteBtn";
import AddOption from "components/custom-button/AddOption";
import { useLoggedInUser } from "hooks/useLoggedInUser";

interface RewardDetailsProps {
  setFieldValue?: any;
  values: any;
  disabled?: boolean;
  isAddLanguage?: boolean;
}

const RewardDetails: FunctionComponent<RewardDetailsProps> = ({
  values,
  setFieldValue,
  disabled,
  isAddLanguage,
}) => {
  const rankRewardTitles = ["1st", "2nd", "3rd"];
  const { isSuperAdmin, isViwellAdmin } = useLoggedInUser();

  return (
    <CollapseAble
      defaultActiveKey={["reward-details"]}
      headerKey={"reward-details"}
      className={styles["activity-card"]}
      header={
        <div className="d-flex">
          <ContainerHeading text="Rewards Details" />
        </div>
      }
      hideExtra={true}
      expandIconPosition="end"
    >
      <div className={styles["content-card"]}>
        <CustomTextEditor
          name="rewardDetails"
          label="Description"
          isRestrictAccess={true}
          disabled={disabled && !isAddLanguage}
        />
        {isSuperAdmin || isViwellAdmin ? (
          <>
            <Row gutter={[24, 0]}>
              <Col sm={24} md={12}>
                <CustomTextField
                  type="number"
                  label="Complete Challenge Points"
                  name="completePoints"
                  disabled={disabled}
                  placeholder="Enter Points"
                  onChange={(e) =>
                    setFieldValue(
                      "completePoints",
                      e.target.value.length === 0
                        ? null
                        : Number(e.target.value),
                    )
                  }
                />
              </Col>
            </Row>

            <FieldArray
              name="rankPoints"
              render={(arrayHelpers) => (
                <>
                  {values?.rankPoints?.length
                    ? values?.rankPoints.map((item: any, index: number) => (
                        <Row gutter={[24, 0]}>
                          <Col sm={24} md={12}>
                            <CustomTextField
                              type="number"
                              label={`${rankRewardTitles[index]} Rank Reward`}
                              name={`rankPoints.${index}`}
                              placeholder="Enter rank"
                              disabled={disabled}
                            />
                          </Col>
                          <Col sm={24} md={12} className={styles["delete-div"]}>
                            <DeleteBtn
                              disabled={disabled}
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <DeleteFilled />
                            </DeleteBtn>
                          </Col>
                        </Row>
                      ))
                    : null}
                  <AddOption
                    title="Add Winner Rank"
                    onClick={() => arrayHelpers.push("")}
                    disabled={values?.rankPoints?.length > 2 || disabled}
                  />
                </>
              )}
            />
          </>
        ) : null}
      </div>
    </CollapseAble>
  );
};
export default RewardDetails;
